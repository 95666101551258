import React from 'react';
import { Row, Col, Button, Modal } from 'reactstrap';


const PopupConfirmation = (props) => {
  return (
    <Modal
      centered
      isOpen={props.show}
      toggle={() => {
        props.toggleModal();
      }}
    >
      <div className="modal-body overflow-hidden">
        <br/>
        <h4 className="card-title">{props.title || 'Title' }</h4>
        <br/>
        <span> {props.body} </span>
        <br/>
        <br/>
        <Row style={{float: "right"}}>
            <Col lg="12" className="agora-form-buttons text-center pt-4">
              <Button
                onClick={props.actionButtonN}
                className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                onClick={props.actionButtonY}
                className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
              >
                {props.buttonRightText || 'Yes'}
              </Button>
            </Col>
          </Row>
      </div>
    </Modal>
  );
};

export default PopupConfirmation;
