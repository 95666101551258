import React, { useState } from 'react';

import { Row, Col, CardBody, Card, Alert, Container } from 'reactstrap';

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { loginUser, apiError } from '../../store/actions';

// import images
import logo from '../../assets/images/logo.png';
import Loader from '../../shared/loader/loader';

const Login = (props) => {
    const [loader, setLoader] = useState(false);

    // handleValidSubmit
    function handleValidSubmit(event, values) {
        props.loginUser(values, props.history, setLoader);
    }
    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <Loader loading={loader} />
                                <div className="bg-soft-primary">
                                    <Row>
                                        <Col className="col-7">
                                            <div className="text-white p-4">
                                                <h5 className="text-white agora-jost">Welcome to AGORA !</h5>
                                                <p className="agora-jost">Sign in to continue</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            {/* <img src={profile} alt="" className="img-fluid" /> */}
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <Link to="/">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img src={logo} alt="Agora logo icon" className="auth-pages-agora-icon" height="45" />
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2">
                                        <AvForm
                                            className="form-horizontal login-form"
                                            onValidSubmit={(e, v) => {
                                                handleValidSubmit(e, v);
                                            }}
                                        >
                                            {props.error && props.error ? <Alert color="danger">{props.error}</Alert> : null}

                                            <div className="form-group">
                                                <AvField
                                                    name="email"
                                                    label="Email"
                                                    className="form-control"
                                                    placeholder="Enter email"
                                                    type="email"
                                                    required
                                                />
                                            </div>

                                            <div className="form-group">
                                                <AvField
                                                    name="password"
                                                    label="Password"
                                                    type="password"
                                                    required
                                                    placeholder="Enter Password"
                                                />
                                            </div>

                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                                <label className="custom-control-label" htmlFor="customControlInline">
                                                    Remember me
                                                </label>
                                            </div>

                                            <div className="mt-3">
                                                <button
                                                    className="btn btn-primary btn-block agora-button waves-effect waves-light br-10"
                                                    type="submit"
                                                >
                                                    Log In
                                                </button>
                                            </div>

                                            <div className="mt-4 text-center">
                                                <Link to="/forgot-password" className="text-muted">
                                                    <i className="mdi mdi-lock mr-1"></i> Forgot your password?
                                                </Link>
                                            </div>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                {/* <p>Don't have an account ? <Link to="register" className="font-weight-medium text-primary"> Signup now </Link> </p> */}
                                <p>© {new Date().getFullYear()} AGORA</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = (state) => {
    const { error } = state.Login;
    return { error };
};

export default withRouter(connect(mapStatetoProps, { loginUser, apiError })(Login));
