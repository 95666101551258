import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { EditorState, Modifier } from 'draft-js';

function SpecialCharPicker(props) {
    const [expanded, setExpanded] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('mathematical');
    const myRef = useRef();
    
    const categories = {
        mathematical: [
            { symbol: 'ƒ', title: 'function / florin' },
            { symbol: '∫', title: 'integral' },
            { symbol: '∑', title: 'n ary summation' },
            { symbol: '∞', title: 'infinity' },
            { symbol: '√', title: 'square root' },
            { symbol: '≈', title: 'almost equal to' },
            { symbol: '≠', title: 'not equal to' },
            { symbol: '≡', title: 'identical to' },
            { symbol: '∏', title: 'n ary product' },
            { symbol: '∧', title: 'logical and' },
            { symbol: '∨', title: 'logical or' },
            { symbol: '¬', title: 'not sign' },
            { symbol: '∩', title: 'intersection' },
            { symbol: '∪', title: 'union' },
            { symbol: '∂', title: 'partial differential' },
            { symbol: '∀', title: 'for all' },
            { symbol: '∅', title: 'diameter' },
            { symbol: '∇', title: 'backward difference' },
            { symbol: '∼', title: 'similar to' },
            { symbol: '≅', title: 'approximately equal to' },
            { symbol: '∈', title: 'element of' },
            { symbol: '∉', title: 'not an element of' },
            { symbol: '∋', title: 'contains as member' },
            { symbol: '∃', title: 'there exists' },
            { symbol: '∗', title: 'asterisk operator' },
            { symbol: '∝', title: 'proportional to' },
            { symbol: '∠', title: 'angle' },
        ],
        currency: [
          { symbol: '$', title: 'dollar sign' },
          { symbol: '¢', title: 'cent sign' },
          { symbol: '€', title: 'euro sign' },
          { symbol: '£', title: 'pound sign' },
          { symbol: '¥', title: 'yen sign' },
          { symbol: '¤', title: 'currency sign' },
          { symbol: '₠', title: 'euro currency sign' },
          { symbol: '₡', title: 'colon sign' },
          { symbol: '₢', title: 'cruzeiro sign' },
          { symbol: '₣', title: 'french franc sign' },
          { symbol: '₤', title: 'lira sign' },
          { symbol: '₥', title: 'mill sign' },
          { symbol: '₦', title: 'naira sign' },
          { symbol: '₧', title: 'peseta sign' },
          { symbol: '₨', title: 'rupee sign' },
          { symbol: '₩', title: 'won sign' },
          { symbol: '₪', title: 'new sheqel sign' },
          { symbol: '₫', title: 'dong sign' },
          { symbol: '₭', title: 'kip sign' },
          { symbol: '₮', title: 'tugrik sign' },
          { symbol: '₯', title: 'drachma sign' },
          { symbol: '₰', title: 'german penny symbol' },
          { symbol: '₱', title: 'peso sign' },
          { symbol: '₲', title: 'guarani sign' },
          { symbol: '₳', title: 'austral sign' },
          { symbol: '₴', title: 'hryvnia sign' },
          { symbol: '₵', title: 'cedi sign' },
          { symbol: '₶', title: 'livre tournois sign' },
          { symbol: '₷', title: 'spesmilo sign' },
          { symbol: '₸', title: 'tenge sign' },
          { symbol: '₹', title: 'indian rupee sign' },
          { symbol: '₺', title: 'turkish lira sign' },
          { symbol: '₻', title: 'nordic mark sign' },
          { symbol: '₼', title: 'manat sign' },
          { symbol: '₽', title: 'ruble sign' },
          { symbol: '円', title: 'yen character' },
          { symbol: '元', title: 'yuan character' },
          { symbol: '圓', title: 'yuan character, in hong kong and taiwan' },
          { symbol: '圆', title: 'yen/yuan character variant one' }
        ],
        text: [
          { symbol: '©', title: 'copyright sign' },
          { symbol: '®', title: 'registered sign' },
          { symbol: '™', title: 'trade mark sign' },
          { symbol: '‰', title: 'per mille sign' },
          { symbol: 'µ', title: 'micro sign' },
          { symbol: '·', title: 'middle dot' },
          { symbol: '•', title: 'bullet' },
          { symbol: '…', title: 'three dot leader' },
          { symbol: '′', title: 'minutes / feet' },
          { symbol: '″', title: 'seconds / inches' },
          { symbol: '§', title: 'section sign' },
          { symbol: '¶', title: 'paragraph sign' },
          { symbol: 'ß', title: 'sharp s / ess zed' }
        ],
        arrow: [
            { symbol: '←', title: 'Leftward arrow' },
            { symbol: '↑', title: 'Upward arrow' },
            { symbol: '→', title: 'Rightward arrow' },
            { symbol: '↓', title: 'Downward arrow' },
            { symbol: '↔', title: 'Left right' },
            { symbol: '↵', title: 'Carriage return' },
            { symbol: '⇐', title: 'Leftwards double arrow' },
            { symbol: '⇑', title: 'Upwards double arrow' },
            { symbol: '⇒', title: 'Rightwards double arrow' },
            { symbol: '⇓', title: 'Downwards double arrow' },
            { symbol: '⇔', title: 'Left right  double arrow' },
            { symbol: '∴', title: 'Therefore' },
            { symbol: '⊂', title: 'Subset of' },
            { symbol: '⊃', title: 'Superset of' },
            { symbol: '⊄', title: 'not a subset of' },
            { symbol: '⊆', title: 'Subset of or equal to' },
            { symbol: '⊇', title: 'Superset of or equal to' },
            { symbol: '⊕', title: 'Circled plus' },
            { symbol: '⊗', title: 'Circled times' },
            { symbol: '⊥', title: 'perpendicular' },
            { symbol: '⋅', title: 'Dot operator' },
            { symbol: '⌈', title: 'Left ceiling' },
            { symbol: '⌉', title: 'Right ceiling' },
            { symbol: '⌊', title: 'Left floor' },
            { symbol: '⌋', title: 'Right floor' },
            { symbol: '〈', title: 'Left pointing angle bracket' },
            { symbol: '〉', title: 'Right pointing angle bracket' },
            { symbol: '◊', title: 'Lozenge' },
            { symbol: '♠', title: 'Black spade suit' },
            { symbol: '♣', title: 'Black club suit' },
            { symbol: '♥', title: 'Black heart suit' },
            { symbol: '♦', title: 'Black diamond suit' },
            
        ],
        quotation: [
            { symbol: '‹', title: 'single left pointing angle quotation mark' },
            { symbol: '›', title: 'single right pointing angle quotation mark' },
            { symbol: '«', title: 'left pointing guillemet' },
            { symbol: '»', title: 'right pointing guillemet' },
            { symbol: '‘', title: 'left single quotation mark' },
            { symbol: '’', title: 'right single quotation mark' },
            { symbol: '“', title: 'left double quotation mark' },
            { symbol: '”', title: 'right double quotation mark' },
            { symbol: '‚', title: 'single low 9 quotation mark' },
            { symbol: '„', title: 'double low 9 quotation mark' },
            { symbol: '<', title: 'less than sign' },
            { symbol: '>', title: 'greater than sign' },
            { symbol: '≤', title: 'less than or equal to' },
            { symbol: '≥', title: 'greater than or equal to' },
            { symbol: '–', title: 'en dash' },
            { symbol: '—', title: 'em dash' },
            { symbol: '¯', title: 'macron' },
            { symbol: '‾', title: 'overline' },
            { symbol: '¤', title: 'currency sign' },
            { symbol: '¦', title: 'broken bar' },
            { symbol: '¨', title: 'diaeresis' },
            { symbol: '¡', title: 'inverted exclamation mark' },
            { symbol: '¿', title: 'turned question mark' },
            { symbol: 'ˆ', title: 'circumflex accent' },
            { symbol: '˜', title: 'small tilde' },
            { symbol: '°', title: 'degree sign' },
            { symbol: '−', title: 'minus sign' },
            { symbol: '±', title: 'plus minus sign' },
            { symbol: '÷', title: 'division sign' },
            { symbol: '⁄', title: 'fraction slash' },
            { symbol: '×', title: 'multiplication sign' },
            { symbol: '¹', title: 'superscript one' },
            { symbol: '²', title: 'superscript two' },
            { symbol: '³', title: 'superscript three' },
            { symbol: '¼', title: 'fraction one quarter' },
            { symbol: '½', title: 'fraction one half' },
            { symbol: '¾', title: 'fraction three quarters' }
          ],
        latin: [
          { symbol: "À", title: "A grave" },
          { symbol: "Á", title: "A acute" },
          { symbol: "Â", title: "A circumflex" },
          { symbol: "Ã", title: "A tilde" },
          { symbol: "Ä", title: "A diaeresis" },
          { symbol: "Å", title: "A ring above" },
          { symbol: "Ā", title: "A macron" },
          { symbol: "Æ", title: "ligature AE" },
          { symbol: "Ç", title: "C cedilla" },
          { symbol: "È", title: "E grave" },
          { symbol: "É", title: "E acute" },
          { symbol: "Ê", title: "E circumflex" },
          { symbol: "Ë", title: "E diaeresis" },
          { symbol: "Ē", title: "E macron" },
          { symbol: "Ì", title: "I grave" },
          { symbol: "Í", title: "I acute" },
          { symbol: "Î", title: "I circumflex" },
          { symbol: "Ï", title: "I diaeresis" },
          { symbol: "Ī", title: "I macron" },
          { symbol: "Ð", title: "ETH" },
          { symbol: "Ñ", title: "N tilde" },
          { symbol: "Ò", title: "O grave" },
          { symbol: "Ó", title: "O acute" },
          { symbol: "Ô", title: "O circumflex" },
          { symbol: "Õ", title: "O tilde" },
          { symbol: "Ö", title: "O diaeresis" },
          { symbol: "Ø", title: "O slash" },
          { symbol: "Ō", title: "O macron" },
          { symbol: "Œ", title: "ligature OE" },
          { symbol: "Š", title: "S caron" },
          { symbol: "Ù", title: "U grave" },
          { symbol: "Ú", title: "U acute" },
          { symbol: "Û", title: "U circumflex" },
          { symbol: "Ü", title: "U diaeresis" },
          { symbol: "Ū", title: "U macron" },
          { symbol: "Ý", title: "Y acute" },
          { symbol: "Ÿ", title: "Y diaeresis" },
          { symbol: "Ȳ", title: "Y macron" },
          { symbol: "Þ", title: "THORN" },
          { symbol: "à", title: "a grave" },
          { symbol: "á", title: "a acute" },
          { symbol: "â", title: "a circumflex" },
          { symbol: "ã", title: "a tilde" },
          { symbol: "ä", title: "a diaeresis" },
          { symbol: "å", title: "a ring above" },
          { symbol: "ā", title: "a macron" },
          { symbol: "æ", title: "ligature ae" },
          { symbol: "ç", title: "c cedilla" },
          { symbol: "è", title: "e grave" },
          { symbol: "é", title: "e acute" },
          { symbol: "ê", title: "e circumflex" },
          { symbol: "ë", title: "e diaeresis" },
          { symbol: "ē", title: "e macron" },
          { symbol: "ì", title: "i grave" },
          { symbol: "í", title: "i acute" },
          { symbol: "î", title: "i circumflex" },
          { symbol: "ï", title: "i diaeresis" },
          { symbol: "ī", title: "i macron" },
          { symbol: "ð", title: "eth" },
          { symbol: "ñ", title: "n tilde" },
          { symbol: "ò", title: "o grave" },
          { symbol: "ó", title: "o acute" },
          { symbol: "ô", title: "o circumflex" },
          { symbol: "õ", title: "o tilde" },
          { symbol: "ö", title: "o diaeresis" },
          { symbol: "ø", title: "o slash" },
          { symbol: "ō", title: "o macron" },
          { symbol: "œ", title: "ligature oe" },
          { symbol: "š", title: "s caron" },
          { symbol: "ù", title: "u grave" },
          { symbol: "ú", title: "u acute" },
          { symbol: "û", title: "u circumflex" },
          { symbol: "ü", title: "u diaeresis" },
          { symbol: "ū", title: "u macron" },
          { symbol: "ý", title: "y acute" },
          { symbol: "þ", title: "thorn" },
          { symbol: "ÿ", title: "y diaeresis" },
          { symbol: "ȳ", title: "y macron" },
          { symbol: "Α", title: "Alpha" },
          { symbol: "Β", title: "Beta" },
          { symbol: "Γ", title: "Gamma" },
          { symbol: "Δ", title: "Delta" },
          { symbol: "Ε", title: "Epsilon" },
          { symbol: "Ζ", title: "Zeta" },
          { symbol: "Η", title: "Eta" },
          { symbol: "Θ", title: "Theta" },
          { symbol: "Ι", title: "Iota" },
          { symbol: "Κ", title: "Kappa" },
          { symbol: "Λ", title: "Lambda" },
          { symbol: "Μ", title: "Mu" },
          { symbol: "Ν", title: "Nu" },
          { symbol: "Ξ", title: "Xi" },
          { symbol: "Ο", title: "Omicron" },
          { symbol: "Π", title: "Pi" },
          { symbol: "Ρ", title: "Rho" },
          { symbol: "Σ", title: "Sigma" },
          { symbol: "Τ", title: "Tau" },
          { symbol: "Υ", title: "Upsilon" },
          { symbol: "Φ", title: "Phi" },
          { symbol: "Χ", title: "Chi" },
          { symbol: "Ψ", title: "Psi" },
          { symbol: "Ω", title: "Omega" },
          { symbol: "α", title: "alpha" },
          { symbol: "β", title: "beta" },
          { symbol: "γ", title: "gamma" },
          { symbol: "δ", title: "delta" },
          { symbol: "ε", title: "epsilon" },
          { symbol: "ζ", title: "zeta" },
          { symbol: "η", title: "eta" },
          { symbol: "θ", title: "theta" },
          { symbol: "ι", title: "iota" },
          { symbol: "κ", title: "kappa" },
          { symbol: "λ", title: "lambda" },
          { symbol: "μ", title: "mu" },
          { symbol: "ν", title: "nu" },
          { symbol: "ξ", title: "xi" },
          { symbol: "ο", title: "omicron" },
          { symbol: "π", title: "pi" },
          { symbol: "ρ", title: "rho" },
          { symbol: "σ", title: "sigma" },
          { symbol: "τ", title: "tau" },
          { symbol: "υ", title: "upsilon" },
          { symbol: "φ", title: "phi" },
          { symbol: "χ", title: "chi" },
          { symbol: "ψ", title: "psi" },
          { symbol: "ω", title: "omega" }
        ],
        symbols: [
          { symbol: 'ℵ', title: 'alef symbol' },
          { symbol: 'ϖ', title: 'pi symbol' },
          { symbol: 'ℜ', title: 'real part symbol' },
          { symbol: 'ϒ', title: 'upsilon hook symbol' },
          { symbol: '℘', title: 'Weierstrass p' },
          { symbol: 'ℑ', title: 'imaginary part' }
        ]
    };

    const icon = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZm9jdXNhYmxlPSJmYWxzZSI+PHBhdGggZD0iTTE1IDE4aDRsMS0ydjRoLTZ2LTMuM2wxLjQtMWE2IDYgMCAwMDEuOC0yLjkgNi4zIDYuMyAwIDAwLS4xLTQuMSA1LjggNS44IDAgMDAtMy0zLjJjLS42LS4zLTEuMy0uNS0yLjEtLjVhNS4xIDUuMSAwIDAwLTMuOSAxLjggNi4zIDYuMyAwIDAwLTEuMyA2IDYuMiA2LjIgMCAwMDEuOCAzbDEuNC45VjIwSDR2LTRsMSAyaDR2LS41bC0yLTFMNS40IDE1QTYuNSA2LjUgMCAwMTQgMTFjMC0xIC4yLTEuOS42LTIuN0E3IDcgMCAwMTYuMyA2QzcuMSA1LjQgOCA1IDkgNC41YzEtLjMgMi0uNSAzLjEtLjVhOC44IDguOCAwIDAxNS43IDIgNyA3IDAgMDExLjcgMi4zIDYgNiAwIDAxLjIgNC44Yy0uMi43LS42IDEuMy0xIDEuOWE3LjYgNy42IDAgMDEtMy42IDIuNXYuNXoiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg=='

    const propTypes = {
        expanded: PropTypes.bool,
        onExpandEvent: PropTypes.func,
        onChange: PropTypes.func,
        doCollapse: PropTypes.func,
        currentState: PropTypes.object,
    };

    const stopPropagation = (event) => {
        event.stopPropagation();
    };

    const onClick = (event) => {
        const { editorState, onChange } = props;
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            event.target.innerHTML,
            editorState.getCurrentInlineStyle(),
        );
        onChange(EditorState.push(editorState, contentState, 'insert-characters'));
    };

    const handleClickOutside = e => {
        if (myRef.current == null || !myRef.current.contains(e.target)) {
            setExpanded(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });

    const renderModal = () => {
        return (
            <div
                onClick={stopPropagation}
                className="rdw-specialchar-modal"
                ref={myRef}
            >
                <div className="rdw-specialchar-categories">
                    {Object.keys(categories).map((category) => (
                        <button
                            key={category}
                            onClick={() => setSelectedCategory(category)}
                            className={selectedCategory === category ? 'active' : ''}
                        >
                            {category}
                        </button>
                    ))}
                </div>
                <div className="rdw-specialchar-symbols">
                    {categories[selectedCategory].map((charObj, index) => (
                        <span
                            key={index}
                            className="rdw-specialchar-icon"
                            alt=""
                            text={charObj.symbol}
                            onClick={onClick}
                            title={charObj.title}
                        >
                            {charObj.symbol}
                        </span>
                    ))}
                </div>
            </div>
        );
    };

    const onClickExpand = () => {
        setExpanded(!expanded);
    };

    return (
        <div
            className='rdw-specialchar-wrapper'
            aria-haspopup="true"
            aria-expanded={expanded}
            aria-label="rdw-specialchar-wrapper"
            onClick={onClickExpand}
        >
            <div className="rdw-option-wrapper">
                <img src={icon} alt="" />
            </div>
            {expanded ? renderModal() : undefined}
        </div>
    );
}

SpecialCharPicker.propTypes = {
    expanded: PropTypes.bool,
    onExpandEvent: PropTypes.func,
    onChange: PropTypes.func,
    doCollapse: PropTypes.func,
    currentState: PropTypes.object,
};

export default SpecialCharPicker;
