import { requestWithToken } from './index';
import { appConfig } from '../config/config';
import { GetFilterString } from '../helpers/general/generalHelpers';

export const getListQuestions = ({ filters }) => {
	let filterString = GetFilterString(filters);
	return requestWithToken({
		url: `question?${filterString}`,
		method: 'GET'
	})
}
export const getListQuestions2 = ({ pageNumber = 1, filters, pageSize = appConfig.RecordsPerPage }) => {
  let filterString = GetFilterString(filters);
  return requestWithToken({
    url: `question?pageNumber=${pageNumber}&pageSize=${pageSize}${filterString}`,
    method: 'GET'
  });
};

export const putUpdateStudyGuideTag = ({ id, body }) => {
	return requestWithToken({
		url: `question/updateStudyTag/${id}`,
		method: 'PUT',
		data: body
	})
}