import axios from 'axios';
import authHeader from '../../../helpers/jwt-token-access/auth-token-header';
import { ShowNotification, GetQueryParam, GetFilterString } from '../../../helpers/general/generalHelpers';
import { appConfig } from '../../../config/config';

function saveChapter(values, history, setLoader) {
  const chapterId = GetQueryParam(history.location.search, 'id');
  let type = 'post';
  let url = '/chapter';
  if (chapterId) {
    type = 'put';
    url = '/chapter/' + chapterId;
  }
  axios[type](url, values, { headers: authHeader() })
    .then((data) => {
      ShowNotification({ type: 'success', message: data.message });
      const messageType = type === 'post' ? 'added' : 'updated';
      history.push({
        pathname: '/chapters',
        notification: {
          show: true,
          message: data.message || `Chapter has been ${messageType} successfully!`,
          title: `Chapter ${messageType} successfully!`
        }
      });
    })
    .catch((error) => {
      const message =
        error && error.response && error.response.data
          ? error.response.data.message
          : 'There was an error adding chapter, please try again!';
      ShowNotification({ type: 'error', message });
      throw error;
    })
    .finally(() => {
      setLoader(false);
    });
}

function getChapter(id) {
  return axios.get('/chapter/' + id, { headers: authHeader() });
}

function getChapters(pageNumber, filters, pageSize = appConfig.RecordsPerPage) {
  let filterString = GetFilterString(filters);
  return axios.get(`/chapter?pageNumber=${pageNumber}&pageSize=${pageSize}${filterString}`, { headers: authHeader() });
}

function getChaptersBySubjectId(subjectId) {
  return axios.get(`/chapter?subjectId=${subjectId}`, { headers: authHeader() });
}

export { saveChapter, getChapter, getChapters, getChaptersBySubjectId };
