import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

function ShowButton(props) {
  if (props.button) {
    return (
      <Link
        to={props.url}
        className="btn btn-primary btn-block agora-button border-radius waves-effect waves-light"
      >
        {props.name}
      </Link>
    );
  } else {
    return '';
  }
}

const PageTitle = (props) => {
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18">{props.title}</h4>
          <div className="page-title-right">
            {props.children}
            <ShowButton button={props.button} name={props.name} type={props.title} url={props.url} />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default PageTitle;
