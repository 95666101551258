import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import parse from 'html-react-parser';
import cloneDeep from 'lodash.clonedeep';
import ReactDevicePreview from 'react-device-preview';
//import { Editor } from '@tinymce/tinymce-react';

import Loader from '../../shared/loader/loader';
import BackButton from '../../components/Common/BackButton';
import PopupAddStudyGuide from './PopupAddStudyGuide';
import PopupConfirmation from '../../components/PopupConfirmation';
import { ShowNotification, GetQueryParam, SetStatusStyle } from '../../helpers/general/generalHelpers';
import { getDetailStudyGuide, putUpdateStudyGuide } from '../../service/studyGuide';
//import { TinyImageUpload } from '../../helpers/general/services/image-service';
//import { appConfig } from '../../config/config';
import MobileScreen from './MobileScreen';
import { Buffer } from "buffer";

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {  preImageUpload, rteColors, rteFontFamily, setImageAttribute, uploadHtmlAndGetContent } from '../../components/Utils';
import SpecialCharPicker from '../../components/SpecialCharPicker';
import draftToHtml from '../../components/draftjs-to-html';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

const EditStudyGuide = (props) => {
  const studyGuideId = GetQueryParam(props.location.search, 'id');
  const [contentHtml, setContentHtml] = useState('');
  const [pages, setPages] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [detailStudyGuide, setDetailStudyGuide] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [editorState, setEditorState] = useState(()=>EditorState.createEmpty())
  const [, update] = useState(0);
  const forceUpdate = () => {
      update((n) => !n);
  };
  Buffer.from("anything", "base64");
  window.Buffer = window.Buffer || require("buffer").Buffer;

  const handleUpdateDetailStudyGuide = () => {
    getDetailStudyGuide({ id: studyGuideId })
      .then((response) => {
        const data = response.data.studyGuide;
        setDetailStudyGuide((prev) => {
          return {
            ...prev,
            order: data.order,
            title: data.title,
            studyLevelId: data.studyLevelId,
            chapter: data.chapter,
            active: data.active,
            subject: data.subject
          };
        });
        ShowNotification({
          type: 'success',
          message: 'Great, you’ve successfully update study guide!'
        });
      })
      .catch((error) => {
        ShowNotification({
          type: 'error',
          message: 'Oops, sorry! Something went wrong, please try again.'
        });
      });
  };

  const handleSaveStudyQuidesPages =  async (props) => {
    //debugger;
    let currPage;
    let updatedPages;
    if (props) {
      updatedPages = cloneDeep(pages);
      // const newUpdatedPages = cloneDeep(pages);
      // conditon if add study guide
      // newUpdatedPages.splice(currentPage, 0, `Page ${currentPage}`);
      // updatedPages[props] = `Page ${props + 1}`;
      updatedPages.splice(currentPage, 0, `Page ${currentPage + 1}`);
      // setCurrentPage((prevCurPage) => prevCurPage + 1);
      setContentHtml(`Page ${currentPage + 1}`);
      updateEditorContent(`Page ${currentPage + 1}`)
    } else {
      updatedPages = cloneDeep(pages);
      // const newUpdatedPages = cloneDeep(pages);
      currPage = currentPage - 1;
      const bodyHtml = await uploadHtmlAndGetContent(contentHtml);
      updatedPages[currPage] = bodyHtml;
      
    }

    if (pages[currPage] !== updatedPages[currPage] || updatedPages.length !== pages.length) {
      await putUpdateStudyGuide({ id: studyGuideId, body: { content: updatedPages } })
        .then((response) => {
          setPages(response.data.studyguide.content);
          //setContentHtmlAsPages();
          //debugger;
          ShowNotification({
            type: 'success',
            message: `Great, you’ve successfully save study guide on page ${currentPage + 1}`
          });
        })
        .catch((error) => {
          ShowNotification({
            type: 'error',
            message: 'Oops, sorry! Something went wrong, please try again.'
          });
        });
    } else {
      console.info(`No changes on page ${currentPage}`);
    }
  };

  const handleGetDetailStudyGuide = () => {
    setLoader(true);
    getDetailStudyGuide({ id: studyGuideId })
      .then((response) => {
        setDetailStudyGuide(response.data.studyGuide);
        setPages(response.data.studyGuide.content);
        setContentHtml(response.data.studyGuide.content[0]);
        updateEditorContent(response.data.studyGuide.content[0])
      })
      .catch((error) => {
        ShowNotification({
          type: 'error',
          message: error.message
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const toggleModal = () => {
    setIsPopupOpen(!isPopupOpen);
    document.body.classList.add('no_padding');
  };

  const handleSaveButton = () => {
    handleSaveStudyQuidesPages();
  };

  const handleCancelChanges = () => {
    setContentHtml(pages[currentPage - 1]);
    updateEditorContent(pages[currentPage - 1])
    setIsCancelOpen(false);
    ShowNotification({
      type: 'success',
      message: `Great, you’ve successfully cancel study guide on page ${currentPage}`
    });
  };

  const handleDeletePage = () => {
    const pagesContent = cloneDeep(pages);
    const currPages = currentPage - 1;
    if (currPages > -1) {
      pagesContent.splice(currPages, 1);
    }
    // Update study guide with content that has been deleted
    putUpdateStudyGuide({ id: studyGuideId, body: { content: pagesContent } })
      .then((response) => {
        const tempPages = response.data.studyguide.content;
        setIsDeleteOpen(false);
        setPages(tempPages);
        setCurrentPage((prev) => {
          const resultPages = prev < 2 ? 1 : prev - 1;
          setContentHtml(tempPages[resultPages - 1]);
          updateEditorContent(tempPages[resultPages - 1])
          return resultPages;
        });
        ShowNotification({
          type: 'success',
          message: `Great, you’ve successfully delete study guide on page ${currentPage}`
        });
      })
      .catch((error) => {
        ShowNotification({
          type: 'error',
          message: 'Oops, sorry! Something went wrong, please try again.'
        });
      })
      .finally(() => {});
  };

  const ChangePage = (type) => {
    switch (type) {
      case 'next':
        setCurrentPage((prev) => prev + 1);
        handleSaveStudyQuidesPages();
        break;
      case 'prev':
        setCurrentPage((prev) => prev - 1);
        handleSaveStudyQuidesPages();
        break;
      case 'add':
        setCurrentPage((prev) => prev + 1);
        console.log("createing new page")
        handleSaveStudyQuidesPages(pages.length);
        break;
      default:
    }
    // Every change pages, always save study guides
  };

  const setContentHtmlAsPages = () => {
    //debugger;
    console.log("ContentPages, Page No ", currentPage)
    const content = pages[currentPage - 1];
    if (content) {
      setContentHtml(content);
      updateEditorContent(content)
    }
  };

  const OnEditorStateChange = (content, editor) => {
    setContentHtml(content);
    updateEditorContent(content)
  };
  const updateEditorContent = (htmlContent)=>{
    setEditorState(EditorState.createWithContent(
      ContentState.createFromBlockArray(
        htmlToDraft(htmlContent, setImageAttribute)
      )
    ))
  }

  useEffect(() => {
    handleGetDetailStudyGuide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setContentHtmlAsPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pages]);

  return (
    <React.Fragment>
      <Loader loading={loader} />
      <div className="page-content">
        <Container fluid className="relative">
          <BackButton text="Back to Study Guides" url="/studyguides" />

          <Card>
            <CardBody>
              <Row>
                <Col md="3" className="justify-content-center">
                  <h4 className="card-title mb-3">{detailStudyGuide?.title}</h4>
                </Col>
                <Col className="justify-content-left">
                  <SetStatusStyle status={detailStudyGuide?.active ? 'active' : 'inactive'} />
                </Col>
                <Col className="col text-md-right text-sm-right">
                  <i onClick={() => setIsPopupOpen(true)} className="bx bxs-edit-alt font-size-16 btn p-0"></i>
                </Col>
              </Row>
              <Row>
                <Col md="3" className="justify-content-center">
                  {detailStudyGuide?.subject?.title} - {detailStudyGuide?.studyLevelId}
                  <br />
                  {detailStudyGuide?.chapter?.title}
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              {/* <h4 className="card-title mb-3">Study Guide Content - Atomic Structure</h4> */}
              <Row className="agora-lato">
                <Col md="1">
                  <Button
                    type="button"
                    disabled={currentPage === 1}
                    className="btn button-arrow btn-primary left-right-button waves-effect"
                    style={{ fontSize: '25px' }}
                    onClick={(e) => {
                      ChangePage('prev');
                    }}
                  >
                    <i className="bx bx-left-arrow"></i>
                  </Button>
                </Col>
                <Col md="4" className="text-center">
                  <ReactDevicePreview device="iphone8" scale="0.7">
                    <div className="device-content">
                      <MobileScreen
                        cardHeaderText={detailStudyGuide?.title}
                        cardSubHeaderText={`${detailStudyGuide?.subject?.title} - ${detailStudyGuide?.studyLevelId}`}
                        cardSubHeaderText2={detailStudyGuide?.chapter?.title}
                        currentPage={currentPage}
                        pagesLength={pages.length}
                      >
                        {parse(contentHtml)}
                      </MobileScreen>
                    </div>
                  </ReactDevicePreview>
                </Col>
                <Col md="1">
                  <div style={{ display: 'flex' }}>
                    {currentPage !== pages.length && (
                      <Button
                        type="button"
                        className="btn button-arrow btn-primary left-right-button waves-effect agora-dark-grey-color"
                        // className="btn button-arrow btn-primary waves-effect agora-dark-grey-color"

                        style={{ fontSize: '25px' }}
                        onClick={(e) => {
                          ChangePage('next');
                        }}
                      >
                        <i className="bx bx-right-arrow"></i>
                      </Button>
                    )}
                    <Button
                      type="button"
                      className="btn button-arrow btn-primary left-right-button waves-effect agora-dark-grey-color"
                      style={{ fontSize: '25px' }}
                      onClick={(e) => {
                        ChangePage('add');
                      }}
                    >
                      <i className="bx bx-plus"></i>
                    </Button>
                  </div>
                </Col>
                <Col md="6">
                 {/*} <Editor
                    {...appConfig.tinyRTE}
                    value={contentHtml}
                    // initialValue={contentHtml}
                    init={{
                      height: 400,
                      menubar: false,
                      images_upload_handler: (blobInfo, success, failure) => {
                        TinyImageUpload(blobInfo, success, failure, setLoader, '');
                      }
                    }}
                    onEditorChange={OnEditorStateChange}
                  />
                  */}
                  <Editor
                        editorState={editorState}
                        onEditorStateChange={(newEditorState)=>{
                          let newContent = contentHtml
                          const htmlContent = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
                          newContent = htmlContent
                          setContentHtml(newContent)
                          setEditorState(newEditorState)
                          forceUpdate();
                        }}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        toolbar={{
                        image: {
                            uploadEnabled: true,
                            alignmentEnabled: true,
                            uploadCallback: preImageUpload,
                            previewImage: true,
                            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                            defaultSize: {
                            height: 'auto',
                            width: 'auto',
                            },
                        },
                        colorPicker: { 
                          colors: rteColors
                        },
                        fontFamily: {
                          options: rteFontFamily,
                        },
                        }}

                        toolbarCustomButtons={[<SpecialCharPicker />]}
                    />
                  <div className="icon-buttons mt-2 text-center">
                    <Button
                      type="button"
                      color="link"
                      disabled={pages.length === 1}
                      className="btn btn-link waves-effect text-danger"
                      onClick={(e) => {
                        setIsDeleteOpen(true);
                      }}
                    >
                      <i className="bx bxs-trash-alt"></i> Delete Page
                    </Button>
                  </div>
                  <Row style={{ marginTop: '80px', float: 'right' }}>
                    <Col className="col-sm-12 col-md-auto mb-4" style={{ paddingTop: '27px' }}>
                      <Button
                        type="button"
                        onClick={() => setIsCancelOpen(true)}
                        className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light ml-2"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        onClick={handleSaveButton}
                        className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
      <PopupAddStudyGuide
        edit={detailStudyGuide}
        show={isPopupOpen}
        UpdateContent={handleUpdateDetailStudyGuide}
        toggleModal={toggleModal}
      />

      <PopupConfirmation
        show={isDeleteOpen}
        actionButtonN={() => setIsDeleteOpen(false)}
        actionButtonY={handleDeletePage}
        title={'Wait a minute…'}
        body={'Are you sure want to remove this page from study guide content?'}
      />

      <PopupConfirmation
        show={isCancelOpen}
        actionButtonN={() => setIsCancelOpen(false)}
        actionButtonY={handleCancelChanges}
        title={'Wait a minute…'}
        body={'Are you sure want to cancel changes content on this pages?'}
      />
    </React.Fragment>
  );
};

export default EditStudyGuide;
