import { symbols } from "./SpecialCharPicker"
import S3 from 'react-aws-s3';
import { appConfig } from "../config/config";
import { convertToRaw } from "draft-js";
import draftToHtml from "./draftjs-to-html/index";
export const convertToHtmlEntity=(htmlContent)=>{
    return htmlContent //!== undefined && htmlContent !== null && htmlContent !== ""? htmlContent.replace(/[ƒ∫∑∞√≈≠≡∏∧∨¬∩∪∂∀∅∇∼≅∈∉∋∃∗∝∠]/g, match => symbols[match]):htmlContent;
  }

  const config = {
    bucketName: appConfig.S3.bucketName,
    region: appConfig.S3.region,
    accessKeyId: appConfig.S3.accessKeyId,
    secretAccessKey: appConfig.S3.secretAccessKey
  };

 export const uploadImage=(file, folderName) =>{
    config.dirName = folderName;
    const ReactS3 = new S3(config);
    const fileName = `${folderName}-${Date.now()}`;
    return ReactS3.uploadFile(file, fileName); 
}

export const uploadAndGetURL = async(blobData, folderName='image')=>{
    return new Promise((resolve, reject)=>{
        uploadImage(blobData, 'image')
          .then(function (res) {
            resolve(res.location);
          })
          .catch(function (err) {
            //failure('HTTP Error: ' + err.message);
            reject(err)
          })
          .finally(() => {
           
            //setLoader(false);
          });
    })
  }


  export const preImageUpload = (file)=>{
    return new Promise((resolve, reject) => {
      //const blob = new Blob([file], { type: "image/jpeg" })
      const blob = new Blob([file])
      const blobURL = URL.createObjectURL(blob)
      resolve({ data: { link: blobURL } });
  
    });
  }
  function isArray(arr) {
    return Object.prototype.toString.call(arr) === '[object Array]';
  }
  export const uploadAndGetHTML = async(htmlCurrentContent)=>{
    return new Promise(async (resolve, reject) => {
      try {
      
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlCurrentContent, 'text/html');
        const blobUrls = Array.from(doc.querySelectorAll('img[src^="blob:"]')).map(img => img.src);
  
        let updatedHtmlContent = htmlCurrentContent;
        console.log("Step 2", blobUrls);
  
        if (blobUrls !== undefined && blobUrls !== null && blobUrls.length > 0) {
          updatedHtmlContent = await Promise.all(blobUrls.map(async (blobUrl) => {
            const blobResponse = await fetch(blobUrl);
            const blobData = await blobResponse.blob();
            let imgLink = await uploadAndGetURL(blobData);
            return htmlCurrentContent.toString().replace(blobUrl, imgLink);
          }));
        }
  
        let htmlContent = "";
        if (Array.isArray(updatedHtmlContent)) {
          htmlContent = updatedHtmlContent[0];
        } else {
          htmlContent = updatedHtmlContent;
        }
  
        htmlContent = convertToHtmlEntity(htmlContent);
        console.log("Step Last", htmlContent);
  
        resolve(htmlContent);
      } catch (error) {
        reject(error);
      }
    });
  }
  export const uploadAndGetContent = async (editorState) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log("STEPPPPPP", convertToRaw(editorState.getCurrentContent()))
        let htmlCurrentContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        console.log("Step 1", htmlCurrentContent);
  
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlCurrentContent, 'text/html');
        const blobUrls = Array.from(doc.querySelectorAll('img[src^="blob:"]')).map(img => img.src);
  
        let updatedHtmlContent = htmlCurrentContent;
        console.log("Step 2", blobUrls);
  
        if (blobUrls !== undefined && blobUrls !== null && blobUrls.length > 0) {
          updatedHtmlContent = await Promise.all(blobUrls.map(async (blobUrl) => {
            const blobResponse = await fetch(blobUrl);
            const blobData = await blobResponse.blob();
            let imgLink = await uploadAndGetURL(blobData);
            return htmlCurrentContent.toString().replace(blobUrl, imgLink);
          }));
        }
  
        let htmlContent = "";
        if (Array.isArray(updatedHtmlContent)) {
          htmlContent = updatedHtmlContent[0];
        } else {
          htmlContent = updatedHtmlContent;
        }
  
        htmlContent = convertToHtmlEntity(htmlContent);
        console.log("Step Last", htmlContent);
  
        resolve(htmlContent);
      } catch (error) {
        reject(error);
      }
    });
  };
  export const uploadHtmlAndGetContent = async (htmlCurrentContent) => {
    return new Promise(async (resolve, reject) => {
      try {
  
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlCurrentContent, 'text/html');
        const blobUrls = Array.from(doc.querySelectorAll('img[src^="blob:"]')).map(img => img.src);
  
        let updatedHtmlContent = htmlCurrentContent;
  
        if (blobUrls !== undefined && blobUrls !== null && blobUrls.length > 0) {
          updatedHtmlContent = await Promise.all(blobUrls.map(async (blobUrl) => {
            const blobResponse = await fetch(blobUrl);
            const blobData = await blobResponse.blob();
            let imgLink = await uploadAndGetURL(blobData);
            return htmlCurrentContent.toString().replace(blobUrl, imgLink);
          }));
        }
  
        let htmlContent = "";
        if (Array.isArray(updatedHtmlContent)) {
          htmlContent = updatedHtmlContent[0];
        } else {
          htmlContent = updatedHtmlContent;
        }
  
        htmlContent = convertToHtmlEntity(htmlContent);
  
        resolve(htmlContent);
      } catch (error) {
        reject(error);
      }
    });
  };

  export function setImageAttribute(nodeName, node)  {
      if (nodeName === 'img' && node instanceof HTMLImageElement) {
          const entityConfig = {};

          entityConfig.src = node.getAttribute
            ? node.getAttribute('src') || node.src
            : node.src;
          entityConfig.alt = node.alt;
          entityConfig.height = setImageSizeAttr(node.height)//node.height && node.height.toString().includes("px")? node.height.replace("px", ""):node.height;// | node.style.height;
          entityConfig.width = setImageSizeAttr(node.width)//node.width && node.width.toString().includes("px")? node.width.replace("px", ""):node.width;// | node.style.width;
          return {
            type: 'IMAGE',
            mutability: 'MUTABLE',
            data: entityConfig,
          };
      }
    }
    const setImageSizeAttr = (wh)=>{
      if(wh || wh ===0){
        if(wh == 0){
          wh ="auto"
          
        }
        else if(wh.toString().includes("px")){
          wh = wh.replace("px", "")
        }
      }
      return wh;
    }

  export const moveImgToFigure =(htmlContent)=> {
    return htmlContent;
    // Create a temporary div element
    var tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;

    // Get all img elements
    var imgElements = tempDiv.getElementsByTagName('img');

    // Iterate over img elements and wrap them with a figure tag
    for (var i = 0; i < imgElements.length; i++) {
        var img = imgElements[i];
        var figure = document.createElement('figure');
        img.parentNode.insertBefore(figure, img);
        figure.appendChild(img);
    }

    // Return the updated HTML content
    return tempDiv.innerHTML;
}
export const rteFontFamily = ['Lato', 'Sans-Serif', 'Arial','Georgia','Impact','Tahoma','Times New Roman','Verdana']

export  const rteColors = [
      // Basic colors
      '#000000', '#FFFFFF', '#808080', '#C0C0C0', '#FF0000', '#800000', 
      '#FFFF00', '#808000', '#00FF00', '#008000', '#00FFFF', '#008080', 
      '#0000FF', '#000080', '#FF00FF', '#800080',

      // Red shades
      '#FF6347', '#E9967A', '#FA8072', '#F08080', '#CD5C5C', '#DC143C', 
      '#B22222', '#A52A2A', '#8B0000', '#f26c70',

      // Orange shades
      '#FFA07A', '#FF7F50', '#FF4500', '#FF8C00', '#FFA500', '#FFD700',

      // Yellow shades
      '#FFFFE0', '#FFFACD', '#FAFAD2', '#FFEFD5', '#FFE4B5', '#FFDAB9',

      // Green shades
      '#ADFF2F', '#7FFF00', '#7CFC00', '#00FF00', '#32CD32', '#98FB98', 
      '#00FA9A', '#00FF7F', '#3CB371', '#2E8B57', '#228B22', '#008000', 
      '#006400',

      // Blue shades
      '#87CEEB', '#87CEFA', '#4682B4', '#4169E1', '#0000FF', '#0000CD', 
      '#00008B', '#000080', '#191970', '#155ca5',

      // Purple shades
      '#E6E6FA', '#D8BFD8', '#DDA0DD', '#EE82EE', '#DA70D6', '#FF00FF', 
      '#BA55D3', '#9370DB', '#8A2BE2', '#9400D3', '#9932CC', '#8B008B', 
      '#800080', '#4B0082', '#6A5ACD', '#483D8B',

      // Pink shades
      '#FFC0CB', '#FFB6C1', '#FF69B4', '#FF1493', '#DB7093', '#C71585',

      // Brown shades
      '#FFF8DC', '#FFEBCD', '#FFE4C4', '#FFDEAD', '#F5DEB3', '#DEB887', 
      '#D2B48C', '#BC8F8F', '#A0522D', '#8B4513', '#D2691E', '#CD853F', 
      '#F4A460', '#8B0000', '#A52A2A', '#B22222', '#D2691E',

      // Gray shades
      '#DCDCDC', '#D3D3D3', '#C0C0C0', '#A9A9A9', '#808080', '#696969', 
      '#778899', '#708090', '#2F4F4F'
  ];
  // export const uploadAndGetContent = async (editorState)=>{
  //   //setLoader(true);
  //   let htmlCurrentContent = draftToHtml(convertToRaw(editorState.getCurrentContent()))
  //   console.log("Step 1",htmlCurrentContent)
  //    // Parse HTML to find blob URLs
  //    const parser = new DOMParser();
  //    const doc = parser.parseFromString(htmlCurrentContent, 'text/html');
  //    const blobUrls = Array.from(doc.querySelectorAll('img[src^="blob:"]')).map(img => img.src);
  //    let updatedHtmlContent = htmlCurrentContent;
  //    console.log("Step 2",blobUrls)
  //    if(blobUrls !== undefined && blobUrls !== null && blobUrls.length>0){
  //       updatedHtmlContent = await Promise.all(blobUrls.map(async (blobUrl) => {
  //       const blobResponse = await fetch(blobUrl);
  //       const blobData = await blobResponse.blob();
  //       // Use AWS S3 SDK to upload the blob to S3
  //       let imgLink = await uploadAndGetURL(blobData)
  //       // Replace blob URL with the newly generated S3 URL
  //       return htmlCurrentContent.toString().replace(blobUrl, imgLink)
  //     }));
  //    }
     
  //   let htmlContent = "";
  //   if(isArray(updatedHtmlContent)){
  //     htmlContent = updatedHtmlContent[0]
  //   }else{
  //     htmlContent = updatedHtmlContent
  //   }
    
  //   htmlContent = convertToHtmlEntity(htmlContent)
  //   console.log("Step Last",htmlContent)
  //   return htmlContent;
  
  // }