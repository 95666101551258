import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Label, FormGroup } from 'reactstrap';

import PageTitle from '../../components/Common/PageTitle';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import { saveUser, getUser, getAllRoles } from './Services/user-service';
import { Link } from 'react-router-dom';
import Loader from '../../shared/loader/loader';
import { ShowNotification, GetQueryParam } from '../../helpers/general/generalHelpers';

const AddEditUser = (props) => {
  const [toggleSwitch, settoggleSwitch] = useState(true);
  const [loader, setLoader] = useState(false);
  const [pageTitle, setPageTitle] = useState('Add User');
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    status: 'active',
    roleName: '',
    email: '',
    mobileNumber: null,
    bankName: '',
    accountNumber: '',
    accountHolderName: ''
  });
  const [userRoles, setUserRoles] = useState([]);

  
  useEffect(() => {
    if (props.location.search) {
      setLoader(true);
      setPageTitle('Edit User');
      const userId = GetQueryParam(props.location.search, 'id');
      getUser(userId)
        .then((response) => {
          setUser(response.data.user);
          settoggleSwitch(response.data.user.status && response.data.user.status === 'active');
        })
        .catch((error) => {
          const message =
            error && error.response && error.response.data
              ? error.response.data.message
              : 'There was an error fetching user information, please try again!';
          ShowNotification({ type: 'error', message });
          throw error;
        })
        .finally(() => {
          setLoader(false);
        });
       
    }
    setLoader(true);
    getAllRoles().then((response)=>{
      //console.log(response.data.roles);
      setUserRoles(response.data.roles)
    }).catch((error) => {
      const message =
        error && error.response && error.response.data
          ? error.response.data.message
          : 'There was an error fetching user information, please try again!';
      ShowNotification({ type: 'error', message });
      throw error;
    }).finally(() => {
      setLoader(false);
    });
  }, [props.location.search]);

  function updateUser(event, values) {
    setLoader(true);
    values.status = toggleSwitch ? 'active' : 'inactive';
    values.type = 'REFERRAL';
    values.referralCode = values.referralCode ? values.referralCode : null;
    values.isActive = true;
    saveUser(values, props.history, setLoader);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="relative">
          <Loader loading={loader} />
          {/* Render Page title */}
          <PageTitle title={pageTitle} />

          <AvForm
            className="needs-validation"
            onValidSubmit={(e, v) => {
              updateUser(e, v);
            }}
          >
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title">Basic Information</h4>
                    <p className="card-title-desc">Fill all information below</p>
                    <Row className="agora-lato">
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="firstName">First Name</Label>
                          <AvField
                            name="firstName"
                            placeholder="Enter first name"
                            type="text"
                            errorMessage="Please enter first name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="firstName"
                            value={user.firstName}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="lastName">Last Name</Label>
                          <AvField
                            name="lastName"
                            placeholder="Enter last name"
                            type="text"
                            errorMessage="Please enter last name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="lastName"
                            value={user.lastName}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="email">Email</Label>
                          <AvField
                            name="email"
                            placeholder="Enter email address"
                            type="email"
                            errorMessage=" Please enter a valid email address"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="email"
                            value={user.email}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="mobileNumber">Mobile Number</Label>
                          <AvField
                            name="mobileNumber"
                            placeholder="Enter phone number"
                            type="phone"
                            className="form-control"
                            validate={{
                              required: { value: true, errorMessage: 'Please provide a phone number.' },
                              pattern: {
                                value: /^(\+92|0)?\d{10}$/,
                                errorMessage: 'Please provide a valid phone number.'
                              }
                            }}
                            id="mobileNumber"
                            value={user.mobileNumber || ''}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="userRole">Role</Label>
                          <AvField
                            type="select"
                            name="userRole"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            errorMessage="Please select a role"
                            value={user.userRole}
                          >
                             <option value="">Select role</option>
                            {userRoles.map((role, idx) => {
                              return (
                                <option key={idx} value={role._id}>
                                  {role.roleName}
                                </option>
                              );
                            })}
                          </AvField>
                        </FormGroup>
                      </Col>
                      {pageTitle === 'Add User' && (
                        <Col md="6">
                          <FormGroup>
                            <Label htmlFor="referralCode">Referral Code</Label>
                            <AvField
                              name="referralCode"
                              placeholder="Enter referral code"
                              type="text"
                              errorMessage=" Please enter a valid referral code"
                              className="form-control"
                              // validate={{ required: { value: true } }}
                              id="referralCode"
                            // value={user.email}
                            />
                          </FormGroup>
                        </Col>
                      )}
                      <Col md="6">
                        <Label htmlFor="userStatus">Status</Label>
                        <div className="custom-control status-switch custom-switch mb-2" dir="ltr">
                          <input type="checkbox" className="custom-control-input" id="userStatus" checked={toggleSwitch} />
                          <label
                            className="custom-control-label"
                            htmlFor="userStatus"
                            onClick={(e) => {
                              settoggleSwitch(!toggleSwitch);
                            }}
                          >
                            {toggleSwitch ? 'Active' : 'Inactive'}
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title">Bank Account Information</h4>
                    <p className="card-title-desc">Fill all information below</p>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="bankName">Bank Name</Label>
                          <AvField
                            name="bankName"
                            placeholder="Enter bank name"
                            type="text"
                            // errorMessage="Please enter a bank Nname"
                            className="form-control"
                            // validate={{ required: { value: true } }}
                            id="bankName"
                            value={user.bankName || ''}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="accountNumber">Account Number</Label>
                          <AvField
                            name="accountNumber"
                            placeholder="Enter account number"
                            type="text"
                            // errorMessage="Please enter an account number"
                            className="form-control"
                            // validate={{ required: { value: true } }}
                            id="accountNumber"
                            value={user.accountNumber || ''}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="accountHolderName">Account Holder Name</Label>
                          <AvField
                            name="accountHolderName"
                            placeholder="Enter account holder name"
                            type="text"
                            // errorMessage=" Please enter account holder name"
                            className="form-control"
                            // validate={{ required: { value: true } }}
                            id="accountHolderName"
                            value={user.accountHolderName || ''}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg="12" className="agora-form-buttons text-right">
                <Link
                  to="/users"
                  className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light"
                >
                  Cancel
                </Link>
                <Button
                  type="submit"
                  className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </AvForm>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddEditUser;
