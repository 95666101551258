import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, CardBody, Label, Button, FormGroup } from 'reactstrap';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvField from 'availity-reactstrap-validation/lib/AvField';

import PageTitle from '../../components/Common/PageTitle';
import { ShowNotification } from '../../helpers/general/generalHelpers';
import Loader from '../../shared/loader/loader';
import { getListQuestions2 } from '../../service/question';
import { getListSubjects } from '../../service/subject';
import QuestionListComponent from './QuestionList';
import QuestionPreviewComponent from './QuestionPreview';

import { getPaginationInformation, initialPaginationObject } from '../../helpers/general/pagination';
import './index.scss';

const QuestionTagging = (props) => {
  const [pagination, setPagination] = useState(initialPaginationObject);
  const filterForm = useRef(null);
  const [filters, setFilters] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeState, setAcitveState] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [subjects, setSubjects] = useState([]);

  if (props.location.notification && props.location.notification.show) {
    const notification = props.location.notification;
    ShowNotification({
      type: 'success',
      message: notification.message
    });
    props.location.notification = null;
  }

  const handleGetSubjects = () => {
    getListSubjects({ pageSize: 100 })
      .then((response) => {
        const subjList = response.data.subjects.map((subj) => {
          return { ...subj, id: subj._id, value: subj.title, label: subj.title };
        });
        setSubjects(subjList);
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleGetQuestions = () => {
    setLoader(true);
    getListQuestions2({ pageNumber: currentPage, filters, pageSize: 9 })
      .then((response) => {
        setQuestions(response.data.questions);
        setPagination(getPaginationInformation(response.data.Count, currentPage));
      })
      .catch((error) => {
        ShowNotification({ type: 'error', message: 'Sorry! something went wrong, please contact our support team' });
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const clearFilters = () => {
    filterForm.current.reset();
    setFilters(undefined);
  };

  const handleFilters = (event, values) => {
    setAcitveState(null);
    setFilters(values);
  };

  const paginate = (pageNumber) => {
    setAcitveState(null);
    setCurrentPage(pageNumber);
  };

  const onCLickQuestion = (e, index) => {
    setAcitveState(index);
  };

  useEffect(() => {
    handleGetSubjects();
  }, []);

  useEffect(() => {
    handleGetQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, filters]);

  return (
    <React.Fragment>
      <div className="page-content questionTagging">
        <Container fluid className="relative">
          <Loader loading={loader} />

          {/* Render Page title */}
          <PageTitle title={'QUESTION TAGGING'} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {/* Filters */}
                  <AvForm
                    ref={filterForm}
                    onValidSubmit={(e, v) => {
                      handleFilters(e, v);
                    }}
                  >
                    <Row>
                      <Col lg="2">
                        <FormGroup>
                          <Label>Question ID :</Label>
                          <AvField name="internalId" placeholder="Search" className="form-control filter-field" />
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        <FormGroup>
                          <Label>Subject :</Label>
                          <AvField type="select" name="subject" className="form-control filter-field">
                            <option value="">Please select one</option>
                            {subjects.map((subj, idx) => {
                              return (
                                <option key={idx} value={subj._id}>
                                  {subj.title + ' - ' + subj.studyLevelId}
                                </option>
                              );
                            })}
                          </AvField>
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        <FormGroup>
                          <Label>Question Type :</Label>
                          <AvField type="select" name="type" className="form-control filter-field">
                            <option value="">Select Status</option>
                            <option value="Structured">Structured</option>
                            <option value="MCQ">MCQ</option>
                          </AvField>
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        <FormGroup>
                          <Label>Tagging Status :</Label>
                          <AvField type="select" name="tagging" className="form-control filter-field">
                            <option value="">Please select the status</option>
                            <option key={1} value={'Tagged'}>
                              {' '}
                              Tagged
                            </option>
                            <option key={2} value={'Untagged'}>
                              {' '}
                              Untagged
                            </option>
                          </AvField>
                        </FormGroup>
                      </Col>
                      <Col lg="4" style={{ marginTop: '27px' }}>
                        <Button
                          type="submit"
                          className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
                        >
                          Filter
                        </Button>
                        <Button
                          type="button"
                          onClick={clearFilters}
                          className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light ml-2"
                        >
                          Clear
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>

                  {/* Headline */}
                  <div className="headline font-size-18"> Questions List </div>
                  <Row>
                    <QuestionListComponent
                      questions={questions}
                      activeState={activeState}
                      onCLickQuestion={onCLickQuestion}
                      pagination={pagination}
                      paginate={paginate}
                    />
                    <QuestionPreviewComponent
                      questions={questions}
                      handleGetQuestions={handleGetQuestions}
                      activeState={activeState}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default QuestionTagging;
