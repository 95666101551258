import { requestWithToken } from './index';
import { appConfig } from '../config/config';
import { GetFilterString } from '../helpers/general/generalHelpers';

export const getListStudyGuides = ({ pageNumber, filters, pageSize = appConfig.RecordsPerPage }) => {
	let filterString = GetFilterString(filters);
  	return requestWithToken({
        url: `studyGuide?pageNumber=${pageNumber}&pageSize=${pageSize}${filterString}`,
        method: 'GET'
    })
}

export const postAddStudyGuide = ({ body }) => {
	return requestWithToken({
		url: 'studyGuide',
		method: 'POST',
		data: body
	})
}

export const getDetailStudyGuide = ({ id }) => {
  return requestWithToken({
		url: `studyGuide/${id}`,
		method: 'GET'
	})
}

export const putUpdateStudyGuide = ({ id, body }) => {
	return requestWithToken({
		url: `studyGuide/${id}`,
		method: 'PUT',
		data: body
	})
}


export const deleteStudyGuide = ({ id }) => {
	return requestWithToken({
		url: `studyGuide/${id}`,
		method: 'DELETE',
	})
}
