import { requestWithToken } from './index';
import { appConfig } from '../config/config';
import { GetFilterString } from '../helpers/general/generalHelpers';

export const getListChapter = ({ pageNumber = 1, filters = '', pageSize = appConfig.RecordsPerPage }) => {
    let filterString = GetFilterString(filters);
  return requestWithToken({
        url: `chapter?pageNumber=${pageNumber}&pageSize=${pageSize}${filterString}`,
        method: 'GET'
    })
};
