import React from 'react';
import { Row, Col, Modal, Label, FormGroup, Button } from 'reactstrap';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import AvRadio from 'availity-reactstrap-validation/lib/AvRadio';
import AvRadioGroup from 'availity-reactstrap-validation/lib/AvRadioGroup';
import Loader from '../../shared/loader/loader';

const PopupAddSyllabus = (props) => {
  const { isOpenAddSyllabus, syllabus, setSyllabus, toggleSyllabusModal, modalLoader, updateSyllabus, chapters, assessments } = props;
 
  return (
    <Modal
      isOpen={isOpenAddSyllabus}
      toggle={() => {
        toggleSyllabusModal();
      }}
    >
      <div className="modal-body overflow-hidden">
        <Loader loading={modalLoader} />
        <AvForm
          className="needs-validation"
          onValidSubmit={(e, v) => {
            updateSyllabus(e, v);
          }}
        >
          <h4 className="card-title">Add New Syllabus Entry</h4>
          <p className="card-title-desc">Fill all information below</p>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label htmlFor="syllabus-type">Type</Label>
                <AvRadioGroup
                  inline
                  name="type"
                  id="syllabus-type"
                  required
                  value={syllabus.type}
                  onChange={(e, value) => {
                    if (value) {
                      setSyllabus({ ...syllabus, type: value });
                    }
                  }}
                >
                  <AvRadio label="Chapter" value="chapter" />
                  <AvRadio style={{ marginLeft: '35px' }} label="Assessment" value="assessment" />
                </AvRadioGroup>
              </FormGroup>
            </Col>
            <Col md="12" style={{ marginBottom: '20px' }}>
              {syllabus.type === 'chapter' ? (
                <FormGroup>
                  <Label htmlFor="chapter">Chapter</Label>
                  <AvField
                    type="select"
                    name="chapter"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    value={syllabus.chapter ? syllabus.chapter._id : ''}
                    errorMessage="Please select a chapter"
                  >
                    <option value="">Select Chapter</option>
                    {chapters.map((chapter, index) => (
                      <option key={'_chapter_' + index} value={chapter._id}>
                        {chapter.title}
                      </option>
                    ))}
                  </AvField>
                </FormGroup>
              ) : (
                <FormGroup>
                  <Label htmlFor="assessment">Assessment</Label>
                  <AvField
                    type="select"
                    name="assessment"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    errorMessage="Please select an assessment"
                    value={syllabus.assessment ? syllabus.assessment._id : ''}
                  >
                    <option value="">Select assessment</option>
                    {
                      assessments.map( (assessment, index) => 
                          <option key={'_assessment_'+index} value={assessment._id}>{assessment.title}</option>
                      )
                    }
                  </AvField>
                </FormGroup>
              )}
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="agora-form-buttons text-center">
              <Button
                onClick={() => {
                  toggleSyllabusModal();
                }}
                className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
              >
                Save
              </Button>
            </Col>
          </Row>
        </AvForm>
      </div>
    </Modal>
  );
};

export default PopupAddSyllabus;
