import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

const BackButton = (props) => {
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <Link to={props.url} className="mb-0 font-size-14 back-button">
            <i className="fa fa-arrow-left"></i> {props.text}
          </Link>
        </div>
      </Col>
    </Row>
  );
};

export default BackButton;
