import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import AvRadioGroup from 'availity-reactstrap-validation/lib/AvRadioGroup';
import AvRadio from 'availity-reactstrap-validation/lib/AvRadio';
import { Row, Col, FormGroup, Label, Button, Modal } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import { ShowNotification } from '../../helpers/general/generalHelpers';
import { postAddAssessment, putUpdateAssessment } from '../../service/assessment';
import { getListStudyGuides } from '../../service/studyGuide';
import { getListSubjects } from '../../service/subject';
import { getListChapter } from '../../service/chapter';

const tempStatus = [
  {
    id: true,
    value: true,
    label: 'Active'
  },
  {
    id: false,
    value: false,
    label: 'Inactive'
  }
]

const PopupAssessment = (props) => {
  const history = useHistory();

  const [loader, setLoader] = useState(true);
  const [modalOpen, setModalOpen] = useState(props.show);

  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState();

  const [studyGuides, setStudyGuides] = useState([]);
  const [selecttedStudyGuides, setSelecttedStudyGuides] = useState();

  const [chapters, setChapters] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState();

  const [selectedStatus, setSelectedStatus] = useState(true);
  const [selectedLevel, setSelectedLevel] = useState()

  const [valueAssessment, setValueAssessment] = useState({});


  const handleGetSubjets = () => {
    getListSubjects({ pageSize: 100 })
      .then((response) => {
        const subjList = response.data.subjects.map((subj) => {
          return { ...subj, id: subj._id, value: subj.title, label: subj.title + ' - ' + subj.studyLevelId };
        });
        setSubjects(subjList);
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
  }

  const handleGetStudyGuides = (value) => {
    const param = value.id ? { subject: value.title || value.label  } : '';
    getListStudyGuides({ pageSize: 100, pageNumber: 1, filters: param })
      .then((response) => {
        const subjList = response.data.studyGuides.map((subj) => {
          return { ...subj, id: subj._id, value: subj.title, label: subj.title };
        });
        setStudyGuides(subjList);
      })
      .catch((error) => {
        throw error;
      })
  }

  const handleGetListChapter = (value) => {
    const param = value?.id ? { subjectId: value.id } : '';
    getListChapter({ pageSize: 99999, filters: param })
    .then((response) => {
      const chapList = response.data.chapters.map((chap) => {
        return { id: chap._id, value: chap.title, label: chap.title };
      });
      setChapters(chapList);
    })
    .catch((error) => {
      throw error;
    })
  }

  const toggleModal = () =>  {
    props.toggleModal();
  }

  const requestAddAssessment = (value) => {
    const requestBody = {
      title : value.title,
      type : value.type,
      level: value.level,
      subject: selectedSubject.id,
      active: true,
      studyLevelId: selectedSubject.studyLevelId,
      diagnosticTest: false
    }
  
    if (value.level === "Subject") {
      requestBody.diagnosticTest = value.diagnosticTest
    } else if (value.level === "Chapter") {
      requestBody.chapter = selectedChapter.id
    } else if (value.level === "Study Guide") {
      requestBody.studyGuide = selecttedStudyGuides.id
    }
  
    postAddAssessment({ body: requestBody })
      .then((response) => {
        const assessmentsId = response.data.assessment._id;
        history.push(`/assessment?id=${assessmentsId}`);
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
  }

  const requestUpdateAssessment = (value) => {
    const requestBody = {
      title : value.title,
      type : value.type,
      level: value.level,
      subject: selectedSubject.id,
      active: selectedStatus?.value || false,
      studyLevelId: selectedSubject.studyLevelId,
      diagnosticTest: false
    }
  
    if (value.level === "Subject") {
      requestBody.diagnosticTest = value.diagnosticTest
    } else if (value.level === "Chapter") {
      requestBody.chapter = selectedChapter.id
    } else if (value.level === "Study Guide") {
      requestBody.studyGuide = selecttedStudyGuides.id
    }

    putUpdateAssessment({ id: props.edit?._id, body: requestBody })
      .then((response) => {
        props.UpdateContent()
        toggleModal();
      })
      .catch((error) => {
        ShowNotification({
          type: 'error',
          message: error.message
        });
      })
      .finally(() => {
        setLoader(false);
      });
  }

  const handleSubmitForm = (e, v) => {

    if (
      (v.level === "Subject" && v.diagnosticTest && selectedSubject && (v.title !== "")) ||
      (v.level === "Chapter" && selectedChapter?.id && selectedSubject && (v.title !== "")) ||
      (v.level === "Study Guide" && selecttedStudyGuides?.id && selectedSubject && (v.title !== ""))
      ) {
        if (props.edit) {
          requestUpdateAssessment(v);
        } else {
          requestAddAssessment(v);
        }
    } else {
      ShowNotification({
        type: 'error',
        message: 'Please fill all fields.'
      });
    }
     

    
  }

  const onChangeSubject = (value) => {
    setSelectedSubject(value);
    // get value dropdown
    handleGetListChapter(value);
    handleGetStudyGuides(value);
  }

  const handleAutoFillForm = () => {
    const data = props.edit;
    const tempSelectedSubject = { id: data.subject._id, value: data.subject.title, label: data.subject.title };

    setSelectedLevel(data.level);
    setSelectedSubject(tempSelectedSubject);
    setValueAssessment({ title: data.title, type: data.type, level: data.level, diagnosticTest: data.diagnosticTest });
    if(data.level === 'Chapter'){
      setSelectedChapter({ id: data.chapter._id, value: data.chapter.title, label: data.chapter.title });
    } else if(data.level === 'Study Guide'){
      setSelecttedStudyGuides({ id: data.studyGuide._id, value: data.studyGuide.title, label: data.studyGuide.title });
    }
    setSelectedStatus(data.active? tempStatus[0] : tempStatus[1]);

    handleGetListChapter(tempSelectedSubject);
    handleGetStudyGuides(tempSelectedSubject);
  }

  const hangeChangeLevel = (e, v) => {
    setSelectedLevel(v)

    // get value dropdown
    handleGetListChapter(selectedSubject);
    handleGetStudyGuides(selectedSubject);
  }

  useEffect(() => {
    setModalOpen(props.show);
    if (props.show && subjects.length === 0) {
      handleGetSubjets();
    }

    if(!props.edit) {
      setSelectedChapter(null)
      setSelectedSubject(null)
      setSelectedLevel("Chapter")
      setValueAssessment({ level: "Chapter", type: "MCQ" });
    }else {
      handleAutoFillForm();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  return (
    <Modal
      centered
      isOpen={modalOpen}
      toggle={() => {
        toggleModal();
      }}
    >
      <div className="modal-body overflow-hidden">
        <AvForm
          model={valueAssessment}
          className="needs-validation"
          onValidSubmit={handleSubmitForm}
        >
          <h4 className="card-title">{props.edit ? 'Edit Assessment' : 'Add New Assessment'}</h4>
          <p className="card-title-desc">Fill all information below</p>
          <Row>
            <FormGroup className="col-md-12">
              <Col>
                <Label>Title</Label>
                <AvField
                  required
                  name="title"
                  placeholder="Enter Title"
                  className="form-control filter-field"
                />
              </Col>
              <Col className="mb-2">
                <Label>Type</Label>
                <AvRadioGroup inline name="type" required>
                  <AvRadio label="MCQ" value="MCQ" />
                  <AvRadio label="Structured" value="Structured" />
                </AvRadioGroup>
              </Col>
              <Col className="mb-2">
                <Label>Subject Name</Label>
                <Select
                  value={selectedSubject}
                  isLoading={loader}
                  isSearchable
                  name="subject"
                  placeholder="Select Subject Name"
                  options={subjects}
                  onChange={(value) => {
                    if (value?.id !== selectedSubject?.id) {
                      setSelectedChapter(null);
                      onChangeSubject(value)
                    }
                  }}
                />
              </Col>
              <Col className="mb-2">
                <Label>Level</Label>
                <AvRadioGroup inline name="level" required onChange={hangeChangeLevel}>
                  <AvRadio label="Subject" value="Subject" />
                  <AvRadio label="Chapter" value="Chapter" />
                  <AvRadio label="Study Guide" value="Study Guide" />
                </AvRadioGroup>
              </Col>
              {selectedLevel === 'Subject' &&
                <Col className="mb-2">
                  <Label>Diagnostic Test?</Label>
                  <AvRadioGroup inline name="diagnosticTest" required>
                    <AvRadio label="Yes" value={true} />
                    <AvRadio label="No" value={false} />
                  </AvRadioGroup>
                </Col>
              }
              
              {selectedLevel === 'Chapter' &&
                <Col className="mb-2">
                  <Label>Chapter Name</Label>
                  <Select
                    value={selectedChapter}
                    name="chapter"
                    placeholder="Select Chapter Name"
                    options={chapters}
                    onChange={(value) => {
                      setSelectedChapter(value);
                    }}
                  />
              </Col>
              }

              {selectedLevel === 'Study Guide' &&
                <Col className="mb-2">
                  <Label>Study Guide Name</Label>
                  <Select
                    value={selecttedStudyGuides}
                    name="studyGuide"
                    placeholder="Select Study Guide"
                    options={studyGuides}
                    onChange={(value) => {
                      setSelecttedStudyGuides(value);
                    }}
                  />
              </Col>
              }

              {props.edit && 
                <Col className="mb-2">
                  <Label>Status</Label>
                  <Select
                    value={selectedStatus}
                    name="status"
                    placeholder="Enter Status"
                    options={tempStatus}
                    onChange={(value) => {
                      setSelectedStatus(value);
                    }}
                  />
              </Col>}
              
            </FormGroup>
          </Row>

          <Row>
            <Col lg="12" className="agora-form-buttons text-center pt-4">
              <Button
                onClick={() => {
                  toggleModal();
                }}
                className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
              >
                {props.edit ? 'Save' : 'Next'}
              </Button>
            </Col>
          </Row>
        </AvForm>
      </div>
    </Modal>
  );
};

export default PopupAssessment;
