import React, { useEffect, useState } from 'react';
import { Row, Col, UncontrolledTooltip, Button } from 'reactstrap';
import Select from 'react-select';
import parse from 'html-react-parser';
import { getListStudyGuides } from '../../service/studyGuide';
import { putUpdateStudyGuideTag } from '../../service/question';
import { ShowNotification } from '../../helpers/general/generalHelpers';
import './index.scss';
import cloneDeep from 'lodash.clonedeep';

const QuestionPreview = (props) => {
  const {questions, activeState, handleGetQuestions } = props;
  const [studyGuides, setStudyGuides] = useState([]);
  const [studyGuidesAll, setStudyGuidesAll] = useState([]);
  const [savedStudyGuided, setSavedStudyGuided] = useState([]);
  const [tempStudyGuides, setTempStudyGuides] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [selectedStudyGuide, setselectedStudyGuide] = useState(null);
  const [optionsNames] = useState(['A', 'B', 'C', 'D', 'E']);
 
  const onAddTagging = () => {
    if(!(activeState === null || selectedStudyGuide === null)){
      const tempData = cloneDeep(tempStudyGuides);
      const findSameData = tempData.find(item => item === selectedStudyGuide.id);
      if(!findSameData){
        tempData.push(selectedStudyGuide.id);
        setTempStudyGuides(tempData);
      }else {
        ShowNotification({ type: 'error', message:'Data already added!' });
      }
    }
  }

  const onremoveTagging = (index) => {
      const tempData = cloneDeep(tempStudyGuides);
      tempData.splice(index, 1);
      setTempStudyGuides(tempData)
  }

  const onSubmitTagging = () => {
    const id = questions?.[activeState]?._id;
    const body = {
      studyGuides :
      {
        tag: tempStudyGuides
      }            
  }
    putUpdateStudyGuideTag({ id, body })
    .then(() => {
      ShowNotification({ type: 'success', message:'Great, you’ve successfully updated question tagging!' });
      handleGetQuestions();
    })
    .catch((error) => {
      throw error;
    })
    .finally(() => {
    })
  }

  const handleFilterStudyGuides = () => {
    const subjectID = questions?.[activeState]?.subject?._id;
    const studyGuideFilter = cloneDeep(studyGuidesAll).filter(c => c?.item?.subject?._id === subjectID);
    setStudyGuides(studyGuideFilter)
  }

  const handleGetStudyGuides = () => {
    setLoadingData(true);
    getListStudyGuides({ pageSize: 1115, pageNumber: 1 })
    .then((response) => {
      const subjList = response.data.studyGuides.map((data) => {
        return {
          item: data,
          id: data._id,
          value: data._id,
          label: `${data.subject?.title || ''} - ${data?.studyLevelId} - ${data.title}`
        }
      });
      setStudyGuidesAll(subjList);

      const subjectID = questions?.[activeState]?.subject?._id;
      const studyGuideFilter = cloneDeep(subjList).filter(c => c?.item?.subject?._id === subjectID);
      setStudyGuides(studyGuideFilter)
      
    })
    .catch((error) => {
      throw error;
    })
    .finally(() => {
      setLoadingData(false)
    })
  }

  const handleToArray = (data) => {
    if(data){
      const getKey = Object.keys(data);
      const dataArray = getKey.map(e => {
        const temp = data?.[e];
        temp.key = e;
        return temp
      });
      return dataArray || [];
    }
    return [];
  }

  useEffect(() => {
    if(activeState !== null) {
      if(studyGuidesAll.length < 1) { handleGetStudyGuides(); }
      else { handleFilterStudyGuides(); }
      setselectedStudyGuide(null);
      setTempStudyGuides(questions?.[activeState]?.studyGuides);
      setSavedStudyGuided(questions?.[activeState]?.studyGuides);
    }
  }, [activeState])

  return (
    <React.Fragment>
      <Col lg="8">
        <Row>
          <Col lg="6">
            <br />
            <div className="font-size-18"> Question Preview </div>
            <br />
          </Col>
          <Col lg="6">
            <br />
            <div className="font-size-18"> Study Guide Tagging </div>
            <br />
          </Col>
        </Row>
        <Row style={{ height: 'calc(100% - 85px)' }}>
          <Col lg="6" className="border-right">
            <div className={`question-preview preview-${questions?.[activeState]?.type}`}>
              {parse(
                questions?.[activeState]?.questions?.body ||
                  "<p style='color: #c8c8c8;text-align: center;margin-top: 200px;'> Sorry, currently data not available <br/> Click on “Question List” to add question tagging </p>"
              )}

              {/* MCQ */}
              {/* {questions?.[activeState]?.type === 'MCQ' &&
                <div>
                  {handleToArray(questions?.[activeState]?.mcqOptions).map((item, index) => (
                    <div key={`${item.key}-${index}-body`} className="font-size-18"> ({item.key}) {parse(item.body)} </div>
                  ))}
                </div>
              } */}

              {/* STRUCTURE */}
              {questions?.[activeState]?.type === 'Structured' && (
                <div>
                  {questions?.[activeState]?.questions?.subQuestions?.map((item, index) => (
                    <div key={item.id}>
                      <div> - Subquestion ({optionsNames[index]}) </div>
                      <div> {parse(item.body)} </div>
                      <div>
                        {item?.subQuestions.map((item2, index2) => (
                          <div key={item2.id} className="subsubquestion">
                            <div>
                              {' '}
                              -- Sub-Subquestion ({optionsNames[index]}) ({index2 + 1}){' '}
                            </div>
                            <div> {parse(item2.body)} </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {/* MCQ */}
            {questions?.[activeState]?.type === 'MCQ' && (
              <div className="answer-mcq-section">
                {handleToArray(questions?.[activeState]?.mcqOptions).map((item, index) => (
                  <div
                    className={`options ${item.answer ? 'option-true' : 'option-false'}`}
                    key={`${item.key}-${index}`}
                  >
                    {' '}
                    {item.key}{' '}
                  </div>
                ))}
              </div>
            )}
          </Col>
          <Col lg="6" className="border-right">
            <Row>
              <Col lg="11">
                <Select
                  isLoading={loadingData}
                  isSearchable
                  isDisabled={activeState === null}
                  name="newQuestion"
                  placeholder="Search by Study Guide Title"
                  options={studyGuides}
                  value={selectedStudyGuide}
                  onChange={(value) => {
                    setselectedStudyGuide(value);
                  }}
                />
              </Col>
              <Col style={{ padding: '0px' }} onClick={onAddTagging}>
                <i
                  className={`bx bxs-plus-circle ${
                    activeState === null || selectedStudyGuide === null ? 'icon-plus-disable' : 'icon-plus'
                  }`}
                  id="icon-plus"
                />
                <UncontrolledTooltip placement="top" target={'icon-plus'}>
                  Add
                </UncontrolledTooltip>
              </Col>
            </Row>
            <br />
            <div className="body-study-guides">
              {tempStudyGuides.map((item, index) => {
                const resultFind =
                  studyGuidesAll.find((it) => it.id === item._id) || studyGuidesAll.find((it) => it.id === item);
            
                return (
                  <div className="study-guide" key={index}>
                    <div className="left">
                      <div className="subject ellipsis">
                        {' '}
                        {resultFind?.item?.subject?.title || ''} - {resultFind?.item?.studyLevelId || ''}{' '}
                      </div>
                      <div className="title ellipsis"> {resultFind?.item?.title || '-'} </div>
                    </div>
                    <i className={`bx bxs-x-circle icon-remove`} onClick={() => onremoveTagging(index)} />
                  </div>
                );
              })}
            </div>

            <Button
              style={{ width: '100%' }}
              type="submit"
              disabled={
                !!(
                  activeState === null ||
                  tempStudyGuides.length === 0 ||
                  JSON.stringify(savedStudyGuided) === JSON.stringify(tempStudyGuides)
                )
              }
              onClick={onSubmitTagging}
              className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
            >
              Save
            </Button>
          </Col>
        </Row>
      </Col>
    </React.Fragment>
  );
};

export default QuestionPreview;
