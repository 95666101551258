import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
  Label,
  Button
} from 'reactstrap';

import PageTitle from '../../components/Common/PageTitle';
import { SetStatusStyle, ShowNotification, ObjectHasValues } from '../../helpers/general/generalHelpers';
import Loader from '../../shared/loader/loader';
import { getPaginationInformation, initialPaginationObject } from '../../helpers/general/pagination';
import { getAllRoles, getUsers } from './Services/user-service';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import PopupAddReward from '../Referrals/addReward';

const UsersList = (props) => {
  const [pagination, setPagination] = useState(initialPaginationObject);
  const [currentPage, setCurrentPage] = useState(1);
  const filterForm = useRef(null);
  const [filters, setFilters] = useState(null);
  const [users, setUsers] = useState({
    users: []
  });
  const [loader, setLoader] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [roles, setRoles] = useState([]);

  if (props.location.notification && props.location.notification.show) {
    const notification = props.location.notification;
    ShowNotification({
      type: 'success',
      message: notification.message
    });
    props.location.notification = null;
  }

  useEffect(() => {
    setLoader(true); // Start loading

    getUsers(currentPage, filters)
      .then((response) => {
        const users = response.data.users;
        setUsers({ users });
        setPagination(getPaginationInformation(response.data.Count, currentPage));

        getAllRoles().then((response)=>{
          //console.log(response.data.roles);
          setRoles(response.data.roles || [])
        }).catch((error) => {
          const message =
            error && error.response && error.response.data
              ? error.response.data.message
              : 'There was an error fetching user information, please try again!';
          ShowNotification({ type: 'error', message });
          throw error;
        }).finally(() => {
          setLoader(false);
        });
      })
      .catch((error) => {
        throw error;
      });
  }, [currentPage, filters]);

  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
  }

  function handleFilters(event, values) {
    if (ObjectHasValues(values)) {
      setFilters(values);
    }
  }

  function clearFilters() {
    filterForm.current.reset();
    setFilters(null);
  }

  function toggleModal() {
    setModalOpen(!modalOpen);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  const getRoleNameById = roleId => {
    const role = roles.find(role => role._id === roleId);
    return role ? role.roleName : roleId;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="relative">
          <Loader loading={loader} />
          <Row>
            <Col xs="12">
              <div className="d-flex mb-2 align-items-center justify-content-between">
                <h4 className="mb-0 p-1 mr-auto font-size-20 font-weight-bold">Users</h4>
                <div className="">
                  <Button
                    onClick={(e) => {
                      setModalOpen(true);
                    }}
                    className="btn font-size-12 px-4 btn-primary inline agora-button border-radius waves-effect waves-light"
                  >
                    Referrals Reward
                  </Button>
                  <Link to="/user" className="m-1 font-size-12 px-4 btn btn-primary agora-button border-radius waves-effect waves-light">
                    Add New User
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          {/* Render Page title */}
          {/* <PageTitle>
            <div className="page-title-right">
              <Button
                onClick={(e) => {
                  setModalOpen(true);
                }}
                className="btn btn-primary btn-block agora-button border-radius waves-effect waves-light"
              >
                Referrals Reward
              </Button>
            </div>
          </PageTitle>

          <PageTitle title="USERS" button name="+ Add New User" url="/user" /> */}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="p-10">
                  <AvForm
                    ref={filterForm}
                    onValidSubmit={(e, v) => {
                      handleFilters(e, v);
                    }}
                  >
                    <Row>
                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label htmlFor="email">Email</Label>
                          <AvField
                            name="email"
                            placeholder="Please Enter an Email "
                            type="text"
                            className="form-control filter-field"
                            // onChange={handleFilters}
                            id="email-filter"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label htmlFor="referral-filter">Referral Code</Label>
                          <AvField
                            name="referralCode"
                            placeholder="Please Enter Referral Code"
                            type="text"
                            className="form-control filter-field"
                            id="referral-filter"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label htmlFor="referrer-filter">Referrer Code</Label>
                          <AvField
                            name="referrerCode"
                            placeholder="Please Enter Referrer Code"
                            type="text"
                            className="form-control filter-field"
                            id="referrer-filter"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label htmlFor="status-filter">Status</Label>
                          <AvField type="select" name="status" id="status-filter" className="form-control filter-field">
                            <option value="" disabled>
                              Select Status
                            </option>
                            <option value="all">All</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </AvField>
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label htmlFor="role">Role</Label>
                          <AvField type="select" name="role" className="form-control filter-field">
                            <option value="" disabled>
                              Select role
                            </option>
                            <option value="all">All</option>
                            <option value="admin">Admin</option>
                            <option value="student">Student</option>
                            <option value="assessor">Assessor</option>
                            <option value="contenteditor">Content Editor</option>
                          </AvField>
                        </FormGroup>
                      </Col>
                      <div className="pt-4.5 px-0 pl-1 col-sm-12 col-md-auto agora-filter-buttons">
                        <Button
                          type="submit"
                          className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
                        >
                          Filter
                        </Button>
                        <Button
                          type="button"
                          onClick={clearFilters}
                          className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light ml-2"
                        >
                          Clear
                        </Button>
                      </div>
                    </Row>
                  </AvForm>
                  <div className="table-responsive mt-2">
                    <Table className="table-centered table-nowrap table-hover text-center">
                      <thead className="thead-light font-size-15">
                        <tr>
                          <th scope="col" className="text-center" style={{ width: '70px' }}>
                            #
                          </th>
                          <th scope="col" className="text-left">
                            Name
                          </th>
                          <th scope="col" className="text-left">
                            Email
                          </th>
                          <th scope="col">Referral</th>
                          <th scope="col">Referrer</th>
                          <th scope="col">Points</th>
                          <th scope="col">Status</th>
                          <th scope="col">Last Updated</th>
                          <th scope="col" className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="agora-lato">
                        {users.users.map((user, i) => (
                          <tr key={'_user_' + i}>
                            <td className="text-center">
                              {!user.profileImage ? (
                                <div className="avatar-xs d-inline-block">
                                  <span className="avatar-title rounded-circle">{user.firstName.charAt(0)}</span>
                                </div>
                              ) : (
                                <div>
                                  <img
                                    className="rounded-circle avatar-xs"
                                    src={user.profileImage}
                                    alt={user.firstName}
                                  />
                                </div>
                              )}
                            </td>
                            <td>
                              <h5 className="font-size-14 mb-1 text-left">
                                <Link
                                  to={{
                                    pathname: '/profile',
                                    search: '?id=' + user._id
                                  }}
                                  className="text-dark"
                                >
                                  {user.firstName + ' ' + user.lastName}
                                </Link>
                              </h5>
                              <p className="text-muted mb-0 text-capitalize text-left">
                                {/*{user.role === 'contenteditor' ? 'Content Editor' : user.role}*/}
                                {user.userRole ? getRoleNameById(user.userRole) : ''}
                              </p>
                            </td>
                            <td className="text-left">{user.email}</td>
                            <td>{user.referralId?.Code ? user.referralId?.Code : '-'}</td>
                            <td>{user.referredBy?.Code ? user.referredBy?.Code : '-'}</td>
                            <td>{user.points ? user.points : 0}</td>
                            <td>
                              <div>
                                <SetStatusStyle status={user.status} />
                              </div>
                            </td>
                            <td>
                              {new Intl.DateTimeFormat('en-GB', {
                                year: '2-digit',
                                month: 'short',
                                day: '2-digit'
                              }).format(new Date(user.updatedAt))}
                            </td>
                            <td className="text-center">
                              <ul className="list-inline font-size-20 contact-links mb-0">
                                <li className="list-inline-item px-2">
                                  <Link
                                    to={{
                                      pathname: '/profile',
                                      search: '?id=' + user._id
                                    }}
                                    id={'profile' + user._id}
                                  >
                                    <i className="bx bx-show-alt"></i>
                                    <UncontrolledTooltip placement="top" target={'profile' + user._id}>
                                      View
                                    </UncontrolledTooltip>
                                  </Link>
                                </li>
                                <li className="list-inline-item px-2">
                                  <Link
                                    to={{
                                      pathname: '/user',
                                      search: '?id=' + user._id
                                    }}
                                    id={'edit-user' + user._id}
                                  >
                                    <i className="bx bx-edit-alt"></i>
                                    <UncontrolledTooltip placement="top" target={'edit-user' + user._id}>
                                      Edit
                                    </UncontrolledTooltip>
                                  </Link>
                                </li>
                                <li className="list-inline-item px-2">
                                  <Link
                                    to={{
                                      pathname: '/viewAllReferrals',
                                      search: '?id=' + user.referralId?._id,
                                      state: {
                                        user: user
                                      }
                                    }}
                                    id={'user-referral' + user._id}
                                  >
                                    <i className="bx bx-group"></i>
                                    <UncontrolledTooltip placement="top" target={'user-referral' + user._id}>
                                      Referrals
                                    </UncontrolledTooltip>
                                  </Link>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <Row>
                    <Col lg="12">
                      <Pagination className="pagination pagination-rounded justify-content-center mt-4">
                        <PaginationItem disabled={pagination.currentPage === 1}>
                          <PaginationLink
                            first
                            onClick={(e) => {
                              paginate(1);
                            }}
                          />
                        </PaginationItem>
                        <PaginationItem disabled={pagination.currentPage === 1}>
                          <PaginationLink
                            previous
                            onClick={(e) => {
                              paginate(pagination.currentPage - 1);
                            }}
                          />
                        </PaginationItem>
                        {pagination.pages.map((page, index) => (
                          <PaginationItem key={'_page_' + index} active={page === pagination.currentPage}>
                            <PaginationLink
                              onClick={(e) => {
                                paginate(page);
                              }}
                            >
                              {page}
                            </PaginationLink>
                          </PaginationItem>
                        ))}
                        <PaginationItem disabled={pagination.currentPage === pagination.totalPages}>
                          <PaginationLink
                            next
                            onClick={(e) => {
                              paginate(pagination.currentPage + 1);
                            }}
                          />
                        </PaginationItem>
                        <PaginationItem disabled={pagination.currentPage === pagination.totalPages}>
                          <PaginationLink
                            last
                            onClick={(e) => {
                              paginate(pagination.totalPages);
                            }}
                          />
                        </PaginationItem>
                      </Pagination>
                      <div className="records-count text-center">
                        <p>
                          Showing <strong>{pagination.startIndex + 1}</strong> to{' '}
                          <strong>{pagination.endIndex + 1}</strong> of <strong>{pagination.totalItems}</strong> records
                        </p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <PopupAddReward show={modalOpen} toggleModal={toggleModal} />
    </React.Fragment>
  );
};

export default UsersList;
