import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
  Label,
  Button
} from 'reactstrap';

import PageTitle from '../../components/Common/PageTitle';
import { SetStatusStyle, ShowNotification, ObjectHasValues } from '../../helpers/general/generalHelpers';
import Loader from '../../shared/loader/loader';
import { getPaginationInformation, initialPaginationObject } from '../../helpers/general/pagination';
import { getRoles } from './Services/role-service';
import PopupAddReward from '../Referrals/addReward';

const RoleList = (props) => {
  const [pagination, setPagination] = useState(initialPaginationObject);
  const [currentPage, setCurrentPage] = useState(1);
  const filterForm = useRef(null);
  const [filters, setFilters] = useState(null);
  const [roles, setRoles] = useState({
    roles: []
  });
  const [loader, setLoader] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);

  if (props.location.notification && props.location.notification.show) {
    const notification = props.location.notification;
    ShowNotification({
      type: 'success',
      message: notification.message
    });
    props.location.notification = null;
  }

  useEffect(() => {
    setLoader(true);
    getRoles(currentPage, filters)
      .then((response) => {
        const roles = response.data.roles;
        setRoles({ roles });
        setPagination(getPaginationInformation(response.data.Count, currentPage));
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
  }, [currentPage, filters]);

  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
  }

  // function handleFilters(event, values) {
  //   if (ObjectHasValues(values)) {
  //     setFilters(values);
  //   }
  // }

  // function clearFilters() {
  //   filterForm.current.reset();
  //   setFilters(null);
  // }

  function toggleModal() {
    setModalOpen(!modalOpen);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="relative">
          <Loader loading={loader} />
          <Row>
            <Col xs="12">
              <div className="d-flex mb-2 align-items-center justify-content-between">
                <h4 className="mb-0 p-1 mr-auto font-size-20 font-weight-bold">Roles</h4>
                <div className="">
                  <Link to="/role" className="m-1 font-size-12 px-4 btn btn-primary agora-button border-radius waves-effect waves-light">
                    Add New Role
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          {/* Render Page title */}
          {/* <PageTitle>
            <div className="page-title-right">
              <Button
                onClick={(e) => {
                  setModalOpen(true);
                }}
                className="btn btn-primary btn-block agora-button border-radius waves-effect waves-light"
              >
                Referrals Reward
              </Button>
            </div>
          </PageTitle>

          <PageTitle title="USERS" button name="+ Add New User" url="/user" /> */}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="p-10">
                  {/* <AvForm
                    ref={filterForm}
                    onValidSubmit={(e, v) => {
                      handleFilters(e, v);
                    }}
                  >
                    <Row>
                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label htmlFor="email">Email</Label>
                          <AvField
                            name="email"
                            placeholder="Please Enter an Email "
                            type="text"
                            className="form-control filter-field"
                            // onChange={handleFilters}
                            id="email-filter"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label htmlFor="referral-filter">Referral Code</Label>
                          <AvField
                            name="referralCode"
                            placeholder="Please Enter Referral Code"
                            type="text"
                            className="form-control filter-field"
                            id="referral-filter"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label htmlFor="referrer-filter">Referrer Code</Label>
                          <AvField
                            name="referrerCode"
                            placeholder="Please Enter Referrer Code"
                            type="text"
                            className="form-control filter-field"
                            id="referrer-filter"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label htmlFor="status-filter">Status</Label>
                          <AvField type="select" name="status" id="status-filter" className="form-control filter-field">
                            <option value="" disabled>
                              Select Status
                            </option>
                            <option value="all">All</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </AvField>
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label htmlFor="role">Role</Label>
                          <AvField type="select" name="role" className="form-control filter-field">
                            <option value="" disabled>
                              Select role
                            </option>
                            <option value="all">All</option>
                            <option value="admin">Admin</option>
                            <option value="student">Student</option>
                            <option value="assessor">Assessor</option>
                            <option value="contenteditor">Content Editor</option>
                          </AvField>
                        </FormGroup>
                      </Col>
                      <div className="pt-4.5 px-0 pl-1 col-sm-12 col-md-auto agora-filter-buttons">
                        <Button
                          type="submit"
                          className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
                        >
                          Filter
                        </Button>
                        <Button
                          type="button"
                          onClick={clearFilters}
                          className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light ml-2"
                        >
                          Clear
                        </Button>
                      </div>
                    </Row>
                  </AvForm> */}
                  <div className="table-responsive mt-2">
                    <Table className="table-centered table-nowrap table-hover text-center">
                      <thead className="thead-light font-size-15">
                        <tr>
                          <th scope="col" className="text-center" style={{ width: '70px' }}>
                            #
                          </th>
                          <th scope="col" className="text-left">
                            Name
                          </th>
                          <th scope="col">Status</th>
                          <th scope="col" className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="agora-lato">
                        {roles.roles.map((role, i) => (
                          <tr key={'_role_' + i}>
                            <td className="text-center">
                             {i+1}
                            </td>
                            <td className="text-left">{role.roleName}</td>
                            <td>
                              <div>
                                <SetStatusStyle status={role.isActive?"active":"inactive"} />
                              </div>
                            </td>
                            <td className="text-center">
                              <ul className="list-inline font-size-20 contact-links mb-0">
                                <li className="list-inline-item px-2">
                                  <Link
                                    to={{
                                      pathname: '/role',
                                      search: '?id=' + role._id
                                    }}
                                    id={'edit-role' + role._id}
                                  >
                                    <i className="bx bx-edit-alt"></i>
                                    <UncontrolledTooltip placement="top" target={'edit-role' + role._id}>
                                      Edit
                                    </UncontrolledTooltip>
                                  </Link>
                                  <Link
                                    to={{
                                      pathname: '/role-privileges',
                                      search: '?id=' + role._id
                                    }}
                                    id={'view-permission' + role._id}
                                  >
                                    <i className="bx bx-user"></i>
                                    <UncontrolledTooltip placement="top" target={'view-permission' + role._id}>
                                      UI Permission
                                    </UncontrolledTooltip>
                                  </Link>
                                  <Link
                                    to={{
                                      pathname: '/role-api-privileges',
                                      search: '?id=' + role._id
                                    }}
                                    id={'view-api-permission' + role._id}
                                  >
                                    <i className="bx bx-user"></i>
                                    <UncontrolledTooltip placement="top" target={'view-api-permission' + role._id}>
                                      API Permission
                                    </UncontrolledTooltip>
                                  </Link>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <Row>
                    <Col lg="12">
                      <Pagination className="pagination pagination-rounded justify-content-center mt-4">
                        <PaginationItem disabled={pagination.currentPage === 1}>
                          <PaginationLink
                            first
                            onClick={(e) => {
                              paginate(1);
                            }}
                          />
                        </PaginationItem>
                        <PaginationItem disabled={pagination.currentPage === 1}>
                          <PaginationLink
                            previous
                            onClick={(e) => {
                              paginate(pagination.currentPage - 1);
                            }}
                          />
                        </PaginationItem>
                        {pagination.pages.map((page, index) => (
                          <PaginationItem key={'_page_' + index} active={page === pagination.currentPage}>
                            <PaginationLink
                              onClick={(e) => {
                                paginate(page);
                              }}
                            >
                              {page}
                            </PaginationLink>
                          </PaginationItem>
                        ))}
                        <PaginationItem disabled={pagination.currentPage === pagination.totalPages}>
                          <PaginationLink
                            next
                            onClick={(e) => {
                              paginate(pagination.currentPage + 1);
                            }}
                          />
                        </PaginationItem>
                        <PaginationItem disabled={pagination.currentPage === pagination.totalPages}>
                          <PaginationLink
                            last
                            onClick={(e) => {
                              paginate(pagination.totalPages);
                            }}
                          />
                        </PaginationItem>
                      </Pagination>
                      <div className="records-count text-center">
                        <p>
                          Showing <strong>{pagination.startIndex + 1}</strong> to{' '}
                          <strong>{pagination.endIndex + 1}</strong> of <strong>{pagination.totalItems}</strong> records
                        </p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <PopupAddReward show={modalOpen} toggleModal={toggleModal} />
    </React.Fragment>
  );
};

export default RoleList;
