import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Table
} from 'reactstrap';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvField from 'availity-reactstrap-validation/lib/AvField';

import MarkingSection from '../../components/Tasks/MarkingSection';
import { SetStatusStyle, GetQueryParam, ShowNotification } from '../../helpers/general/generalHelpers';
import { user, userRoles } from '../../helpers/user/userInfo';
import BackButton from '../../components/Common/BackButton';
import Loader from '../../shared/loader/loader';
import { updateTask, getTask } from './Services/task-service';
import { getAllUsers } from '../Users/Services/user-service';
import upIcon from '../../assets/images/task/up-arrow.svg';
import downIcon from '../../assets/images/task/down-arrow.svg';


import changeIcon from '../../assets/images/task/ic-change-assignee.svg';

const Task = (props) => {
  const [task, setTask] = useState(null);
  const [currentAssignee, setCurrentAssignee] = useState(null);

  const [showChangeAssigneeModal, setShowChangeAssigneeModal] = useState(false);
  const [newAssignee, setNewAssignee] = useState(null);
  const [assignees, setAssignees] = useState(null);
  const [filteredAssignees, setFilteredAssignees] = useState(null);
  const [showHeader,setShowHeader] = useState(true);
  const [startMarking,setStartMarking] = useState(false);

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    let taskId = GetQueryParam(props.location.search, 'id');
    if (taskId === null) {
      props.history.push('/tasks');
    }

    getTask(taskId).then((response) => {
      let task = response.data.task;
      // console.log(response.data.task);
      setTask(task);
      setCurrentAssignee(task.assignee);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [modalLoader, setModalLoader] = useState(false);

  function calculateTotalAnsweredQuestions(questions) {
    if (questions === null || questions === []) {
      return 0;
    }

    return questions.filter((q) => q.text !== null && q.text !== "" || q.url.length !== 0).length;
  }

  function updateTaskStatus(status) {
    //API call to update task status
    let data = { status: status };
    setLoader(true);
    updateTask(task._id, data)
      .then((response) => {
        let message = `Task Succesfully ${status}`;
        ShowNotification({ type: 'success', message });
        setTask({ ...task, status: status });
      })
      .catch((error) => {
        let message = `Try Again: Unable to ${status.toLowerCase()} task`;
        ShowNotification({ type: 'error', message });
      })
      .finally(() => setLoader(false));
  }

  function openModalAndGetNewAssignees() {
    setShowChangeAssigneeModal(true);
    getAllUsers().then((response) => {
      const users = response.data.users;
      setAssignees(users);
      setFilteredAssignees(users);
    });
  }

  function handleChangeNewAssigneeFeild(value) {
    if (value === '') {
      setAssignees(assignees);
      return;
    }

    value = value.toLowerCase();
    const users = assignees.filter(
      ({ firstName, lastName }) => firstName.toLowerCase().includes(value) || lastName.toLowerCase().includes(value)
    );
    setFilteredAssignees(users);
  }

  function changeAssignee() {
    // update in DB
    // const taskData = {
    //   assignee: {
    //     _id: newAssignee._id,
    //     firstName: newAssignee.firstName,
    //     lastName: newAssignee.lastName,
    //     email: newAssignee.email,
    //     profileImage: newAssignee?.profileImage
    //   }
    // };
    if (currentAssignee) {
    }
    const assingeData = {
      assignee: newAssignee._id,
      status: 'Pending',
      reassigned: currentAssignee ? true : false
    };
    setModalLoader(true);
    updateTask(task._id, assingeData)
      .then((response) => {
        let message = 'Task Assignee Successfully Changed';
        ShowNotification({ type: 'success', message });
      })
      .catch((err) => {
        let message = 'Try Again: Unable to change assignee';
        ShowNotification({ type: 'error', message });
      })
      .finally(() => {
        setCurrentAssignee(newAssignee);
        setModalLoader(false);
        onChangeAssigneeModalClose();
      });
  }

  function onChangeAssigneeModalClose() {
    setShowChangeAssigneeModal(false);
    setNewAssignee(null);
  }

  if (task === null) {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Loader loading={true} />
          </Container>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid className="relative">
            <Loader loading={loader} />
            {/* Render Page title */}
            <BackButton text="Back to Tasks list" url="/tasks" />
            <div style={{flexDirection:'row'}}>
              {startMarking &&
              <div style={{flexDirection:'row',display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end',justifySelf:'flex-end'}} onClick={()=>setShowHeader(!showHeader)}>
              <div className=" font-size-14 back-button" style={{marginRight:10,paddingBottom:5}} >{showHeader ? 'Hide details' : 'Show details'}</div>
              <img className="mx-0" style={{width:15,height:15,marginTop:0,marginBottom:10}} src={showHeader ? upIcon :downIcon} alt="expand-icon" />
              </div>
              }
            {showHeader &&
              <Row>
              <Col lg="9">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="6" className="agora-grey-color">
                        <h4 className=" agora-grey-color font-size-17 text-capitalize mb-3">{task.assessment.title}</h4>
                        <div>Subject: {task.subject.title}</div>
                        <div>Learner Name: {`${task.learner.firstName} ${task.learner.lastName}`}</div>
                        <div>
                          Answered:{' '}
                          {`${calculateTotalAnsweredQuestions(task.marks)} out of ${
                            task?.marks?.length ?? 0
                          } questions`}
                        </div>
                        <div>
                          Created at:{' '}
                          {new Intl.DateTimeFormat('en-GB', {
                            year: 'numeric',
                            month: 'short',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit'
                          }).format(new Date(task.createdAt))}
                        </div>
                        <div>
                          Last updated at:{' '}
                          {new Intl.DateTimeFormat('en-GB', {
                            year: 'numeric',
                            month: 'short',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit'
                          }).format(new Date(task.updatedAt))}
                        </div>
                      </Col>
                      <Col md="6" className="text-right">
                        <div className="bigger-status mb-2">
                          <SetStatusStyle status={task.status} />
                        </div>
                        <div className="agora-grey-color">
                          Due Date:{' '}
                          {new Intl.DateTimeFormat('en-GB', {
                            year: 'numeric',
                            month: 'short',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit'
                            // }).format(new Date(task.dueDate))}
                          }).format(new Date(task.updatedAt))}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="3">
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg="12" className="task-assignee">
                        <div className="task-assignee-heading">
                          <h5>Assignee</h5>
                          {user?.user?.role === userRoles.admin ? (
                            <span
                              onClick={(e) => {
                                openModalAndGetNewAssignees();
                              }}
                            >
                              <img className="change-arrow" src={changeIcon} alt="next-icon" />
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                        <div>
                          <img
                            src={currentAssignee?.profileImage || 'https://avatars.dicebear.com/api/female/random.svg'}
                            alt={currentAssignee?.firstName}
                          />
                          <span>
                            {currentAssignee?.firstName} {currentAssignee?.lastName}
                          </span>
                        </div>
                        <div className="clearfix"></div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            }
            </div>
            {/* Accessor Accept/Decline Task Component */}
            {user.user?.role === userRoles.assessor && task?.status === 'Pending' ? (
              <AssessorDecisionMenu updateTaskStatus={updateTaskStatus} currentStatus={task.status} />
            ) : null}
            {/* Marking Section */}
            {user.user.role.toLowerCase() === userRoles.admin.toLowerCase() || task?.status === 'Accepted' ? (
              <MarkingSection task={task} startMarkingStatus={(_) => setStartMarking(_)} />
            ) : null}
            {/* Change Assignee Modal */}
            <Modal
              isOpen={showChangeAssigneeModal}
              toggle={() => setShowChangeAssigneeModal(!showChangeAssigneeModal)}
              centered
            >
              <Loader loading={modalLoader} />
              <ModalHeader>Select a new assignee</ModalHeader>
              <ModalBody>
                {/* Form Field */}
                <AvForm
                  className="needs-validation"
                  // onValidSubmit={(e,v) => { updateUserInformation(e, v) }}
                >
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <AvField
                          name="assignee-name"
                          type="text"
                          id="assignee-name"
                          placeholder="Search assignee"
                          // validate={{ required: { value: true } }}
                          // errorMessage="Please enter assignee name"
                          // value={newAssigneeField}
                          onChange={(e) => handleChangeNewAssigneeFeild(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <div className="task-search-assignee-list">
                        <Table>
                          <tbody>
                            {assignees === null ? (
                              <Loader loading={true} />
                            ) : (
                              filteredAssignees?.map((a) => (
                                <tr
                                  key={a._id}
                                  onClick={() => setNewAssignee(a)}
                                  className={`${a._id === newAssignee?._id ? 'task-assignee-selected' : ''}`}
                                >
                                  <td className={`${a._id === newAssignee?._id ? 'text-white' : ''}`}>
                                    {a.firstName} {a.lastName}
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </ModalBody>
              <ModalFooter className="text-center">
                <Button
                  onClick={() => {
                    onChangeAssigneeModalClose(false);
                  }}
                  className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    changeAssignee();
                  }}
                  className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
                  disabled={newAssignee === null}
                >
                  Select
                </Button>
              </ModalFooter>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
};

function AssessorDecisionMenu({ updateTaskStatus, currentStatus }) {
  const [showAssessorDecisionModal, setShowAssessorDecisionModal] = useState(false);
  const [updatedStatus, setUpdatedStatus] = useState(null);

  function handleDecision(status) {
    setUpdatedStatus(status);
    setShowAssessorDecisionModal(true);
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <div className="my-5 d-flex flex-column align-items-center">
                <div className="w-25">
                  <Button
                    className="btn btn-primary btn-block agora-button border-radius waves-effect waves-light"
                    onClick={() => {
                      handleDecision('Accepted');
                    }}
                  >
                    Accept Task
                  </Button>
                </div>
                {currentStatus === 'Pending' ? (
                  <div className="w-25 mt-2">
                    <Button
                      className="btn btn-primary btn-block agora-decline-button border-radius waves-effect waves-light"
                      onClick={() => handleDecision('Declined')}
                    >
                      Decline Task
                    </Button>
                  </div>
                ) : null}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={showAssessorDecisionModal} centered>
        <ModalHeader>{updatedStatus === 'Declined' ? 'Decline' : 'Accept'} Task</ModalHeader>
        <ModalBody>Are you sure you want to {updatedStatus === 'Declined' ? 'decline' : 'accept'} this task?</ModalBody>
        <ModalFooter className="text-center">
          <Button
            onClick={() => {
              setShowAssessorDecisionModal(false);
            }}
            className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setShowAssessorDecisionModal(false);
              updateTaskStatus(updatedStatus);
            }}
            className={`btn btn-primary ${
              updatedStatus === 'Declined' ? 'agora-decline-button' : 'agora-button'
            } save-button border-radius waves-effect waves-light`}
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default Task;
