import axios from "axios";
import authHeader from "../../../helpers/jwt-token-access/auth-token-header";
import {
  ShowNotification,
  GetQueryParam,
  GetFilterString,
} from "../../../helpers/general/generalHelpers";
import { appConfig } from "../../../config/config";

function saveUser(values, history, setLoader) {
  const userId = GetQueryParam(history.location.search, "id");
  console.log(userId);
  let type = "post";
  let url = "/user";
  if (userId) {
    type = "put";
    url = "/user/" + userId;
  }

  values.username =
    values.firstName.toLowerCase() + values.lastName.toLowerCase();
  axios[type](url, values, { headers: authHeader() })
    .then((data) => {
      console.log(data.data.user._id);
      values.userId = data.data.user._id;
      if (type === "post") {
        generateReferralCode(values).then(({data}) => postuser(data,history,type))
      }
      else postuser(data,history,type);
      
    })
    .catch((error) => {
      console.log(error.response);
      const message =
        error && error.response && error.response.data
          ? error.response.data.message
          : "There was an error adding user, please try again!";
      ShowNotification({ type: "error", message });
      throw error;
    })
    .finally(() => {
      setLoader(false);
    });
}

function postuser(data,history,type){
  ShowNotification({ type: "success", message: data.message });
  const messageType = type === "post" ? "added" : "updated";
  history.push({
    pathname: "/users",
    notification: {
      show: true,
      message: data.message || `User has been ${messageType} successfully!`,
      title: `User ${messageType} successfully!`,
    },
  });

}
function updateUser(userId, userData) {
  return axios.put(`/user/${userId}`, userData, { headers: authHeader() });
}

function getUsers(pageNumber, filters, pageSize = appConfig.RecordsPerPage) {
  let filterString = GetFilterString(filters);
  return axios.get(
    `/user?pageNumber=${pageNumber}&pageSize=${pageSize}${filterString}`,
    { headers: authHeader() }
  );
}

function getAllUsers() {
  return axios.get(`/user`, {
    headers: authHeader(),
  });
}

function getUser(id) {
  return axios.get("/user/" + id, {
    headers: authHeader(),
  });
}

function generateReferralCode(userData) {
  return axios.post("/referralCoupon/", userData, { headers: authHeader() });
}

function getAllRoles() {
  return axios.get(`/role/?isActive=true`, {
    headers: authHeader(),
  });
}

export {
  saveUser,
  getUsers,
  getUser,
  getAllUsers,
  updateUser,
  generateReferralCode,
  getAllRoles
};
