import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Label, FormGroup } from 'reactstrap';

import PageTitle from '../../components/Common/PageTitle';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import { getChapter, saveChapter } from './Services/chapter-service';
import { Link } from 'react-router-dom';
import Loader from '../../shared/loader/loader';
import { ShowNotification, GetQueryParam } from '../../helpers/general/generalHelpers';
import { getSubjects } from '../Subjects/Services/subject-service';

const AddEditChapter = (props) => {
  const [pageTitle, setPageTitle] = useState('Add Chapter');
  const [toggleSwitch, settoggleSwitch] = useState(true);
  const [textcount, settextcount] = useState(0);
  const [textareabadge, settextareabadge] = useState(0);
  const [loader, setLoader] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [chapter, setChapter] = useState({
    title: '',
    description: '',
    subjectId: '',
    active: true,
    sloIds: [1234, 1235],
    order: 0
  });

  useEffect(() => {
    if (props.location.search) {
      setLoader(true);
      setPageTitle('Edit Chapter');
      const chapterId = GetQueryParam(props.location.search, 'id');
      getChapter(chapterId)
        .then((response) => {
          settextcount(chapter.description.length);
          settextareabadge(chapter.description ? true : false);
          settoggleSwitch(chapter.active);
          setChapter(response.data.chapter);
        })
        .catch((error) => {
          const message =
            error && error.response && error.response.data
              ? error.response.data.message
              : 'There was an error fetching chapter information, please try again!';
          ShowNotification({ type: 'error', message });
          throw error;
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [props.location.search, chapter.description, chapter.active]);

  useEffect(() => {
    getSubjects()
      .then((response) => {
        setSubjects(response.data.subjects);
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  function updateChapter(event, values) {
    setLoader(true);
    values.sloIds = chapter.sloIds;
    values.active = toggleSwitch;
    values.order = Number(values.order);
    saveChapter(values, props.history, setLoader);
  }

  function textareachange(event) {
    var count = event.target.value.length;
    if (count > 0) {
      settextareabadge(true);
    } else {
      settextareabadge(false);
    }
    settextcount(event.target.value.length);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="relative">
          <Loader loading={loader} />
          {/* Render Page title */}
          <PageTitle title={pageTitle} />

          <AvForm
            className="needs-validation"
            onValidSubmit={(e, v) => {
              updateChapter(e, v);
            }}
          >
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title">Basic Information</h4>
                    <p className="card-title-desc">Fill all information below</p>
                    <Row className="agora-lato">
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="order">Order</Label>
                          <AvField
                            name="order"
                            placeholder="Enter order"
                            type="number"
                            errorMessage="Please enter order number"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="order"
                            value={chapter.order}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="agora-lato">
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="title">Chapter Name</Label>
                          <AvField
                            name="title"
                            placeholder="Enter chapter name"
                            type="text"
                            errorMessage="Please enter chapter name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="title"
                            value={chapter.title}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="subjectId">Subject</Label>
                          <AvField
                            type="select"
                            name="subjectId"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            errorMessage="Please select a subject"
                            value={chapter.subjectId ? chapter.subjectId._id : ''}
                          >
                            <option value="">Please select a subject</option>
                            {subjects.map((subject, index) => (
                              <option key={'_subject_' + index} value={subject._id}>
                                {subject.title}
                              </option>
                            ))}
                          </AvField>
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="description">Description</Label>
                          {textareabadge ? (
                            <span className="ml-3 badgecount badge badge-success"> {textcount} / 180 </span>
                          ) : null}
                          <AvField
                            name="description"
                            type="textarea"
                            id="textarea"
                            onChange={(e) => {
                              textareachange(e);
                            }}
                            maxLength="180"
                            rows="3"
                            validate={{ required: { value: true } }}
                            placeholder="Enter chapter description"
                            errorMessage="Please enter chapter description"
                            value={chapter.description}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <Label htmlFor="active">Status</Label>
                        <div className="custom-control status-switch custom-switch mb-2" dir="ltr">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="active"
                            defaultChecked={toggleSwitch}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="active"
                            onClick={(e) => {
                              settoggleSwitch(!toggleSwitch);
                            }}
                          >
                            {toggleSwitch ? 'Active' : 'Inactive'}
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg="12" className="agora-form-buttons text-right">
                <Link
                  to="/chapters"
                  className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light"
                >
                  Cancel
                </Link>
                <Button
                  type="submit"
                  className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </AvForm>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddEditChapter;
