import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
  Label,
  Button,
} from 'reactstrap';

import PageTitle from '../../components/Common/PageTitle';
import { ShowNotification, ObjectHasValues } from '../../helpers/general/generalHelpers';
import { getPaginationInformation, initialPaginationObject } from '../../helpers/general/pagination';
import { getQuestions } from './Api/questions';
import Loader from '../../shared/loader/loader';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import PopupAddEditQuestions from './PopupAddEditQuestions';
import { getSubjects } from '../Subjects/Services/subject-service';

const Questions = (props) => {
  const [pagination, setPagination] = useState(initialPaginationObject);
  const [currentPage, setCurrentPage] = useState(1);
  const filterForm = useRef(null);
  const [filters, setFilters] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [subjects, setSubjects] = useState([]);

  if (props.location.notification && props.location.notification.show) {
    const notification = props.location.notification;
    ShowNotification({
      type: 'success',
      message: notification.message
    });
  }

  useMemo(() => {
    getSubjects(undefined, undefined, 100)
      .then((response) => {
        const subjList = response.data.subjects.map((subj) => {
          return { ...subj, id: subj._id, value: subj.title, label: subj.title };
        });
        setSubjects(subjList);
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  useEffect(() => {
    setLoader(true);
    getQuestions(currentPage, filters)
      .then((response) => {
        const questions = response.data.questions;
        setQuestions(questions);
        setPagination(getPaginationInformation(response.data.Count, currentPage));
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
  }, [currentPage, filters]);

  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
  }

  function handleFilters(event, values) {
    if (ObjectHasValues(values)) {
      setFilters(values);
    }
  }

  function clearFilters() {
    filterForm.current.reset();
    setFilters(undefined);
  }

  function toggleModal() {
    setModalOpen(!modalOpen);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  return (
    <div className="page-content">
      <Container fluid className="relative">
        <Loader loading={loader} />

        <PageTitle title="Questions">
          <div className="page-title-right">
            <Button
              onClick={(e) => {
                setModalOpen(true);
              }}
              className="btn btn-primary btn-block agora-button border-radius waves-effect waves-light"
            >
              + Add New Question
            </Button>
          </div>
        </PageTitle>

        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <AvForm
                  ref={filterForm}
                  onValidSubmit={(e, v) => {
                    handleFilters(e, v);
                  }}
                >
                  <Row className="mb-3">
                    <Col md="2" sm="4">
                      <FormGroup>
                        <Label>Question ID :</Label>
                        <AvField
                          name="internalId"
                          placeholder="Search"
                          className="form-control filter-field"
                          // onChange={handleFilters}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2" sm="4">
                      <FormGroup>
                        <Label htmlFor="status-filter">Type</Label>
                        <AvField type="select" name="type" className="form-control filter-field">
                          <option value="">Select Status</option>
                          <option value="Structured">Structured</option>
                          <option value="MCQ">MCQ</option>
                        </AvField>
                      </FormGroup>
                    </Col>
                    <Col md="2" sm="4">
                      <FormGroup>
                        <Label>Paper Number:</Label>
                        <AvField
                          name="paperNumber"
                          placeholder="Search"
                          className="form-control filter-field"
                          // onChange={handleFilters}
                          id="paperNumber-filter"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2" sm="4">
                      <FormGroup>
                        <Label>Subject</Label>
                        <AvField type="select" name="subject" className="form-control filter-field">
                          <option value="">Select Subject</option>
                          {subjects.map((subj, idx) => {
                            return (
                              <option key={idx} value={subj._id}>
                                {subj.title + ' - ' + subj.studyLevelId}
                              </option>
                            );
                          })}
                        </AvField>
                      </FormGroup>
                    </Col>
                    <Col md="2" sm="4">
                      <FormGroup>
                        <Label>Year:</Label>
                        <AvField
                          name="year"
                          placeholder="Search"
                          type="text"
                          className="form-control filter-field"
                          // onChange={handleFilters}
                          id="paperNumber-filter"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2" sm="4">
                      <FormGroup>
                        <Label>Sample Answer:</Label>
                        <AvField
                          type="select"
                          name="sampleAnswerAvailable"
                          id="type-filter"
                          className="form-control filter-field"
                        >
                          <option value="">Select Status</option>
                          <option value="true">Available</option>
                          <option value="false">Not Available</option>
                        </AvField>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <Button
                        type="submit"
                        className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
                      >
                        Filter
                      </Button>
                      <Button
                        type="button"
                        onClick={clearFilters}
                        className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light ml-2"
                      >
                        Clear
                      </Button>
                    </Col>
                  </Row>
                </AvForm>
                <div className="table-responsive">
                  <Table className="table-centered table-nowrap table-hover">
                    <thead className="thead-light font-size-15">
                      <tr>
                        <th scope="col">Questions ID</th>
                        <th scope="col">Type</th>
                        <th scope="col">Paper Number</th>
                        <th scope="col">Subject</th>
                        <th scope="col">Year</th>
                        <th scope="col">Sample Answer</th>
                        <th scope="col" className="text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="agora-lato">
                      {questions.map((question, idx) => (
                        <tr key={question._id}>
                          <td>
                            <span>
                              <Link
                                style={{ color: '#495057' }}
                                to={{
                                  pathname:
                                    question.type.toLowerCase() === 'mcq' ? '/mcq-question' : '/structured-question',
                                  search: '?id=' + question._id
                                }}
                                target="_blank"

                                id={'question' + question._id}
                              >
                                {question.internalId}
                                <UncontrolledTooltip placement="top" target={'question' + question._id}>
                                  View
                                </UncontrolledTooltip>
                              </Link>
                            </span>
                          </td>
                          <td>
                            <span>{question.type || ''}</span>
                          </td>
                          <td>
                            <span>{question.paperNumber || ''}</span>
                          </td>
                          <td>
                            <span>
                              {question.subject ? question.subject.title + ' - ' + question.studyLevelId : ''}
                            </span>
                          </td>
                          <td>
                            <span>{question.year || ''}</span>
                          </td>
                          <td>
                            <span>{question.sampleAnswerAvailable ? 'Available' : 'Not Available'}</span>
                          </td>
                          <td className="text-center">
                            <ul className="list-inline font-size-20 contact-links mb-0">
                              <li className="list-inline-item px-2">
                                <Link
                                  to={{
                                    pathname:
                                      question.type.toLowerCase() === 'mcq' ? '/mcq-question' : '/structured-question',
                                    search: '?id=' + question._id
                                  }}
                                  id={'question' + question._id}
                                target="_blank"

                                >
                                  <i className="bx bx-show-alt"></i>
                                  <UncontrolledTooltip placement="top" target={'question' + question._id}>
                                    View
                                  </UncontrolledTooltip>
                                </Link>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <Row>
                  <Col lg="12">
                    <Pagination className="pagination pagination-rounded justify-content-center mt-4">
                      <PaginationItem disabled={pagination.currentPage === 1}>
                        <PaginationLink
                          first
                          onClick={(e) => {
                            paginate(1);
                          }}
                        />
                      </PaginationItem>
                      <PaginationItem disabled={pagination.currentPage === 1}>
                        <PaginationLink
                          previous
                          onClick={(e) => {
                            paginate(pagination.currentPage - 1);
                          }}
                        />
                      </PaginationItem>
                      {pagination.pages.map((page, index) => (
                        <PaginationItem key={'_page_' + index} active={page === pagination.currentPage}>
                          <PaginationLink
                            onClick={(e) => {
                              paginate(page);
                            }}
                          >
                            {page}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem disabled={pagination.currentPage === pagination.totalPages}>
                        <PaginationLink
                          next
                          onClick={(e) => {
                            paginate(pagination.currentPage + 1);
                          }}
                        />
                      </PaginationItem>
                      <PaginationItem disabled={pagination.currentPage === pagination.totalPages}>
                        <PaginationLink
                          last
                          onClick={(e) => {
                            paginate(pagination.totalPages);
                          }}
                        />
                      </PaginationItem>
                    </Pagination>
                    <div className="records-count text-center">
                      <p>
                        Showing <strong>{pagination.startIndex + 1}</strong> to{' '}
                        <strong>{pagination.endIndex + 1}</strong> of <strong>{pagination.totalItems}</strong> records
                      </p>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <PopupAddEditQuestions show={modalOpen} toggleModal={toggleModal} />
    </div>
  );
};

export default Questions;
