import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Label, FormGroup } from 'reactstrap';

import PageTitle from '../../components/Common/PageTitle';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import { saveRole, getRole } from './Services/role-service';
import { Link } from 'react-router-dom';
import Loader from '../../shared/loader/loader';
import { ShowNotification, GetQueryParam } from '../../helpers/general/generalHelpers';

const AddEditRole = (props) => {
  const [toggleSwitch, settoggleSwitch] = useState(true);
  const [loader, setLoader] = useState(false);
  const [pageTitle, setPageTitle] = useState('Add Role');
  const [role, setRole] = useState({
    roleName: '',
    canSubscribe: true,
    isActive: true,
  });

  useEffect(() => {
    if (props.location.search) {
      setLoader(true);
      setPageTitle('Edit Role');
      const roleId = GetQueryParam(props.location.search, 'id');
      getRole(roleId)
        .then((response) => {
          setRole(response.data.role);
          settoggleSwitch(response.data.role.isActive);// && response.data.role.isActive === true);
        })
        .catch((error) => {
          const message =
            error && error.response && error.response.data
              ? error.response.data.message
              : 'There was an error fetching role information, please try again!';
          ShowNotification({ type: 'error', message });
          throw error;
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [props.location.search]);

  function updateRole(event, values) {
    setLoader(true);
    values.isActive = toggleSwitch;
    saveRole(values, props.history, setLoader);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="relative">
          <Loader loading={loader} />
          {/* Render Page title */}
          <PageTitle title={pageTitle} />

          <AvForm
            className="needs-validation"
            onValidSubmit={(e, v) => {
              updateRole(e, v);
            }}
          >
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title">Basic Information</h4>
                    <p className="card-title-desc">Fill all information below</p>
                    <Row className="agora-lato">
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="roleName">Role Name</Label>
                          <AvField
                            name="roleName"
                            placeholder="Enter Role Name"
                            type="text"
                            errorMessage="Please enter role name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="roleName"
                            value={role.roleName}
                          />
                        </FormGroup>
                      </Col>
                      
                      <Col md="6">
                        <Label htmlFor="roleStatus">Status</Label>
                        <div className="custom-control status-switch custom-switch mb-2" dir="ltr">
                          <input type="checkbox" className="custom-control-input" id="roleStatus" checked={toggleSwitch} />
                          <label
                            className="custom-control-label"
                            htmlFor="roleStatus"
                            onClick={(e) => {
                              settoggleSwitch(!toggleSwitch);
                            }}
                          >
                            {toggleSwitch ? 'Active' : 'Inactive'}
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            

            <Row>
              <Col lg="12" className="agora-form-buttons text-right">
                <Link
                  to="/users"
                  className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light"
                >
                  Cancel
                </Link>
                <Button
                  type="submit"
                  className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </AvForm>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddEditRole;
