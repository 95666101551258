import axios from 'axios';
import authHeader from '../../../helpers/jwt-token-access/auth-token-header';
import { ShowNotification, GetFilterString } from '../../../helpers/general/generalHelpers';
import { appConfig } from '../../../config/config';

function saveSyllabus(syllabusId, values, history, setLoader) {
  let type = 'post';
  let url = '/syllabus';
  if (syllabusId) {
    type = 'put';
    url = '/syllabus/' + syllabusId;
  }
  return new Promise((resolve, reject) => {
    axios[type](url, values, { headers: authHeader() })
      .then((data) => {
        const messageType = type === 'post' ? 'added' : 'updated';
        ShowNotification({
          type: 'success',
          title: `Syllabus ${messageType} successfully!`,
          message: data.message || `Syllabus has been ${messageType} successfully!`
        });
        resolve(data);
      })
      .catch((error) => {
        const message =
          error && error.response && error.response.data
            ? error.response.data.message
            : 'There was an error updating syllabus, please try again!';
        ShowNotification({ type: 'error', message });
        reject(error);
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
  });
}

function getSyllabus(id) {
  return axios.get('/syllabus/' + id, { headers: authHeader() });
}

function getSyllabuses(pageNumber, filters, pageSize = appConfig.RecordsPerPage) {
  let filterString = GetFilterString(filters);
  return axios.get(`/syllabus?pageNumber=${pageNumber}&pageSize=${pageSize}${filterString}`, { headers: authHeader() });
}

function deleteSyllabus(id) {
  return axios.delete('/syllabus/' + id, { headers: authHeader() });
}

export { saveSyllabus, getSyllabus, getSyllabuses, deleteSyllabus };
