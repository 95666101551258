import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";

import AvField from "availity-reactstrap-validation/lib/AvField";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import PageTitle from "../../components/Common/PageTitle";
import {
  ObjectHasValues,
  SetStatusStyle,
  ShowNotification,
} from "../../helpers/general/generalHelpers";
import {
  getPaginationInformation,
  initialPaginationObject,
} from "../../helpers/general/pagination";
import Loader from "../../shared/loader/loader";
import { deleteCoupon, getCoupons } from "./services/coupon-service";

const CouponsList = (props) => {
  const [isTargetElementVisible, setIsTargetElementVisible] = useState(true);
  const [pagination, setPagination] = useState(initialPaginationObject);
  const [currentPage, setCurrentPage] = useState(1);
  const filterForm = useRef(null);
  const [filters, setFilters] = useState(null);
  const [coupons, setCoupons] = useState([]);
  const [loader, setLoader] = useState(true);
  const [isDelete, setIsDelete] = useState(false);

  if (props.location.notification && props.location.notification.show) {
    const notification = props.location.notification;
    ShowNotification({
      type: "success",
      message: notification.message,
    });
    props.location.notification = null;
  }

  useEffect(() => {
    setLoader(true);
    getCoupons(currentPage, filters)
      .then((response) => {
        const coupons = response.data.coupons;
        setCoupons(JSON.parse(coupons));
        setPagination(
          getPaginationInformation(response.data.Count, currentPage)
        );
        setIsTargetElementVisible(true);
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
  }, [currentPage, filters, isDelete, isTargetElementVisible]);

  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
  }

  function handleFilters(event, values) {
    if (ObjectHasValues(values)) {
      setFilters(values);
    }
  }

  function clearFilters() {
    filterForm.current.reset();
    setFilters(null);
  }
  async function deleteHandler(id) {
    if (window.confirm("Are you sure! you want to delete")) {
      try {
        deleteCoupon(id);
        setIsDelete(!isDelete);
      } catch (error) {
        console.log(error);
      }
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="relative">
          <Loader loading={loader} />

          {/* Render Page title */}
          <PageTitle
            title="Coupons"
            button
            name="+ Add New Coupon"
            url="/coupon"
          />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={filterForm}
                    onValidSubmit={(e, v) => {
                      handleFilters(e, v);
                    }}
                  >
                    <Row>
                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label htmlFor="couponCode">Coupon Code</Label>
                          <AvField
                            name="code"
                            placeholder="Please Enter a Code"
                            type="text"
                            className="form-control filter-field"
                            id="code-filter"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label htmlFor="status-filter">Status</Label>
                          <AvField
                            type="select"
                            name="status"
                            id="status-filter"
                            className="form-control filter-field"
                          >
                            <option value="">Select Status</option>
                            <option value="all">All</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </AvField>
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label htmlFor="validFrom">Valid From</Label>
                          <AvField
                            type="date"
                            name="validFrom"
                            className="form-control filter-field"
                            placeholder="Enter Start Date"
                            id="validFrom-filter"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label htmlFor="validTill">Valid Till</Label>
                          <AvField
                            type="date"
                            name="validTill"
                            className="form-control filter-field"
                            placeholder="Enter End Date"
                            id="validTill-filter"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label htmlFor="discount">Discount %</Label>
                          <AvField
                            id="discount-filter"
                            name="discount"
                            type="number"
                            placeholder="Please Enter a number"
                            validate={{
                              pattern: {
                                value: /^\d+(\.\d{1,3})?$/,
                                errorMessage:
                                  "Allowd number with upto 3 decimal places.",
                              },
                              min: {
                                value: 0,
                                errorMessage:
                                  "Please provide a valid positive number.",
                              },
                              max: {
                                value: 100,
                                errorMessage:
                                  "Please provide a valid Discount.",
                              },
                            }}
                            className="form-control filter-field"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-12 col-md-auto agora-filter-buttons">
                        <Button
                          type="submit"
                          className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
                        >
                          Filter
                        </Button>
                        <Button
                          type="button"
                          onClick={clearFilters}
                          className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light ml-2"
                        >
                          Clear
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                  <div className="table-responsive">
                    <Table className="table-centered table-nowrap table-hover">
                      <thead className="thead-light font-size-15 ">
                        <tr>
                          <th scope="col">Code</th>
                          <th scope="col">Description</th>
                          <th scope="col">Discount %</th>
                          <th scope="col">Status</th>
                          <th scope="col">Start Date</th>
                          <th scope="col">End Date</th>
                          <th scope="col">Last Updated</th>
                          <th scope="col" className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>

                      <tbody className="agora-lato">
                        {coupons.map((coupon, i) => (
                          <tr key={"_coupon_" + i}>
                            {/* <th scope="col" ></th> */}
                            <td>{coupon.Code}</td>
                            <td>{coupon.joinedData[0].Description}</td>
                            <td>{coupon.joinedData[0].Discount}%</td>
                            <td>
                              <div>
                                <SetStatusStyle
                                  status={
                                    coupon.IsActive ? "Active" : "Inactive"
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              {new Intl.DateTimeFormat("en-GB", {
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                              }).format(
                                new Date(coupon.joinedData[0].ValidFrom)
                              )}
                            </td>
                            <td>
                              {new Intl.DateTimeFormat("en-GB", {
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                              }).format(
                                new Date(coupon.joinedData[0].ExpireDate)
                              )}
                            </td>
                            <td>
                              {new Intl.DateTimeFormat("en-GB", {
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              }).format(
                                new Date(coupon.joinedData[0].updatedAt)
                              )}
                            </td>
                            <td className="text-center">
                              <ul className="list-inline font-size-20 contact-links mb-0">
                                <li className="list-inline-item px-2">
                                  <Link
                                    to={{
                                      pathname: "/coupon",
                                      search: "?id=" + coupon._id,
                                    }}
                                    id={"edit-coupon" + coupon._id}
                                  >
                                    <i className="bx bxs-edit-alt"></i>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={"edit-coupon" + coupon._id}
                                    >
                                      Edit
                                    </UncontrolledTooltip>
                                  </Link>
                                </li>
                                <li className="list-inline-item px-2">
                                  <Link
                                    to={{
                                      pathname: "/coupons",
                                    }}
                                    id={"delete-coupon" + coupon._id}
                                    onClick={() => {
                                      deleteHandler(coupon._id);

                                      setIsTargetElementVisible(
                                        !isTargetElementVisible
                                      );
                                    }}
                                  >
                                    <i className="bx bxs-trash"></i>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={"delete-coupon" + coupon._id}
                                    >
                                      Delete
                                    </UncontrolledTooltip>
                                  </Link>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <Row>
                    <Col lg="12">
                      <Pagination className="pagination pagination-rounded justify-content-center mt-4">
                        <PaginationItem disabled={pagination.currentPage === 1}>
                          <PaginationLink
                            first
                            onClick={(e) => {
                              paginate(1);
                            }}
                          />
                        </PaginationItem>
                        <PaginationItem disabled={pagination.currentPage === 1}>
                          <PaginationLink
                            previous
                            onClick={(e) => {
                              paginate(pagination.currentPage - 1);
                            }}
                          />
                        </PaginationItem>
                        {pagination.pages.map((page, index) => (
                          <PaginationItem
                            key={"_page_" + index}
                            active={page === pagination.currentPage}
                          >
                            <PaginationLink
                              onClick={(e) => {
                                paginate(page);
                              }}
                            >
                              {page}
                            </PaginationLink>
                          </PaginationItem>
                        ))}
                        <PaginationItem
                          disabled={
                            pagination.currentPage === pagination.totalPages
                          }
                        >
                          <PaginationLink
                            next
                            onClick={(e) => {
                              paginate(pagination.currentPage + 1);
                            }}
                          />
                        </PaginationItem>
                        <PaginationItem
                          disabled={
                            pagination.currentPage === pagination.totalPages
                          }
                        >
                          <PaginationLink
                            last
                            onClick={(e) => {
                              paginate(pagination.totalPages);
                            }}
                          />
                        </PaginationItem>
                      </Pagination>
                      <div className="records-count text-center">
                        <p>
                          Showing <strong>{pagination.startIndex + 1}</strong>{" "}
                          to <strong>{pagination.endIndex + 1}</strong> of{" "}
                          <strong>{pagination.totalItems}</strong> records
                        </p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CouponsList;
