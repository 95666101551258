import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import parse from 'html-react-parser';
import { useHistory } from 'react-router-dom';
//import { Editor } from '@tinymce/tinymce-react';
import { EditorState,ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import draftToHtml from '../../components/draftjs-to-html/index';
import ReactDevicePreview from 'react-device-preview';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import Lightbox from 'react-awesome-lightbox';

import MobileScreen from './MobileScreen';
import SubQuestion from './SubQuestion';
import PopupAddEditQuestions from './PopupAddEditQuestions';
import PopupMarking from './PopupMarking';
import PopupSampleAnswer from './PopupSampleAnswer';
import { getQuestion, saveQuestion } from './Api/questions';
import Loader from '../../shared/loader/loader';
//import { appConfig } from '../../config/config';
import { TinyImageUpload, UploadMarkingImage } from '../../helpers/general/services/image-service';
import { GetQueryParam, ShowNotification } from '../../helpers/general/generalHelpers';
import { Buffer } from "buffer";
import MarkingModal from './markingModal';
import { forwardRef } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from "html-to-draftjs";
import { convertToHtmlEntity, preImageUpload, rteColors, rteFontFamily, setImageAttribute, uploadAndGetContent } from '../../components/Utils';
import SpecialCharPicker from '../../components/SpecialCharPicker';

const AddEditStructuredQuestion = (props) => {
    const dropzoneElement = useRef(null);
    Buffer.from("anything", "base64");
    window.Buffer = window.Buffer || require("buffer").Buffer;
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [question, setQuestion] = useState({
        loaded: false,
        type: '',
        subject: {},
        chapter: {},
        difficulty: ''
    });

    const [sampleAnswer, setSampleAnswer] = useState('<p></p>');
    const [marking, setMarking] = useState('<p></p>');
    const [subQuestions, setSubQuestions] = useState({
        a: {
            // id: uuid(),
            body: '',
            marks: 0,
            sampleAnswer: '',
            markingAttachment: '',
            answer: false,
            subsubQuestions: {}
        }
    });
    const [subSubQuestions, setSubSubQuestions] = useState({ a: { body: '' } });
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMarkingOpen, setModalMarkingOpen] = useState(false);
    const [loader, setLoader] = useState(true);
    const [sampleAnswerModalGetSet, setsampleAnswerModalGetSet] = useState({
        get: () => {
            return sampleAnswer;
        },
        set: setSampleAnswer
    });
    const [markingModalGetSet, setMarkingModalGetSet] = useState({
        get: () => {
            return marking;
        },
        set: setMarking
    });
    const [questionModalOpen, setQuestionModalOpen] = useState(false);
    const [isMarkingSchemeOpen, setIsMarkingSchemeOpen] = useState(false);

    const [file, setFile] = useState(null);
    const [selectedQuestionType, setSelectedQuestionType] = useState(null);
    const [uploadedMarkingScheme,setUploadedMarkingScheme] = useState(null)

    const [, update] = useState(0);
    const forceUpdate = () => {
        update((n) => !n);
    };

    const history = useHistory();

    useEffect(() => {
        if (props.location.state) {
            setQuestion({ loaded: true, ...props.location.state });
            setLoader(false);
        } else if (props.location.search) {
            const questionId = GetQueryParam(props.location.search, 'id');

            getQuestion(questionId)
                .then((response) => {
                    console.log('response', response);
                    const question = response.data.question;
                    // previous code
                    // let explanation = question.Feedback?.body.split('Explanation: ')
                    // new code
                     let explanation = question.questions.sampleAnswer
                    //let explanation = question.Feedback?.body.split('Explanation: ')
                    setSampleAnswer(explanation ? explanation[1] : 'No explanation available');
                    // setSampleAnswer(question.questions.sampleAnswer);

                    const urlRegex = /(https?:\/\/[^ ]*)/;
                    let markingScheme = '';
                    if (question.questions) {
                        if (question?.questions?.markingAttachment) {
                        } else if (question?.questions?.subquestions) {
                            let [key] = Object.keys(question?.questions?.subquestions);
                            if (question?.questions?.subquestions?.[key]?.subsubquestions) {
                                let [subKey] = Object.keys(
                                    question.questions.subquestions[key].subsubquestions,
                                );
                                markingScheme = question?.questions?.subquestions?.[key]?.subsubquestions[
                                    subKey
                                ].markingAttachment?.match(urlRegex);
                            } else {
                                markingScheme = question.questions.subquestions[
                                    key
                                ].markingAttachment?.match(urlRegex);
                            }
                        }
                    }
                    setMarking(markingScheme ? markingScheme[0]?.slice(0, -1) : '');
                    // setMarking(question.questions?.markingAttachment || '');
                    console.log('question.questions', question.questions.body);
                    setSubQuestions(question.questions.subquestions);
                    // debugger
                    console.log("question.questions.subquestions",question.questions.subquestions)
                    Object.keys(question.questions.subquestions)?.map((id, value) => {
                        if (question.questions?.subquestions[id]?.subsubQuestions !== null && question.questions?.subquestions[id]?.subsubQuestions !== undefined && question.questions?.subquestions[id]?.subsubQuestions !== '') {
                            
                            setSubSubQuestions(question.questions?.subquestions[id]?.subsubQuestions)
                        }
                    })
                    
                    // if(question?.questions && question?.questions?.subquestions != undefined && question?.questions?.subquestions != null){
                    //     Object.keys(question.questions.subquestions)?.map((id, value) => {
                    //         if (question.questions?.subquestions[id]?.subsubQuestions !== null && question.questions?.subquestions[id]?.subsubQuestions !== undefined && question.questions?.subquestions[id]?.subsubquestions !== '') {

                    //             setSubSubQuestions(question.questions?.subquestions[id]?.subsubquestions)
                    //         }
                    //     })
                    setQuestion({
                        loaded: true,
                        ...question,
                        type: question.type,
                        subject: question.subject,
                        chapter: question.chapter,
                        // difficulty: { value: question.difficultyLevel, label: question.difficultyLevel },
                        difficulty: question.difficultyLevel,
                        body: question.questions.body
                    });
                    
                //}
                setEditorState(question.questions.body !==undefined? EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                        htmlToDraft(question.questions.body, setImageAttribute)
                    )
                  ):EditorState.createEmpty())
                    forceUpdate();
                })
                .catch((error) => {
                    const message =
                        error && error.response && error.response.data
                            ? error.response.data.message
                            : 'There was an error fetching subject information, please try again!';
                    ShowNotification({ type: 'error', message });
                    throw error;
                })
                .finally(() => {
                    setLoader(false);
                });
        } else {
            history.goBack();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleQuestionEditorChange = (content, editor) => {
        setQuestion({ ...question, body: content });
    };

    const addSubQuestion = (event) => {
        if (subQuestions?.length < 5) {
            setSubQuestions([
                ...subQuestions,
                {
                    id: uuid(),
                    body: '',
                    marks: 1,
                    answer: false,
                    sampleAnswer: '',
                    subQuestions: []
                }
            ]);

        } else {
            ShowNotification({ type: 'warning', message: 'Maximum 5 Subquestions are allowed.' });
        }
    };

    function toggleModal() {
        setModalOpen(!modalOpen);
    }

    function toggleMarkingModal() {
        setModalMarkingOpen(!modalMarkingOpen);
    }

    function toggleEditQuestionModal() {
        setQuestionModalOpen(!questionModalOpen);
    }

    const handleSaveQuestion = async () => {
        setLoader(true);
        const htmlConverted = await uploadAndGetContent(editorState)
        let newQuestion = {
            _id: question._id,
            type: question.type,
            studyLevelId: question.subject.studyLevelId || question.studyLevelId,
            subject: question.subject.id || question.subject._id,
            active: true,
            year: parseInt(question.year), //number
            session: question.session,
            paperNumber: question.paperNumber.label || question.paperNumber,
            versionNumber: question.versionNumber,
            questionNumber: question.questionNumber,
            markingSchemeId: question.markingSchemeId,
            questions: {
                //body: question.body ? question.body : '',
                // sampleAnswer: sampleAnswer,
                body: htmlConverted,
                sampleAnswer: sampleAnswer,
                markingAttachment: marking === '<p></p>' ? '' : marking,
                subquestions: subQuestions
            },
            Feedback: {
                "body": `<b>Explanation: </b> ${sampleAnswer}`
            },
            markingAttachment :uploadedMarkingScheme,
            markingAttachmentType: selectedQuestionType?.value,
            subjectName: question.subject.label || question.subject.title,
            // chapter: question.chapter.id || question.chapter._id,
            sampleAnswerAvailable: sampleAnswer !== '',
            difficultyLevel: question.difficulty,
            internalId: question.internalId
        };
        // debugger;
        saveQuestion(newQuestion, props.history, setLoader);
    };

    function inputSampleAnswer(idx, subIdx) {
        if (idx !== undefined && subIdx !== undefined) {
            setsampleAnswerModalGetSet({
                get: () => {
                    return subQuestions?.[idx]?.subQuestions?.[subIdx]?.sampleAnswer;
                },
                set: (content) => {
                    let newSubQuestions = subQuestions;
                    newSubQuestions[idx].subQuestions[subIdx].sampleAnswer = content;
                    setSubQuestions(newSubQuestions);
                    forceUpdate();
                }
            });
            toggleModal();
        } else if (idx !== undefined) {
            setsampleAnswerModalGetSet({
                get: () => {
                    return subQuestions[idx].sampleAnswer;
                },
                set: (content) => {
                    let newSubQuestions = subQuestions;
                    newSubQuestions[idx].sampleAnswer = content;
                    setSubQuestions(newSubQuestions);
                    forceUpdate();
                }
            });
            toggleModal();
            handleSaveQuestion();
        }
    }

    function inputMarking(idx, subIdx) {
        if (idx !== undefined && subIdx !== undefined) {
            setMarkingModalGetSet({
                get: () => {
                    return subQuestions?.[idx]?.subQuestions?.[subIdx]?.markingAttachment;
                },
                set: (content) => {
                    let newSubQuestions = subQuestions;
                    newSubQuestions[idx].subQuestions[subIdx].markingAttachment = content;
                    setSubQuestions(newSubQuestions);
                    forceUpdate();
                }
            });
            toggleMarkingModal();
        } else if (idx !== undefined) {
            setMarkingModalGetSet({
                get: () => {
                    return subQuestions[idx].markingAttachment;
                },
                set: (content) => {
                    let newSubQuestions = subQuestions;
                    newSubQuestions[idx].markingAttachment = content;
                    setSubQuestions(newSubQuestions);
                    forceUpdate();
                }
            });
            toggleMarkingModal();
        }
    }

    function handleAcceptedFiles(files) {
        if (files.length) {
          files.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          );
          const file = files[0];
          setFile(file);
        }
      }

      const uploadImage =()=>{
        if(file.length === 0){
        alert('Please select a file')
        }else{
            UploadMarkingImage(file, 'markingAttachment')
            .then((result) => {
                setUploadedMarkingScheme(result.location)
                setIsMarkingSchemeOpen(false)
                console.log("URL", result.location)
                let message = 'Image upload successfully'
                ShowNotification({ type: 'success', message });
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              ShowNotification({ type: 'success', message:'Image uploading failed' });
              setFile(null);
            });
        }
      }
    return (
        <React.Fragment>
            <Loader loading={loader} />
            <div className="page-content">
                <Container fluid className="relative">
                    <Row>
                        <Col xs="12">
                            <div
                                onClick={() => {
                                    window.history.back();
                                }}
                                className="page-title-box d-flex align-items-center justify-content-between"
                            >
                                <span className="mb-0 font-size-14 back-button">
                                    <i className="fa fa-arrow-left"></i> Back to Questions
                                </span>
                            </div>
                        </Col>
                    </Row>

                    <Card>
                        <CardBody>
                            <Row className="subject-info-section">
                                <Col md="3" className="justify-content-center">
                                    <h4 className="card-title mb-3">{question.chapter?.label || question.chapter?.title}</h4>
                                </Col>
                                <Col className="justify-content-left">
                                    <span
                                        className={
                                            'badge badge-pill badge-soft-success font-size-12 px-3 mr-1 ' +
                                            (question.type.toLowerCase() === 'mcq' ? 'text-uppercase' : 'text-capitalize')
                                        }
                                    >
                                        {question.type}
                                    </span>
                                    <span
                                        className={clsx({
                                            'badge badge-pill font-size-12 text-capitalize px-3': true,
                                            'badge-soft-success': question.difficulty === 'Easy',
                                            'badge-soft-info': question.difficulty === 'Medium',
                                            'badge-soft-warning': question.difficulty === 'Hard'
                                        })}
                                    >
                                        {question.difficulty}
                                    </span>
                                </Col>
                                <Col className="col text-md-right text-sm-right">
                                    <i onClick={toggleEditQuestionModal} className="bx bxs-edit-alt font-size-16 btn p-0"></i>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="2" className="subject-creator-info-section font-size-13">
                                    <div className="subject-creator-info">
                                        <p>Study level: {question?.studyLevelId || '-'}</p>
                                        <p>Year: {question.year || '-'}</p>
                                    </div>
                                </Col>
                                <Col md="2" className="subject-creator-info-section font-size-13">
                                    <div className="subject-creator-info">
                                        <p>Subject: {question.subject?.title || '-'}</p>
                                        <p>Session: {question.session || '-'}</p>
                                    </div>
                                </Col>
                                <Col md="2" className="subject-creator-info-section font-size-13">
                                    <div className="subject-creator-info">
                                        <p>Paper Number: {question.paperNumber?.label || question.paperNumber || '-'}</p>
                                        <p>Version Number: {question.versionNumber || '-'}</p>
                                    </div>
                                </Col>
                                <Col className="subject-creator-info-section font-size-13">
                                    <div className="subject-creator-info">
                                        <p>Marking Scheme ID: {question.markingSchemeId || '-'}</p>
                                        <p>Question ID: {question.internalId || '-'}</p>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <Row>
                                <Col md="12" className="justify-content-center">
                                    <h4 className="card-title mb-3">Question Detail</h4>
                                </Col>
                            </Row>
                            <Row className="section-top">
                                <Col md="6" className="text-center section-right">
                                    <ReactDevicePreview device="iphone8" scale="0.7">
                                        <div className="device-content">
                                            <MobileScreen
                                                cardHeaderText={question.subject?.label || question?.subject?.title || '-'}
                                                cardSubHeaderText={question.chapter?.label || question?.chapter?.title || '-'}
                                                forceUpdate={forceUpdate}
                                            >
                                                <span>
                                                    {parse(question.body || '')}
                                                    {subQuestions && Object.keys(subQuestions)?.map((key, idx) => {
                                                        return (
                                                            <span key={subQuestions[key].id}>
                                                                {parse(subQuestions[key].body || '')}


                                                                {Object.keys(subSubQuestions)?.map((subsubqq, val) => {
                                                                    return <span>{parse(subSubQuestions[subsubqq].body || '')}</span>
                                                                })}
                                                            </span>
                                                        );
                                                    })}
                                                </span>
                                            </MobileScreen>
                                        </div>
                                    </ReactDevicePreview>
                                </Col>
                                <Col md="6">
                                    <span className="section-bottom ">
                                        <h5 className="my-2 text-black-50">Question</h5>

                                        {question.loaded && (
                                            // <Editor
                                            //     {...appConfig.tinyRTE}
                                            //     // initialValue={question.body}
                                            //     value={question.body}
                                            //     init={{
                                            //         height: 400,
                                            //         menubar: false,
                                            //         images_upload_handler: (blobInfo, success, failure) => {
                                            //             TinyImageUpload(blobInfo, success, failure, setLoader);
                                            //         }
                                            //     }}
                                            //     onEditorChange={handleQuestionEditorChange}
                                            // />
                                            <Editor
                                                editorState={editorState}
                                                onEditorStateChange={(newEditorState)=>{
                                                    if(question.body !== undefined){
                                                        let newQuestion = question;
                                                        const htmlContent = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
                                                        newQuestion.body = htmlContent
                                                        setQuestion(newQuestion)
                                                        setEditorState(newEditorState)
                                                        forceUpdate()
                                                    }
                                                    
                                                }}
                                                wrapperClassName="wrapper-class"
                                                editorClassName="editor-class"
                                                toolbarClassName="toolbar-class"
                                                toolbar={{
                                                image: {
                                                    uploadEnabled: true,
                                                    alignmentEnabled: true,
                                                    uploadCallback: preImageUpload,
                                                    previewImage: true,
                                                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                                    defaultSize: {
                                                    height: 'auto',
                                                    width: 'auto',
                                                    },
                                                },
                                                colorPicker: { 
                                                    colors: rteColors
                                                  },
                                                fontFamily: {
                                                options: rteFontFamily,
                                                },
                                                }}
                                                toolbarCustomButtons={[<SpecialCharPicker />]}
                                            />
                                        )}
                                    </span>

                                    {question.loaded && subQuestions &&
                                        Object.keys(subQuestions)?.map((key, idx) => {
                                            return (
                                                <>
                                                    <SubQuestion
                                                        keyid={key}
                                                        key={subQuestions[key].id}
                                                        subQuestion={subQuestions[key]}
                                                        subQuestions={subQuestions}
                                                        setSubQuestions={setSubQuestions}
                                                        idx={idx}
                                                        inputSampleAnswer={inputSampleAnswer}
                                                        inputMarking={inputMarking}
                                                        forceUpdate={forceUpdate}
                                                        setLoader={setLoader}
                                                        question={question}
                                                        subsubquestions={subSubQuestions}
                                                    />
                                                    {subQuestions[key].subsubQuestions && [subQuestions[key].subsubQuestions].length > 0 && Object.keys(subQuestions[key].subsubQuestions).map((subkey, subidx) => (
                                                        <SubQuestion
                                                        subSubQuestionBlock={true}
                                                        keyid={subkey}
                                                        key={subQuestions[key].subsubQuestions[subkey].id}
                                                        subQuestion={subQuestions[key].subsubQuestions[subkey]}
                                                        subQuestions={subQuestions}
                                                        setSubQuestions={setSubQuestions}
                                                        idx={subidx}
                                                        inputSampleAnswer={inputSampleAnswer}
                                                        inputMarking={inputMarking}
                                                        forceUpdate={forceUpdate}
                                                        setLoader={setLoader}
                                                        question={question}
                                                        subsubquestions={subSubQuestions}
                                                    />
                                                    ))}
                                                </>
                                            )
                                        })
                                    }

                                    {/* <Button
                                        type="button"
                                        color="link"
                                        className="btn btn-link waves-effect mr-4"
                                        onClick={addSubQuestion}
                                    >
                                        <i className="bx font-size-20 bxs-plus-square"></i>
                                        <span style={{ verticalAlign: 'top' }} className="font-size-15">
                                            {' '}
                                            Add Subquestion{' '}
                                        </span>
                                    </Button> */}

                                    {/* {subQuestions?.length < 1 && ( */}
                                    <React.Fragment>
                                        <Button
                                            type="button"
                                            color="link"
                                            className="btn btn-link waves-effect mr-4"
                                            onClick={() => {
                                                setsampleAnswerModalGetSet({
                                                    get: () => {
                                                        return sampleAnswer;
                                                    },
                                                    set: setSampleAnswer
                                                });
                                                toggleModal();
                                            }}
                                        >
                                            <i className={`bx font-size-20 ${sampleAnswer ? 'bx-check-circle' : 'bxs-conversation'}`}></i>
                                            <span style={{ verticalAlign: 'top' }} className="font-size-15">
                                                {' '}
                                                Explanation{' '}
                                            </span>
                                        </Button>

                                        {isMarkingSchemeOpen && (
                                            marking.length > 7 ?
                                            <Lightbox image={marking} title="Marking Scheme" onClose={() => setIsMarkingSchemeOpen(false)} />
                                            :
                            null
                                        )}
                                        <Button
                                            type="button"
                                            color="link"
                                            className="btn btn-link waves-effect mr-4"
                                            onClick={() => {
                                                setIsMarkingSchemeOpen(true)
                                                // setMarkingModalGetSet({
                                                //     get: () => {
                                                //         return marking;
                                                //     },
                                                //     set: setMarking
                                                // });
                                                // toggleMarkingModal();
                                            }}
                                        >
                                            <i className={`bx font-size-20 ${marking ? 'bx-badge-check' : 'bxs-badge-check'}`}></i>
                                            <span style={{ verticalAlign: 'top' }} className="font-size-15">
                                                {' '}
                                                Marking{' '}
                                            </span>
                                        </Button>

                                    </React.Fragment>
                                    {/* )} */}
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12" className="text-right">
                                    <Button
                                        type="button"
                                        onClick={() => {
                                            history.goBack();
                                        }}
                                        className="agora-button cancel-button border-radius"
                                    >
                                        Cancel
                                    </Button>
                                    <Button className="agora-button save-button border-radius" onClick={handleSaveQuestion}>
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>

{/*{marking === '<p></p>' || marking === "" &&*/}


            <MarkingModal
                loader={loader}
                modalOpen={isMarkingSchemeOpen}
                dropzoneElement={dropzoneElement}
                selectedQuestionType={selectedQuestionType}
                uploadedMarkingScheme={uploadedMarkingScheme}
                setSelectedQuestionType={setSelectedQuestionType}
                setLoader={setLoader}
                toggleModal={()=>setIsMarkingSchemeOpen(false)}
                handleSaveQuestion={handleSaveQuestion}
                sampleAnswer={sampleAnswer}
                setSampleAnswer={setSampleAnswer}
                handleAcceptedFiles={handleAcceptedFiles}
                uploadImage={uploadImage}
                sampleAnswerModalGetSet={sampleAnswerModalGetSet}
                question={question}
                subQuestions={subQuestions}
            />
{/*}*/}

            <PopupSampleAnswer
                loader={loader}
                modalOpen={modalOpen}
                setLoader={setLoader}
                toggleModal={toggleModal}
                handleSaveQuestion={handleSaveQuestion}
                sampleAnswer={sampleAnswer}
                setSampleAnswer={setSampleAnswer}
                sampleAnswerModalGetSet={sampleAnswerModalGetSet}
            />

            <PopupMarking
                loader={loader}
                modalOpen={modalMarkingOpen}
                setLoader={setLoader}
                toggleModal={toggleMarkingModal}
                handleSaveQuestion={handleSaveQuestion}
                markingModalGetSet={markingModalGetSet}
                marking={marking}
            />

            <PopupAddEditQuestions
                edit
                question={question}
                show={questionModalOpen}
                setQuestion={setQuestion}
                toggleModal={toggleEditQuestionModal}
            />
        </React.Fragment>
    );
};

export default AddEditStructuredQuestion;
