import React from 'react';
import { Row, Col, Button, Card, CardBody } from 'reactstrap';
import parse from 'html-react-parser';
const MobileScreen = (props) => {
  let currTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  const optionsNames = ['A', 'B', 'C', 'D', 'E'];

  return (
    <div>
      <div className="topBar">
        <Row className="font-size-17 p-1 text-white">
          <Col md="6">{currTime}</Col>
          <Col md="6" style={{ textAlign: 'end' }}>
            <i className="fas fa-signal mr-1"></i>
            <i className="fas fa-wifi mr-1"></i>
            <i className="fas fa-battery-full"></i>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md="12">
            <h3 className="text-white" style={{ textAlign: 'center' }}>
              {props.headerText || 'Question'}
            </h3>
          </Col>
        </Row>
      </div>

      <div className="screenCard">
        <Row>
          <Col md="6">
            <span className="text-muted text-capitalize">{props.cardHeaderText || 'Chemistry - O Level'}</span>
            <br />
            <span className="text-muted text-capitalize">{props.cardSubHeaderText || 'Organic Chemistry'}</span>
          </Col>
          <Col md="6" className="font-size-22" style={{ color: '#196bb5', textAlign: 'end', opacity: '85%' }}>
            <span className="cardIconCircle mr-3">
              <i className="fas fa-exclamation"></i>
            </span>
            <span className="cardIconCircle mr-2">
              <i className="fas fa-expand-arrows-alt"></i>
            </span>
          </Col>
        </Row>

        <div
          className="mt-1 mx-1"
          style={{ overflow: 'auto', height: props.mcq ? '22rem' : '25rem', fontSize: '12pt' }}
        >
          {props.children}
          <div className="answer_container" style={{ flexDirection: 'column', display: 'flex' }}>
            {props.options &&
              props.options.length > 0 &&
              props.options.map((option, idx) => {
                return (
                  <span style={{ flexDirection: 'column' }}>
                    {/* {optionsNames[idx]}. {option.body.replace(/<p\s*\/?>|<\/p>/gi, '')} */}
                    {optionsNames[idx]}.{parse(option.body || '')}
                  </span>
                );
              })}
          </div>
        </div>

        {props.options && props.options.length > 0 && (
          <Card className="device-option">
            <CardBody className="p-2">
              <Row className="justify-content-center">
                {props.options.map((option, idx) => {
                  return (
                    <Col md="2" key={'opt_scr_' + idx}>
                      <span
                        className={'btn ' + (option.answer ? 'correct-option' : '')}
                        onClick={() => {
                          let newOptions = props.options;
                          newOptions[idx].answer = !newOptions[idx].answer;
                          props.setOptions(newOptions);
                          props.forceUpdate();
                        }}
                      >
                        {optionsNames[idx]}
                      </span>
                    </Col>
                  );
                })}
              </Row>
            </CardBody>
          </Card>
        )}
      </div>
      <div className="bottomBar">
        <Row className="font-size-17 p-1 text-white">
          <Col md="12 mt-1" style={{ textAlign: 'center' }}>
            <Button
              size="lg"
              className="agora-button border-radius mr-1"
              style={{ width: '8rem', backgroundColor: '#999999', backgroundImage: 'none' }}
            >
              Back
            </Button>
            <Button size="lg" className="agora-button save-button border-radius ml-1" style={{ width: '8rem' }}>
              Next
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MobileScreen;
