import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  UncontrolledTooltip,
  Button,
  Pagination,
  PaginationItem,
  PaginationLink
} from 'reactstrap';
import { Link } from 'react-router-dom';

import { getSubject } from './Services/subject-service';
import { getSyllabuses, saveSyllabus, deleteSyllabus } from './Services/syllabus-service';
import PopupAddSyllabus from './popup-add-syllabus';
import PopupEditSyllabus from './popup-edit-syllabus';
import Loader from '../../shared/loader/loader';
import BackButton from '../../components/Common/BackButton';
import { getChaptersBySubjectId } from '../Chapters/Services/chapter-service';
import { getListAssessments } from '../../service/assessment';
import { initialPaginationObject, getPaginationInformation } from '../../helpers/general/pagination';
import { ShowNotification, SetStatusStyle, SetSyllabusType, GetQueryParam } from '../../helpers/general/generalHelpers';
import SweetAlert from 'react-bootstrap-sweetalert';

const ViewSubject = (props) => {
  const syllabusObject = {
    _id: '',
    chapter: {
      _id: '',
      title: ''
    },
    type: 'chapter',
    subject: '',
    assessment: {
      _id: '',
      title: ''
    }
  };
  const [pagination, setPagination] = useState(initialPaginationObject);
  const [isOpenAddSyllabus, setIsOpenAddSyllabus] = useState(false);
  const [isEditAddSyllabus, setIsEditAddSyllabus] = useState(false);
  const [loader, setLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const [syllabusEntries, setSyllabusEntries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [chapters, setChapters] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [confirmation, setConfirmation] = useState(false);
  const [syllabus, setSyllabus] = useState(syllabusObject);
  const [subject, setSubject] = useState({
    title: '',
    description: '',
    active: true,
    subjectIcon: 'Icon',
    studyLevelId: '',
    syllabusEntries: [],
    createdBy: '',
    createdAt: null,
    updatedAt: null
  });

  const handleGetChaptersBySubjectId = (id) => {
    getChaptersBySubjectId(id)
      .then((response) => {
        setChapters(response.data.chapters);
      })
      .catch((error) => {
        throw error;
      });
  };

  const handleGetAssesmentById = (id) => {
    getListAssessments({ filters: { subject: id }, pageSize: 10000 })
      .then((response) => {
        setAssessments(response.data.assessments);
      })
      .catch((error) => {
        throw error;
      });
  };

  useEffect(() => {
    if (subject._id) {
      handleGetChaptersBySubjectId(subject._id);
      handleGetAssesmentById(subject._id);
    }
  }, [subject]);

  useEffect(() => {
    if (props.location.search) {
      const subjectId = GetQueryParam(props.location.search, 'id');
      getSubjectById(subjectId);
      getSyllabusBySubjectId(subjectId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.search, currentPage]);

  function getSubjectById(subjectId) {
    setLoader(true);
    getSubject(subjectId)
      .then((response) => {
        const subject = response.data.subject;
        setSubject(subject);
      })
      .catch((error) => {
        const message =
          error && error.response && error.response.data
            ? error.response.data.message
            : 'There was an error fetching subject information, please try again!';
        ShowNotification({ type: 'error', message });
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
  }

  function getSyllabusBySubjectId(subjectId) {
    setTableLoader(true);
    getSyllabuses(currentPage, { subject: subjectId }, 15)
      .then((response) => {
        const syllabuses = response.data.syllabus;
        setSyllabusEntries(syllabuses);
        setPagination(getPaginationInformation(response.data.Count, currentPage));
      })
      .catch((error) => {
        const message =
          error && error.response && error.response.data
            ? error.response.data.message
            : 'There was an error fetching syllabus entries, please try again!';
        ShowNotification({ type: 'error', message });
        throw error;
      })
      .finally(() => {
        setTableLoader(false);
      });
  }

  function editSyllabus(data) {
    if (data) {
      const temp = {
        _id: data._id,
        type: data.content?.[0]?.type,
        chapter: data.content?.[0]?.chapter,
        assessment: data.content?.[0]?.assessment
      };
      setSyllabus(temp);
    }
    setIsEditAddSyllabus(true);
  }

  function deleteSyllabusEntry(syllabusId) {
    setTableLoader(true);
    deleteSyllabus(syllabusId)
      .then((response) => {
        ShowNotification({ type: 'success', message: 'Syllabus deleted successfully!' });
        const index = syllabusEntries.findIndex((se) => se._id === syllabusId);
        syllabusEntries.splice(index, 1);
      })
      .catch((error) => {
        ShowNotification({ type: 'error', message: "Couldn't delete syllabus. Please try again!" });
        throw error;
      })
      .finally(() => {
        setTableLoader(false);
      });
  }

  function updateSyllabus(event, values) {
    values.subject = subject._id;
    setModalLoader(true);
    const request = {
      title: 'test dummy',
      subject: subject._id,
      content: []
    };

    if (values.type === 'chapter') {
      request.content.push({
        type: values.type,
        chapter: values.chapter
      });
    } else if (values.type === 'assessment') {
      request.content.push({
        type: values.type,
        assessment: values.assessment
      });
    }

    saveSyllabus(syllabus._id, request, props.history, setModalLoader).then((data) => {
      getSyllabusBySubjectId(subject._id);
      setSyllabus(syllabusObject);
      setIsEditAddSyllabus(false);
      setIsOpenAddSyllabus(false);
    });
  }

  function toggleSyllabusModal() {
    setIsOpenAddSyllabus(!isOpenAddSyllabus);
    removeBodyCss();
  }

  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
  }

  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="relative">
          {/* Render Page title */}
          <BackButton text="Back to subjects" url="/subjects" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Loader loading={loader} />
                  <Row>
                    <Col md="12 subject-info-section">
                      <div className="subject-icon">
                        {!subject.subjectIcon || subject.subjectIcon === 'Icon' ? (
                          <div className="avatar-lg">
                            <span className="avatar-title text-capitalize rounded-circle">
                              {subject.title.charAt(0)}
                            </span>
                          </div>
                        ) : (
                          <div>
                            <img className="img-responsive" src={subject.subjectIcon} alt={subject.title} />
                          </div>
                        )}
                      </div>
                      <div className="subject-info">
                        <h4 className="font-size-18 text-capitalize">
                          {subject.title}
                          <span className="subject-status">
                            <SetStatusStyle status={subject.active ? 'active' : 'inactive'} />
                          </span>
                          <Link
                            className="float-right text-secondary"
                            to={{ pathname: '/subject', search: '?id=' + subject._id }}
                            id={'edit-subject' + subject._id}
                          >
                            <i className="bx bxs-edit-alt"></i>
                            <UncontrolledTooltip placement="top" target={'edit-subject' + subject._id}>
                              Edit Subject
                            </UncontrolledTooltip>
                          </Link>
                        </h4>
                        <h5 className="font-size-14">{subject.studyLevelId}</h5>
                        <p className="font-size-12">{subject.description}</p>
                      </div>
                      <div className="clearfix"></div>
                    </Col>
                    <Col md="12 subject-creator-info-section font-size-13">
                      <div className="subject-creator-info">
                        <p>Created by: {subject.createdBy}</p>
                        <p>
                          Created at:{' '}
                          {new Intl.DateTimeFormat('en-GB', {
                            year: 'numeric',
                            month: 'short',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit'
                          }).format(new Date(subject.createdAt))}
                        </p>
                        <p>
                          Last updated at:{' '}
                          {new Intl.DateTimeFormat('en-GB', {
                            year: 'numeric',
                            month: 'short',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit'
                          }).format(new Date(subject.updatedAt))}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Loader loading={tableLoader} />
                  <Row>
                    <Col xs="12">
                      <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h4 className="mb-0 font-size-18">Syllabus</h4>
                        <div className="page-title-right">
                          <Button
                            onClick={(e) => {
                              editSyllabus(null);
                            }}
                            className="btn btn-primary btn-block agora-button border-radius waves-effect waves-light"
                          >
                            + Add New Entry
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <Table className="table-centered table-nowrap table-hover">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Type</th>
                          <th scope="col" className="text-center" style={{ width: '150px' }}>
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {syllabusEntries.map((syllabus, i) => (
                          <tr key={'_syllabus_' + i}>
                            <td>
                              <h5 className="font-size-14 mb-1">
                                {syllabus?.content?.[0]?.type === 'chapter'
                                  ? syllabus?.content?.[0]?.chapter?.title
                                  : syllabus?.content?.[0]?.assessment?.title}
                              </h5>
                            </td>
                            <td>
                              <div>
                                <SetSyllabusType type={syllabus?.content?.[0]?.type} />
                              </div>
                            </td>
                            <td className="text-center">
                              <ul className="list-inline font-size-20 contact-links mb-0">
                                <li className="list-inline-item px-1">
                                  <Button
                                    onClick={(e) => {
                                      editSyllabus(syllabus);
                                    }}
                                    className="agora-action-button font-size-20"
                                    id={'edit-syllabus' + syllabus._id}
                                  >
                                    <i className="bx bxs-edit-alt"></i>
                                    <UncontrolledTooltip placement="top" target={'edit-syllabus' + syllabus._id}>
                                      Edit
                                    </UncontrolledTooltip>
                                  </Button>
                                </li>
                                <li className="list-inline-item px-1">
                                  <Button
                                    onClick={(e) => {
                                      setSyllabus({ ...syllabus, _id: syllabus._id });
                                      setConfirmation(true);
                                    }}
                                    className="agora-action-button font-size-20"
                                    id={'delete-syllabus' + syllabus._id}
                                  >
                                    <i className="bx bxs-trash-alt"></i>
                                    <UncontrolledTooltip placement="top" target={'delete-syllabus' + syllabus._id}>
                                      Delete
                                    </UncontrolledTooltip>
                                  </Button>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  {/* Modal */}
                  <PopupAddSyllabus
                    isOpenAddSyllabus={isOpenAddSyllabus}
                    toggleSyllabusModal={toggleSyllabusModal}
                    updateSyllabus={updateSyllabus}
                    chapters={chapters}
                    syllabus={syllabus}
                    setSyllabus={setSyllabus}
                    modalLoader={modalLoader}
                    assessments={assessments}
                  />

                  <PopupEditSyllabus
                    isEditAddSyllabus={isEditAddSyllabus}
                    toggleSyllabusModal={() => setIsEditAddSyllabus(false)}
                    updateSyllabus={updateSyllabus}
                    chapters={chapters}
                    syllabus={syllabus}
                    setSyllabus={setSyllabus}
                    modalLoader={modalLoader}
                    assessments={assessments}
                  />

                  {confirmation ? (
                    <SweetAlert
                      title="Are you sure?"
                      danger
                      showCancel
                      confirmBtnText="Delete Syllabus"
                      confirmBtnBsStyle="danger"
                      cancelBtnText="Cancel"
                      cancelBtnBsStyle="basic"
                      onConfirm={() => {
                        setConfirmation(false);
                        deleteSyllabusEntry(syllabus._id);
                        setSyllabus(syllabusObject);
                      }}
                      onCancel={() => {
                        setConfirmation(false);
                        setSyllabus(syllabusObject);
                      }}
                    >
                      You won't be able to revert this!
                    </SweetAlert>
                  ) : null}

                  <Row>
                    <Col lg="12">
                      <Pagination className="pagination pagination-rounded justify-content-center mt-4">
                        <PaginationItem disabled={pagination.currentPage === 1}>
                          <PaginationLink
                            first
                            onClick={(e) => {
                              paginate(1);
                            }}
                          />
                        </PaginationItem>
                        <PaginationItem disabled={pagination.currentPage === 1}>
                          <PaginationLink
                            previous
                            onClick={(e) => {
                              paginate(pagination.currentPage - 1);
                            }}
                          />
                        </PaginationItem>
                        {pagination.pages.map((page, index) => (
                          <PaginationItem key={'_page_' + index} active={page === pagination.currentPage}>
                            <PaginationLink
                              onClick={(e) => {
                                paginate(page);
                              }}
                            >
                              {page}
                            </PaginationLink>
                          </PaginationItem>
                        ))}
                        <PaginationItem disabled={pagination.currentPage === pagination.totalPages}>
                          <PaginationLink
                            next
                            onClick={(e) => {
                              paginate(pagination.currentPage + 1);
                            }}
                          />
                        </PaginationItem>
                        <PaginationItem disabled={pagination.currentPage === pagination.totalPages}>
                          <PaginationLink
                            last
                            onClick={(e) => {
                              paginate(pagination.totalPages);
                            }}
                          />
                        </PaginationItem>
                      </Pagination>
                      <div className="records-count text-center">
                        <p>
                          Showing <strong>{pagination.startIndex + 1}</strong> to{' '}
                          <strong>{pagination.endIndex + 1}</strong> of <strong>{pagination.totalItems}</strong> records
                        </p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewSubject;
