import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Table, Button } from 'reactstrap';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import cloneDeep from 'lodash.clonedeep';
import debounce  from 'lodash.debounce';

import Loader from '../../shared/loader/loader';
import BackButton from '../../components/Common/BackButton';
import PopupAddStudyGuide from './PopupAddAssessment';
import { ShowNotification, GetQueryParam, SetStatusStyle } from '../../helpers/general/generalHelpers';
import { getDetailAssessment, putUpdateAssessment } from '../../service/assessment';
import { getListQuestions, getListQuestions2 } from '../../service/question';
import { dateFormat } from '../../helpers/date';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './index.scss';

const EditAssessment = (props) => {
  const assessmentId = GetQueryParam(props.location.search, 'id');
  const [loader, setLoader] = useState(false);

  const [questions, setQuestions] = useState([]);
  const [questionLoading, setQuestionLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [assessment, setAssessment] = useState(null);

  const [questionIds, setQuestionIds] = useState([]);
  const [listIsEdit, setListIsEdit] = useState([]);

  const [selectedNewQuestion, setSelectedNewQuestion] = useState();
  const [showNewQuestion, setShowNewQuestion] = useState(false);

  const [detailAssessment, setDetailAssessment] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const getQuestions = async (assessment) =>{
    try{
      setQuestionLoading(true);
      
      const questionsData = assessment?.questions?.map((item) => {
        const bodyQuestion = item.questions.body.replace(/<\/?[^>]+>/gi, '').replace('&nbsp;', ' ');
        return {
          item: item,
          id: item._id,
          value: item._id,

          label: `${item.internalId} - ${bodyQuestion}`
        };
      });
      const reMappingQuestions = assessment?.questions?.map((item) => {
        let data = questionsData.find((q) => q.id === item._id);
        data.isEdit = false;
        return data;
      });
      const dataListEdit = assessment.questions.map((item) => {
        return { id: item, value: false };
      });
      setListIsEdit(dataListEdit);
      setQuestionIds(reMappingQuestions);
      setQuestions(questionsData);
    }catch (e) {
      ShowNotification({
        type: 'error',
        message: 'Oops, sorry! Failed get questions'
      });
      console.log(e)
    } finally {
      setQuestionLoading(false);
    }
  }



  const handleGetQuestions = async (inputValue) => {
    try {
      setQuestionLoading(true);
      //debugger;
      let level = assessment.level;
      let filterBuilder = {
        chapter:
          level === 'Subject' ? '' : level === 'Study Guide' ? assessment.studyGuide.chapter : assessment?.chapter._id,
        type: assessment?.type,
        subject: assessment?.subject._id,
        question: inputValue
      };
      const response = await getListQuestions2({ pageNumber: 1, filters: filterBuilder, pageSize:30 });

      const questionsData = response.data?.questions?.map((item) => {
        const bodyQuestion = item.questions.body.replace(/<\/?[^>]+>/gi, '').replace('&nbsp;', ' ');
        return {
          item: item,
          id: item._id,
          value: item._id,

          label: `${item.internalId} - ${bodyQuestion}`
        };
      });
      setQuestions(questionsData);
      return questionsData;
      
    } catch (e) {
      ShowNotification({
        type: 'error',
        message: 'Oops, sorry! Failed get questions'
      });
      console.log(e)
    } finally {
      setQuestionLoading(false);
    }
  };

  const handleGetDetailAssessment = (resQuestion = questions) => {
    console.log('asd');
    setLoader(true);
    getDetailAssessment({ id: assessmentId })
      .then((response) => {
        setDetailAssessment(response.data.assessment);
        setAssessment(response.data.assessment)
        getQuestions(response.data.assessment)
        //handleGetQuestions(response.data.assessment);
      })
      .catch((error) => {
        ShowNotification({
          type: 'error',
          message: error.message
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const toggleModal = () => {
    setIsPopupOpen(!isPopupOpen);
    document.body.classList.add('no_padding');
  };

  const checkForDuplicates = (array) => {
    return new Set(array).size !== array.length;
  };

  const handleSaveQuestion = (body) => {
    putUpdateAssessment({ id: assessmentId, body: body })
      .then((response) => {
        const tempListIsEdit = cloneDeep(listIsEdit);
        if (selectedNewQuestion) {
          tempListIsEdit.push({ id: selectedNewQuestion.id, value: false });
          const tempQuestionIds = cloneDeep(questionIds);
          let qNew =selectedNewQuestion //questions.find((q) => q.id === selectedNewQuestion.id)
          qNew.isEdit = false
          tempQuestionIds.push(qNew);
          setListIsEdit(tempListIsEdit);
          setQuestionIds(tempQuestionIds);
          setSelectedNewQuestion(null);
          ShowNotification({
            type: 'success',
            message: `Great, you’ve successfully add new question`
          });
        } else {
          ShowNotification({
            type: 'success',
            message: `Great, you’ve successfully update question`
          });
        }
      })
      .catch((error) => {
        ShowNotification({
          type: 'error',
          message: error.message
        });
      });
  };

  const handleButtonSaveQuestion = (value) => {
    const reMapQuestion = questionIds.map((item) => item.id);
    const body = {
      questions: [...reMapQuestion, selectedNewQuestion.id]
    };

    if (!checkForDuplicates(body.questions)) {
      handleSaveQuestion(body);
    } else {
      ShowNotification({
        type: 'error',
        message: 'Oops, sorry! The selected question has been selected'
      });
    }
  };

  const handleEditQuestion = (key) => {
    const temp = cloneDeep(questionIds);
    temp[key].isEdit = !temp[key].isEdit;
    setQuestionIds(temp);
  };

  const handleSaveItemQuestion = (key) => {
    const temp = cloneDeep(questionIds);
    temp[key].isEdit = !temp[key].isEdit;
    const reMapQuestion = temp.map((item) => item.id);
    if (!checkForDuplicates(reMapQuestion)) {
      handleSaveQuestion({ questions: reMapQuestion });
      setQuestionIds(temp);
    } else {
      ShowNotification({
        type: 'error',
        message: 'Oops, sorry! The selected question has been selected'
      });
    }
  };

  const handleSelectItemQuestion = (value, item, key) => {
    const temp = cloneDeep(questionIds);
    temp[key] = value;
    temp[key].isEdit = true;
    setQuestionIds(temp);
  };

  const handleDeleteQuestion = (key) => {
    const temp = cloneDeep(questionIds);
    temp.splice(key, 1);
    const reMapQuestion = temp.map((item) => item.id);
    if (!checkForDuplicates(reMapQuestion)) {
      handleSaveQuestion({ questions: reMapQuestion });
      setQuestionIds(temp);
    } else {
      ShowNotification({
        type: 'error',
        message: 'Oops, sorry! The selected question has been selected'
      });
    }
  };

  const debouncedFetchQuestions = debounce(handleGetQuestions, 500); 
  const loadOptions =  (inputValue) => {
    debouncedFetchQuestions(inputValue)

  };

  useEffect(() => {
    handleGetDetailAssessment();
    setShowNewQuestion(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Loader loading={loader} />
      <div className="page-content assessment">
        <Container fluid className="relative">
          <BackButton text="Back to Assessments" url="/assessments" />

          <Card>
            <CardBody>
              <Row>
                <Col md="6" className="d-flex">
                  <div>
                    <h4 className="card-title mb-3">{detailAssessment?.title}</h4>
                  </div>
                  <div style={{ marginLeft: '12px' }}>
                    <span className="badge badge-pill badge-soft-success font-size-12 text-capitalize">
                      {detailAssessment?.type}
                    </span>{' '}
                    <SetStatusStyle status={detailAssessment?.active ? 'active' : 'inactive'} />
                  </div>
                </Col>
                <Col className="col text-md-right text-sm-right">
                  <i onClick={() => setIsPopupOpen(true)} className="bx bxs-edit-alt font-size-16 btn p-0"></i>
                </Col>
              </Row>
              <Row>
                <Col md="3" className="justify-content-center">
                  {detailAssessment?.subject?.title} | {detailAssessment?.studyLevelId}
                  <br />
                  {detailAssessment?.level} Assessment <br />
                  {detailAssessment?.level === 'Subject' &&
                    detailAssessment?.diagnosticTest === true &&
                    'Diagnostic Test'}
                  {detailAssessment?.level === 'Chapter' && (detailAssessment?.chapter?.title || ' - ')}
                  {detailAssessment?.level === 'Study Guide' && (detailAssessment?.studyGuide?.title || ' - ')}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md="3" className="justify-content-center agora-grey-color">
                  Created by : {detailAssessment?.createdBy} <br />
                  Created at : {dateFormat(detailAssessment?.createdAt || new Date())} <br />
                  Last updated at : {dateFormat(detailAssessment?.updatedAt || new Date())}
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <h4 className="card-title mb-3">Questions List</h4>

              <div className="table-responsive" style={{ minHeight: '100vh' }}>
                <Table className="table-centered table-nowrap table-hover">
                  <thead className="thead-light font-size-15">
                    <tr>
                      <th scope="col" style={{ width: '90%', maxWidth: '90%' }}>
                        Question ID
                      </th>
                      <th scope="col" className="text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="agora-lato">
                    {questionIds?.map((item, i) => (
                      <tr key={`${item.id}-${i}`}>
                        <td className="td-question">
                          {item.isEdit ? (
                            <Select
                              value={item}
                              isLoading={questionLoading}
                              isSearchable
                              name={`question-${i}`}
                              placeholder="Search by question ID"
                              options={questions}
                              onChange={(value) => {
                                handleSelectItemQuestion(value, item, i);
                              }}
                              onInputChange={loadOptions}
                              
                            />
                          ) : (
                            <div className="ellipsis"> {item.label}</div>
                          )}
                        </td>
                        <td className="action-section">
                          {(item.isEdit === undefined) || !item.isEdit ? (
                            <i
                              onClick={() => handleEditQuestion(i)}
                              className="bx bx-edit-alt font-size-16 btn p-0"
                            ></i>
                          ) : (
                            <i
                              onClick={() => handleSaveItemQuestion(i)}
                              className="bx bx-save font-size-16 btn p-0"
                            ></i>
                          )}{' '}
                          <i onClick={() => handleDeleteQuestion(i)} className="bx bx-trash font-size-16 btn p-0"></i>
                        </td>
                      </tr>
                    ))}
                    {showNewQuestion && (
                      <tr>
                        <td className="td-question">
                          <Select
                            isLoading={questionLoading}
                            isSearchable
                            name="newQuestion"
                            placeholder="Search by question ID"
                            options={questions}
                            value={selectedNewQuestion}
                            onChange={(value) => {
                              setSelectedNewQuestion(value);
                            }}
                            onInputChange={loadOptions}
                            //loadOptions={promiseOptions}
                          />
                        </td>
                        <td className="action-section">
                          <i
                            onClick={() => handleButtonSaveQuestion()}
                            className="bx bx-save font-size-16 btn p-0 agora-dark-grey-color"
                          ></i>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <div className="text-center">
                  <Button
                    type="submit"
                    disabled={!!selectedNewQuestion || showNewQuestion}
                    onClick={() => setShowNewQuestion(true)}
                    className={`btn btn-primary agora-button save-button border-radius waves-effect waves-light`}
                  >
                    + Add New Question
                  </Button>
                </div>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
      <PopupAddStudyGuide
        edit={detailAssessment}
        show={isPopupOpen}
        UpdateContent={handleGetDetailAssessment}
        toggleModal={toggleModal}
      />
    </React.Fragment>
  );
};

export default EditAssessment;
