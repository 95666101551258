import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Label, FormGroup } from 'reactstrap';

import PageTitle from '../../components/Common/PageTitle';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import { saveSubject, getSubject } from './Services/subject-service';
import { Link } from 'react-router-dom';
import Loader from '../../shared/loader/loader';
import { ShowNotification, GetQueryParam } from '../../helpers/general/generalHelpers';
import Dropzone from 'react-dropzone';
import { IsSquareImage, UploadImage, DeleteImage, GetFileName } from '../../helpers/general/services/image-service';
import { Buffer } from "buffer";

const AddEditSubject = (props) => {
  const [pageTitle, setPageTitle] = useState('Add New Subject');
  const [toggleSwitch, settoggleSwitch] = useState(true);
  const dropzoneElement = useRef(null);
  const [openDropzone, setOpenDropzone] = useState(false);
  const [subjectIcon, setSubjectIcon] = useState({ name: '', url: '' });
  const [textcount, settextcount] = useState(0);
  const [file, setFile] = useState(null);
  const [textareabadge, settextareabadge] = useState(0);
  const [loader, setLoader] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const [imageError, setImageError] = useState('');
  const [subject, setSubject] = useState({
    title: '',
    description: '',
    active: true,
    subjectIcon: '',
    subjectCode: '',
    studyLevelId: '',
    syllabusEntries: []
  });

  Buffer.from("anything", "base64");
  window.Buffer = window.Buffer || require("buffer").Buffer;

  useEffect(() => {
    if (props.location.search) {
      setLoader(true);
      setPageTitle('Edit Subject');
      const subjectId = GetQueryParam(props.location.search, 'id');
      getSubject(subjectId)
        .then((response) => {
          const subject = response.data.subject;
          setSubject(subject);
          settoggleSwitch(subject.active);
          settextcount(subject.description.length);
          settextareabadge(subject.description ? true : false);
          setSubjectIcon({
            name: GetFileName(subject.subjectIcon, 'subjects'),
            url: subject.subjectIcon
          });
        })
        .catch((error) => {
          const message =
            error && error.response && error.response.data
              ? error.response.data.message
              : 'There was an error fetching subject information, please try again!';
          ShowNotification({ type: 'error', message });
          throw error;
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [props.location.search]);

  function updateSubject(event, values) {
    setLoader(true);
    values.subjectIcon = subject.subjectIcon;
    values.subjectcode = subject.subjectcode;
    values.syllabusEntries = [];
    values.active = toggleSwitch;
    saveSubject(values, props.history, setLoader);
  }

  function changeIcon(event) {
    setOpenDropzone(true);
    setSubjectIcon({
      name: '',
      url: ''
    });
  }

  function deleteIcon() {
    setImageLoader(true);
    setFile(null);
    DeleteImage(subjectIcon.name, 'subjects')
      .then((data) => {
        console.log(data);
      })
      .catch(() => {})
      .finally(() => {
        setImageLoader(false);
      });
  }

  useEffect(() => {
    if (openDropzone) {
      dropzoneElement.current.open();
    }
  }, [subjectIcon, openDropzone]);

  function handleAcceptedFiles(files) {
    if (files.length) {
      files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      const file = files[0];
      setFile(file);
    }
  }

  useEffect(() => {
    if (file) {
      setImageError('');
      setOpenDropzone(false);
      IsSquareImage(file).then((isSquare) => {
        if (isSquare) {
          setImageLoader(true);
          UploadImage(file, 'subjects')
            .then((result) => {
              setSubject({
                ...subject,
                subjectIcon: result.location
              });
              setSubjectIcon({
                name: GetFileName(result.key, 'subjects'),
                url: file.preview
              });
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              setImageLoader(false);
              setFile(null);
            });
        } else {
          setImageError('Only square images are allowed!');
        }
      });
    }
  }, [file, subject]);

  function textareachange(event) {
    let count = event.target.value.length;
    if (count > 0) {
      settextareabadge(true);
    } else {
      settextareabadge(false);
    }
    settextcount(event.target.value.length);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="relative">
          <Loader loading={loader} />
          {/* Render Page title */}
          <PageTitle title={pageTitle} />

          <AvForm
            className="needs-validation"
            onValidSubmit={(e, v) => {
              updateSubject(e, v);
            }}
          >
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title">Basic Information</h4>
                    <p className="card-title-desc">Fill all information below</p>
                    <Row className="agora-lato">
                      <Col className="col-sm-auto">
                        <Label htmlFor="title">Subject Icon</Label>
                        {subjectIcon.name.length === 0 && (
                          <Dropzone
                            ref={dropzoneElement}
                            multiple={false}
                            onDropRejected={(e) => {
                              setImageError('Please select an image file!');
                            }}
                            accept="image/jpeg, image/png, image/svg, .jpeg, .png, .svg"
                            onDrop={(acceptedFiles) => {
                              handleAcceptedFiles(acceptedFiles);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone subject-image-dropzone">
                                <Loader loading={imageLoader} />
                                <div className="dz-message needsclick" {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <div className="dz-message needsclick subject-icon-dz">
                                    <div className="mt-4">
                                      <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                    </div>
                                    <h5 className="font-size-12">Drop files here or click to upload.</h5>
                                    <p className="font-size-10">Minimum file size 512x512, File type PNG.</p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        )}
                        {subjectIcon.name.length > 0 && (
                          <div className="dropzone-previews" id="subject-icon-preview">
                            <Card className="mt-0 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                              <Loader loading={imageLoader} />
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col md="12">
                                    <img
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={subjectIcon.name}
                                      src={subjectIcon.url}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                            <div className="icon-buttons mt-2">
                              <Button
                                type="button"
                                color="link"
                                className="btn btn-link waves-effect mr-4"
                                onClick={(e) => {
                                  changeIcon();
                                }}
                              >
                                <i className="bx bx-reset"></i> Change Icon
                              </Button>
                              <Button
                                type="button"
                                color="link"
                                className="btn btn-link waves-effect text-danger"
                                onClick={(e) => {
                                  deleteIcon();
                                }}
                              >
                                <i className="bx bxs-trash-alt"></i> Delete Icon
                              </Button>
                            </div>
                          </div>
                        )}
                        <p className="text-danger mt-3">{imageError}</p>
                      </Col>
                      <Col>
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <Label htmlFor="title">Subject Name</Label>
                              <AvField
                                name="title"
                                placeholder="Enter subject name"
                                type="text"
                                errorMessage="Please enter subject name"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="title"
                                value={subject.title}
                              />
                            </FormGroup>
                          </Col>
                          <Col className='mb-2'>
                            <Label>Subject Code</Label>
                              <AvField
                                name="subjectCode"
                                placeholder="Enter subject code"
                                type="number"
                                errorMessage="Please enter subject code"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                  pattern: {
                                    value: /^\d{4}$/,
                                    errorMessage: 'Subject code must be a 4-digit number',
                                  },
                                  min: { value: 1, errorMessage: 'Subject code must be a positive number and cannot be 0' },
                                }}
                                id="subjectCode"
                                value={subject.subjectCode}
                              />
                          </Col>
                          <Col md="12">
                            <FormGroup>
                              <Label htmlFor="studyLevelId">Study Level</Label>
                              <AvField
                                type="select"
                                name="studyLevelId"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                value={subject.studyLevelId}
                              >
                                <option value="">Please select the study level</option>
                                <option value="A Level">A Level</option>
                                <option value="O Level">O Level</option>
                              </AvField>
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup>
                              <Label htmlFor="description">Description</Label>
                              {textareabadge ? (
                                <span className="ml-3 badgecount badge badge-success"> {textcount} / 140 </span>
                              ) : null}
                              <AvField
                                name="description"
                                placeholder="Enter subject description"
                                type="textarea"
                                rows="4"
                                onChange={(e) => {
                                  textareachange(e);
                                }}
                                maxLength="180"
                                errorMessage="Please enter subject description"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="description"
                                value={subject.title}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <Label htmlFor="userStatus">Status</Label>
                            <div className="custom-control status-switch custom-switch mb-2" dir="ltr">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="subjectStatus"
                                checked={toggleSwitch}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="subjectStatus"
                                onClick={(e) => {
                                  settoggleSwitch(!toggleSwitch);
                                }}
                              >
                                {toggleSwitch ? 'Active' : 'Inactive'}
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg="12" className="agora-form-buttons text-right">
                <Link
                  to="/subjects"
                  className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light"
                >
                  Cancel
                </Link>
                <Button
                  type="submit"
                  className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </AvForm>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddEditSubject;
