import React, { useEffect, useState } from 'react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import users from '../../assets/images/sidebar-icons/users.svg';
import transactions from '../../assets/images/sidebar-icons/transactions.svg';
import mechanics from '../../assets/images/sidebar-icons/mechanics.svg';
import tasks from '../../assets/images/sidebar-icons/tasks.svg';
import coupons from '../../assets/images/sidebar-icons/coupon.svg';
import subjects from '../../assets/images/sidebar-icons/subjects.svg';
import chapters from '../../assets/images/sidebar-icons/chapters.svg';
import studyguides from '../../assets/images/sidebar-icons/studyguides.svg';
import assessments from '../../assets/images/sidebar-icons/assessments.svg';
import questions from '../../assets/images/sidebar-icons/questions.svg';
import questiontagging from '../../assets/images/sidebar-icons/questiontagging.svg';
import { fetchRequestWithToken } from '../../service/index';
//i18n
import { withNamespaces } from 'react-i18next';

const SidebarContent = (props) => {
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  const [userAuthRoutes, setUserAuthRoutes] = useState([])
  useEffect(() => {
    var pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu('#side-menu');
      var matchingMenuItem = null;
      var ul = document.getElementById('side-menu');
      var items = ul.getElementsByTagName('a');
      for (var i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };

    const fetchUserRoles = async () => {
      try {
        if (localStorage.getItem('authUser')) {
          const response = await fetchRequestWithToken({
              url: `roleroute?type=ui`,
              method: 'GET'
          });
          // const response = await requestWithToken({
          //   url: `roleroute?type=ui&routePath=${routePath}`,
          //   method: 'GET'
          // });
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const data = await response.json();
          //const userAuthRoutes = data.rolePermission;
          setUserAuthRoutes(data.rolePermission);
        }
      } catch (error) {
        console.error('Error fetching user roles:', error);
      }
    };


    fetchUserRoles();
    initMenu();
  }, [props.location.pathname]);

  function activateParentDropdown(item) {
    item.classList.add('active');
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add('mm-active');
          }
        }
      }
      return false;
    }
    return false;
  }

  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          {/* <li className="menu-title">{props.t('Menu') }  </li> */}
          {/* <li>
                         <Link to="/dashboard" className="waves-effect">
                                    <i className="bx bx-home-circle"></i>
                                    <span>{props.t('Dashboards') }</span>
                                </Link>
                     </li> */}

          {(userAuthRoutes.some(
            (authRoute) => authRoute.routePath === "users"
          ) &&
           <li>
            <Link to="users" className="waves-effect">
              {/* <i className="bx bxs-user-detail"></i> */}
              <img className="sidebar-icon" src={users} alt="sidebar-icon" />
              <span>{props.t('Users')}</span>
            </Link>
          </li>
          )}
          {(userAuthRoutes.some(
              (authRoute) => authRoute.routePath === "roles"
            ) &&
             <li>
              <Link to="roles" className="waves-effect">
                {/* <i className="bx bxs-user-detail"></i> */}
                <img className="sidebar-icon" src={users} alt="sidebar-icon" />
                <span>{props.t('Roles')}</span>
              </Link>
            </li>
          )}
          {/* <li className="menu-title">Pages</li> */}
          {/* <li>
            <Link to="/pages-404" className="waves-effect">
              <img className="sidebar-icon" src={transactions} alt="sidebar-icon" />
              <span>{props.t('Transactions')}</span>
            </Link>
          </li> */}
          {/* <li>
            <Link to="/pages-404" className="waves-effect">
              <img className="sidebar-icon" src={mechanics} alt="sidebar-icon" />
              <span>{props.t('Mechanics')}</span>
            </Link>
          </li> */}
          {(userAuthRoutes.some(
            (authRoute) => authRoute.routePath === "tasks"
          ) &&
          <li>
            <Link to="/tasks" className="waves-effect">
              <img className="sidebar-icon" src={tasks} alt="sidebar-icon" />
              <span>{props.t('Tasks')}</span>
            </Link>
          </li>
          )}
          {(userAuthRoutes.some(
            (authRoute) => authRoute.routePath === "coupons"
          ) &&
          <li>
            <Link to="/coupons" className="waves-effect">
              <img className="sidebar-icon" src={coupons} alt="sidebar-icon" />
              <span>{props.t('Coupons')}</span>
            </Link>
          </li>
          )}
          <li className="menu-title">CONTENT</li>
          {(userAuthRoutes.some(
            (authRoute) => authRoute.routePath === "subjects"
          ) &&
          <li>
            <Link to="/subjects" className="waves-effect">
              <img className="sidebar-icon" src={subjects} alt="sidebar-icon" />
              <span>{props.t('Subjects')}</span>
            </Link>
          </li>
          )}
           {(userAuthRoutes.some(
            (authRoute) => authRoute.routePath === "chapters"
          ) &&
          <li>
            <Link to="/chapters" className="waves-effect">
              <img className="sidebar-icon" src={chapters} alt="sidebar-icon" />
              <span>{props.t('Chapters')}</span>
            </Link>
          </li>
           )}
           {(userAuthRoutes.some(
            (authRoute) => authRoute.routePath === "studyguides"
          ) &&
          <li>
            <Link to="/studyguides" className="waves-effect">
              <img className="sidebar-icon" src={studyguides} alt="sidebar-icon" />
              <span>{props.t('Study Guides')}</span>
            </Link>
          </li>
           )}
         {(userAuthRoutes.some(
            (authRoute) => authRoute.routePath === "assessments"
          ) &&
          <li>
            <Link to="/assessments" className="waves-effect">
              <img className="sidebar-icon" src={assessments} alt="sidebar-icon" />
              <span>{props.t('Assessments')}</span>
            </Link>
          </li>
         )}

         {(userAuthRoutes.some(
            (authRoute) => authRoute.routePath === "questions"
          ) &&
          <li>
            <Link to="/questions" className="waves-effect">
              <img className="sidebar-icon" src={questions} alt="sidebar-icon" />
              <span>{props.t('Questions')}</span>
            </Link>
          </li>
         )}
         {(userAuthRoutes.some(
            (authRoute) => authRoute.routePath === "question-tagging"
          ) &&
          <li>
            <Link to="/question-tagging" className="waves-effect">
              <img
                className="sidebar-icon"
                width="22px"
                style={{ opacity: '.8' }}
                src={questiontagging}
                alt="sidebar-icon"
              />
              <span>{props.t('Questions Tagging')}</span>
            </Link>
          </li>
         )}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withNamespaces()(SidebarContent));
