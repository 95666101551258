import React, { useMemo } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Row, Col, Card, CardBody, Button, Modal } from 'reactstrap';

import Loader from '../../shared/loader/loader';
import { appConfig } from '../../config/config';
import { TinyImageUpload } from '../../helpers/general/services/image-service';
import Dropzone from 'react-dropzone';
import Select from 'react-select';

const MarkingModal = (props) => {
  const {
    modalOpen,
    toggleModal,
    question,
    subQuestions,
    loader,
    handleAcceptedFiles,
    dropzoneElement,
    selectedQuestionType,
    setSelectedQuestionType,
    uploadImage,
    uploadedMarkingScheme
  } = props;

  const key = subQuestions && Object.keys(subQuestions);
  const subKey = subQuestions?.[key]?.subsubQuestions &&  Object.keys(subQuestions?.[key]?.subsubQuestions);
  const renderList = useMemo(() => {
    const newArray = [];
    if (question && question?.body != '') {
      newArray.push({ value: 'Question', label: 'Question' });
    }
    if (subQuestions && subQuestions?.[key] && subQuestions?.[key]?.body != '') {
      newArray.push({ value: 'SubQuestion', label: 'SubQuestion' });
    }
    if (subQuestions?.[key]?.subsubQuestions?.[subKey] && subQuestions?.[key].subsubQuestions?.[subKey]?.body != '') {
      newArray.push({ value: 'SubSubQuestion', label: 'SubSubQuestion' });
    }
    return newArray;
  }, [question, subQuestions,subQuestions?.[key]?.body, subQuestions?.[key]?.subsubQuestions?.[subKey],subQuestions?.[key].subsubQuestions?.[subKey]?.body]);

  return (
    <Modal
      isOpen={modalOpen}
      toggle={() => {
        toggleModal();
      }}
    >
      <Card>
        <CardBody>
          <Loader loading={loader} />
          <Row>
            <Col md="12">
              <h5>Marking Attachment</h5>
            </Col>
            <Col md="12">
              <h6 className="text-secondary">Fill all information below</h6>
            </Col>
            <Col md="12">
              {/* <div>{sampleAnswerModalGetSet.get()}</div> */}
              <Select
                value={selectedQuestionType}
                // isLoading={loader}
                isSearchable
                name="subjectSpeciality"
                placeholder="Select Question type"
                options={renderList}
                className="mb-4"
                onChange={(value) => {
                  setSelectedQuestionType(value);
                }}
              />
              <div style={{ marginLeft: '18%' }}>
                <Dropzone
                  ref={dropzoneElement}
                  multiple={false}
                  onDropRejected={(e) => {
                    //   setImageError('Please select an image file!');
                  }}
                  accept="image/jpeg, image/png, image/svg, .jpeg, .png, .svg"
                  onDrop={(acceptedFiles) => {
                    handleAcceptedFiles(acceptedFiles);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <>
                      {uploadedMarkingScheme ? (
                        <img src={uploadedMarkingScheme} alt="marking scheme" className="img-fluid" />
                      ) : (
                        <div className="dropzone subject-image-dropzone">
                          {/* <Loader loading={imageLoader} /> */}
                          <div className="dz-message needsclick" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="dz-message needsclick subject-icon-dz">
                              <div className="mt-4">
                                <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                              </div>
                              <h5 className="font-size-12">Drop files here or click to upload.</h5>
                              <p className="font-size-10">Minimum file size 512x512, File type PNG.</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </Dropzone>
              </div>
            </Col>
            <Col md="12" className="mt-4">
              <Row className="justify-content-center">
                {!uploadedMarkingScheme && (
                  <Button
                    type="button"
                    onClick={() => {
                      if (
                        selectedQuestionType.value === null ||
                        selectedQuestionType.value === '' ||
                        selectedQuestionType.value === undefined
                      ) {
                        alert('Please select question type');
                      } else {
                        uploadImage();
                      }
                    }}
                    className="agora-button cancel-button border-radius"
                  >
                    Submit
                  </Button>
                )}
                <Button
                  type="button"
                  onClick={() => {
                    toggleModal();
                  }}
                  className="agora-button cancel-button border-radius"
                >
                  Close
                </Button>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Modal>
  );
};

export default MarkingModal;
