import React, {useState, useEffect} from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { fetchRequestWithToken } from '../../service/index';
import { useHistory } from 'react-router-dom';
const AuthRouteMiddleware = ({name: routePath, component: Component, layout: Layout, ...props }) => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  useEffect(() => {
    
    const fetchUserRoles = async () => {
      try {
        if (localStorage.getItem('authUser') && routePath !== undefined) {
          const response = await fetchRequestWithToken({
              url: `roleroute?type=ui&routePath=${routePath}`,
              method: 'GET'
          });
          
         
          // const response = await requestWithToken({
          //   url: `roleroute?type=ui&routePath=${routePath}`,
          //   method: 'GET'
          // });
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const data = await response.json();
          const userAuthRoutes = data.rolePermission;

          const isAuthenticated = localStorage.getItem('authUser') !== null;
          const hasRequiredRole = userAuthRoutes.some(
            (authRoute) => authRoute.routePath === routePath
          );
          if ((!isAuthenticated || !hasRequiredRole)) {
            console.log("Redirecting...")
            history.push({
              pathname: '/users',
              state: { from: props.location }
            });
          }

          setLoading(false); // Set loading to false once data is received
        }else{
          setLoading(false)
        }
      } catch (error) {
        console.error('Error fetching user roles:', error);
        setLoading(false); // Set loading to false in case of an error
      }
    };

    fetchUserRoles();
  }, [props.location, history]); //props.location, history,//userAuthRoutes

    if (loading) {
      // Render a loading spinner or other indicator while data is being fetched
      return <div>Loading...</div>;
    }
    return (
      <Layout>
        <Component {...props} />
      </Layout>
    )
}
export default withRouter(AuthRouteMiddleware);
