export const appConfig = {
    BASE_URL: process.env.REACT_APP_API,
    // BASE_URL: 'https://43b3-124-29-237-250.ngrok.io/dev/api',
    // BASE_URL: 'https://db39-103-217-178-170.ap.ngrok.io/dev/api',
    // BASE_URL: 'https://hquw6i3gyh.execute-api.ap-southeast-1.amazonaws.com/staging/api',

    RecordsPerPage: 15,
    MaxPages: 5,
    S3: {
        bucketName: 'agora-frontend',
        region: 'ap-southeast-1',
        accessKeyId: 'AKIAVVODWIG6F3AU2K2I',
        secretAccessKey: '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'
    },
    S3_markingUpload: {
        bucketName: 'agora-qbank',
        region: 'ap-southeast-1',
        accessKeyId: 'AKIAVVODWIG6F3AU2K2I',
        secretAccessKey: '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'
    },
    tinyRTE: {
        file_picker_types: 'image media',
        plugins:
            'powerpaste importcss searchreplace autolink directionality visualblocks visualchars fullscreen image link media mediaembed table charmap hr nonbreaking advlist lists imagetools textpattern formatpainter quickbars emoticons table',
        toolbar:
            'bold italic underline | fontselect fontsizeselect | superscript subscript | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist | fullscreen | image media link | forecolor backcolor formatpainter removeformat | charmap emoticons | tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol |'
    }
};
