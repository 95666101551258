import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  // Table,
  // Modal,
  // Label,
  // FormGroup,
  // Button,
  // UncontrolledTooltip
} from 'reactstrap';

import Loader from '../../shared/loader/loader';
import { ShowNotification, SetStatusStyle, GetQueryParam } from '../../helpers/general/generalHelpers';
import BackButton from '../../components/Common/BackButton';
// import AvForm from 'availity-reactstrap-validation/lib/AvForm';
// import AvField from 'availity-reactstrap-validation/lib/AvField';
// import AvRadio from 'availity-reactstrap-validation/lib/AvRadio';
// import AvRadioGroup from 'availity-reactstrap-validation/lib/AvRadioGroup';
import { getChapter } from './Services/chapter-service';
// import { Link } from 'react-router-dom';

const ViewChapter = (props) => {
  // const [modal_standard, setmodal_standard] = useState(false);
  const [loader, setLoader] = useState(false);
  // const [learningObjectives] = useState([
  //   {
  //     id: 1,
  //     description:
  //       'Name appropriate apparatus for the measurement of time, temperature, mass and volume, including burettes, pipettes, measuring cylinders and gas syringes'
  //   },
  //   {
  //     id: 2,
  //     description:
  //       'Suggest suitable apparatus, given relevant information, for a variety of simple experiments, including collection of gases and measurement of rates of reaction'
  //   },
  //   {
  //     id: 4,
  //     description:
  //       'Describe paper chromatography and interpret chromatograms including comparison with ‘known’ samples and the use of Rf values'
  //   }
  // ]);
  const [chapter, setChapter] = useState({
    title: '',
    description: '',
    active: true,
    subjectIcon: 'Icon',
    studyLevelId: '',
    syllabusEntries: [],
    createdBy: '',
    createdAt: null,
    updatedAt: null,
    subjectId: {
      _id: '',
      title: ''
    }
  });

  useEffect(() => {
    if (props.location.search) {
      setLoader(true);
      const chapterId = GetQueryParam(props.location.search, 'id');
      getChapter(chapterId)
        .then((response) => {
          const chapter = response.data.chapter;
          setChapter(chapter);
        })
        .catch((error) => {
          const message =
            error && error.response && error.response.data
              ? error.response.data.message
              : 'There was an error fetching chapter information, please try again!';
          ShowNotification({ type: 'error', message });
          throw error;
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [props.location.search]);

  // function editSyllabus(syllabusId) {
  //   // setSyllabusId(syllabusId);
  //   if (!syllabusId) {
  //     toggleSyllabusModal();
  //   }
  //   toggleSyllabusModal();
  // }

  // function updateSyllabus(event, values) {}

  // function toggleSyllabusModal() {
  //   setmodal_standard(!modal_standard);
  //   removeBodyCss();
  // }

  // function removeBodyCss() {
  //   document.body.classList.add('no_padding');
  // }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="relative">
          <Loader loading={loader} />
          {/* Render Page title */}
          <BackButton text="Back to chapters" url="/chapters" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="12 subject-info-section pb-0">
                      <div className="subject-info ml-0">
                        <h4 className="font-size-18 text-capitalize">
                          {chapter.title}{' '}
                          <span className="subject-status">
                            <SetStatusStyle status={chapter.active ? 'active' : 'inactive'} />
                          </span>
                        </h4>
                        <h5 className="font-size-14">{chapter.subjectId.title}</h5>
                        <p className="font-size-12">{chapter.description}</p>
                      </div>
                      <div className="clearfix"></div>
                    </Col>
                    <Col md="12 subject-creator-info-section font-size-13">
                      <div className="subject-creator-info">
                        <p>Created by: {chapter.createdBy}</p>
                        <p>
                          Created at:{' '}
                          {new Intl.DateTimeFormat('en-GB', {
                            year: 'numeric',
                            month: 'short',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit'
                          }).format(new Date(chapter.createdAt))}
                        </p>
                        <p>
                          Last updated at:{' '}
                          {new Intl.DateTimeFormat('en-GB', {
                            year: 'numeric',
                            month: 'short',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit'
                          }).format(new Date(chapter.updatedAt))}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="12">
                      <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h4 className="mb-0 font-size-18">Learning Objectives</h4>
                        <div className="page-title-right">
                          <Button
                            onClick={(e) => {
                              editSyllabus(null);
                            }}
                            className="btn btn-primary btn-block agora-button border-radius waves-effect waves-light"
                          >
                            + Add New Learning Objective
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <Table className="table-centered table-nowrap table-hover">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" style={{ width: 'calc(100% - 150px)' }}>
                            Title
                          </th>
                          <th scope="col" style={{ width: '150px' }}>
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {learningObjectives.map((lo, i) => (
                          <tr key={'_learningObjective_' + i}>
                            <td>
                              <p className="font-size-14 mb-1">{lo.description}</p>
                            </td>
                            <td>
                              <ul className="list-inline font-size-20 contact-links mb-0">
                                <li className="list-inline-item px-2">
                                  <Button
                                    onClick={(e) => {
                                      editSyllabus(lo.id);
                                    }}
                                    className="agora-action-button font-size-20"
                                    id={'edit-syllabus' + i}
                                  >
                                    <i className="bx bxs-edit-alt"></i>
                                    <UncontrolledTooltip placement="top" target={'edit-syllabus' + i}>
                                      Edit Learning Objective
                                    </UncontrolledTooltip>
                                  </Button>
                                </li>
                                <li className="list-inline-item px-2">
                                  <Link
                                    to={{ pathname: '/learning-details', syllabusInfo: { Id: i } }}
                                    id={'delete-syllabus' + i}
                                  >
                                    <i className="bx bx-trash-alt"></i>
                                    <UncontrolledTooltip placement="top" target={'delete-syllabus' + i}>
                                      Delete Learning Objective
                                    </UncontrolledTooltip>
                                  </Link>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Modal
                      isOpen={modal_standard}
                      toggle={() => {
                        toggleSyllabusModal();
                      }}
                    >
                      <div className="modal-body">
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_standard(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <AvForm
                          className="needs-validation"
                          onValidSubmit={(e, v) => {
                            updateSyllabus(e, v);
                          }}
                        >
                          <h4 className="card-title">Add New Syllabus Entry</h4>
                          <p className="card-title-desc">Fill all information below</p>
                          <Row>
                            <Col md="12">
                              <FormGroup>
                                <Label htmlFor="title">Type</Label>
                                <AvRadioGroup
                                  inline
                                  name="syllabusType"
                                  required
                                  // value={syllabus.type}
                                >
                                  <AvRadio label="Chapter" value="Chapter" />
                                  <AvRadio style={{ marginLeft: '35px' }} label="Assessment" value="Assessment" />
                                </AvRadioGroup>
                              </FormGroup>
                            </Col>
                            <Col md="12" style={{ marginBottom: '20px' }}>
                              <FormGroup>
                                <Label htmlFor="chapterName">Chapter Name</Label>
                                <AvField
                                  name="chapterName"
                                  type="text"
                                  id="chapterName"
                                  validate={{ required: { value: true } }}
                                  placeholder="Enter chapter name"
                                  errorMessage="Please enter chapter name"
                                  // value={syllabus.chapterName}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12" className="agora-form-buttons text-center">
                              <Button
                                onClick={() => {
                                  toggleSyllabusModal();
                                }}
                                className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light"
                              >
                                Cancel
                              </Button>
                              <Button
                                type="submit"
                                className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
                              >
                                Save
                              </Button>
                            </Col>
                          </Row>
                        </AvForm>
                      </div>
                    </Modal>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewChapter;
