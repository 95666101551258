export default function authHeader() {
  const obj = JSON.parse(localStorage.getItem('authUser'));

  if (obj && obj.token) {
    return { Authorization: `Bearer ${obj.token}` };
  } else {
    return {};
  }
}

export function getToken (){
  const obj = JSON.parse(localStorage.getItem('authUser'));
  if (obj && obj.token) {
    return obj.token;
  } else {
    return "";
  }
}