import React from 'react';
import { Row, Col, Progress } from 'reactstrap';
const MobileScreen = (props) => {
  let currTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  return (
    <div>
      <div className="topBar">
        <Row className="font-size-17 p-1 text-white">
          <Col md="6">{currTime}</Col>
          <Col md="6" style={{ textAlign: 'end' }}>
            <i className="fas fa-signal mr-1"></i>
            <i className="fas fa-wifi mr-1"></i>
            <i className="fas fa-battery-full"></i>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md="12">
            <h3 className="text-white ellipsis" style={{ textAlign: 'center', padding: '0px 50px' }}>
              {props.cardHeaderText}
            </h3>
          </Col>
        </Row>
      </div>
      <div className="progressOnScreen">
      <Progress
          className="progress"
          value={(props.currentPage / props.pagesLength) * 100}
          color="primary"
        ></Progress>
        <div className="progressText">
          {props.currentPage}/{props.pagesLength}
        </div>
      </div>

      <div className="screenCard" style={{height: "30rem"}}>
        <Row>
          <Col md="7" style={{padding: '0px 0px 0px 17px' }}>
            <div className="text-muted text-capitalize ellipsis">{props?.cardSubHeaderText}</div>
            <div className="text-muted text-capitalize ellipsis">{props?.cardSubHeaderText2}</div>
          </Col>
          <Col md="5" className="font-size-22" style={{ color: '#196bb5', textAlign: 'end', opacity: '85%' }}>
            <span className="cardIconCircleSm mr-2">
              <i className="bx bxs-edit-alt"></i>
            </span>
            
            <span className="cardIconCircleSm mr-2">
              <i className="bx bx-bookmark"></i>
            </span>

            <span className="cardIconCircleSm mr-2">
              <i className="bx bx-share-alt"></i>
            </span>
          </Col>
        </Row>

        <div
          className="mt-1 mx-1"
          style={{ overflow: 'auto', height: '27rem', fontSize: '12pt' }}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default MobileScreen;
