import React from 'react';
import {
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink
} from 'reactstrap';
import './index.scss';

const QuestionList = (props) => {
  const { questions, activeState, onCLickQuestion, pagination, paginate } = props;
  return (
    <React.Fragment>
      <Col lg="4" className="border-right">
        {questions?.map((item, index) => (
          <div 
            key={index} 
            onClick={(e) => onCLickQuestion(e, index)}
            className={`${activeState === index ? 'question-section-active' : 'question-section'}`}>
            <div className="question-label">
              <div className="question-title ellipsis"> {item?.internalId} - {item.subject?.title} - {item?.studyLevelId} - {item?.type}</div>
              <div className="question-chapter ellipsis"> {item?.chapter?.title} </div>
            </div>
            <div className="question-status">
              {item.studyGuides?.length > 0 ?
                <>
                <i className="bx bxs-check-circle font-size-16"/>
                <div className="text-icon"> Tagged </div>
                </> :
                <>
                <i className="bx bxs-minus-circle font-size-16"/>
                <div className="text-icon"> Untagged </div>
                </> 
              }
            </div>
          </div>
        ))}
        <Row>
          <Col lg="12">
            <Pagination className="pagination pagination-rounded justify-content-center mt-4">
              <PaginationItem disabled={pagination.currentPage === 1}>
                <PaginationLink
                  first
                  onClick={(e) => {
                    paginate(1);
                  }}
                />
              </PaginationItem>
              <PaginationItem disabled={pagination.currentPage === 1}>
                <PaginationLink
                  previous
                  onClick={(e) => {
                    paginate(pagination.currentPage - 1);
                  }}
                />
              </PaginationItem>
              {pagination.pages.map((page, index) => (
                <PaginationItem key={'_page_' + index} active={page === pagination.currentPage}>
                  <PaginationLink
                    onClick={(e) => {
                      paginate(page);
                    }}
                  >
                    {page}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem disabled={pagination.currentPage === pagination.totalPages}>
                <PaginationLink
                  next
                  onClick={(e) => {
                    paginate(pagination.currentPage + 1);
                  }}
                />
              </PaginationItem>
              <PaginationItem disabled={pagination.currentPage === pagination.totalPages}>
                <PaginationLink
                  last
                  onClick={(e) => {
                    paginate(pagination.totalPages);
                  }}
                />
              </PaginationItem>
            </Pagination>
            <div className="records-count text-center">
              <p>
                Showing <strong>{pagination.startIndex + 1}</strong> to{' '}
                <strong>{pagination.endIndex + 1}</strong> of <strong>{pagination.totalItems}</strong> records
              </p>
            </div>
          </Col>
        </Row>
      </Col>
    </React.Fragment>
  );
};

export default QuestionList;
