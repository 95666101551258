import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Lightbox from 'react-awesome-lightbox';
import 'react-awesome-lightbox/build/style.css';
//import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';

import { user, userRoles } from '../../helpers/user/userInfo';
import Loader from '../../shared/loader/loader';
import { updateTask, getQuestionsByIds } from '../../pages/Tasks/Services/task-service';
import { ShowNotification } from '../../helpers/general/generalHelpers';

import expandIcon from '../../assets/images/task/expandIcon.svg';
import nextIcon from '../../assets/images/task/nextIcon.svg';
import prevIcon from '../../assets/images/task/prevIcon.svg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import {  preImageUpload, rteColors, rteFontFamily, setImageAttribute } from '../../components/Utils';
import SpecialCharPicker from '../../components/SpecialCharPicker';
import draftToHtml from '../draftjs-to-html/index';
import htmlToDraft from "html-to-draftjs";

function MarkingSection(props) {
  const [freshStart, setFreshStart] = useState(true);
  const [showOverrideModal, setShowOverrideModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [taskMarks, setTaskMarks] = useState(props.task.marks);
  const [currentMarksAwarded, setCurrentMarksAwarded] = useState(props.task.marks[0].marks || 0);
  const [currentFeedback, setCurrentFeedback] = useState('');
  const [questionEditorState, setQuestionEditorState] = useState(()=>EditorState.createEmpty())
  const [subQuestionEditorState, setSubQuestionEditorState] = useState(()=>EditorState.createEmpty())
  const [subSubQuestionEditorState, setSubSubQuestionEditorState] = useState(()=>EditorState.createEmpty())
  const [feedbackEditorState, setFeedbackEditorState] = useState(()=>EditorState.createEmpty())
  const [questions, setQuestions] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(null);

  const [images, setImages] = useState([]);
  const [currentImage, setCurrentImage] = useState(null);
  const [imageIndex, setImageIndex] = useState(0);
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [isMarkingSchemeOpen, setIsMarkingSchemeOpen] = useState(false);

  const [markingScheme, setMarkingScheme] = useState(null);

  const [showSubmitConfirmationModal, setShowSubmitConfirmationModal] = useState(false);

  const [, update] = useState(0);

  const history = useHistory();

  const forceUpdate = () => update((n) => !n);

  function changeImage(url, indexAdjustment) {
    const index = images.findIndex((image) => image.url === url);

    if (index > -1) {
      if (images[index + indexAdjustment]) {
        images[index].isActive = false;
        images[index + indexAdjustment].isActive = true;
        setCurrentImage(images[index + indexAdjustment]);
        setImageIndex(index + indexAdjustment);
      }
    }
  }

  function selectImage(index) {
    const questionImages = images;
    questionImages.map((image) => (image.isActive = false));
    questionImages[index].isActive = true;
    setImages(questionImages);
    setCurrentImage(images[index]);
    setImageIndex(index);
  }

  useEffect(() => {
    if (props.task !== null || props.task !== undefined) {
      let questionIds = props.task.marks.map((q) => q.questionId);
      getQuestionsByIds(questionIds)
        .then(
          axios.spread((...responses) => {
            let questions = responses.map((r) => r.data.question);
            questions = questions.map((q) => {
              let subquestion = getSubquestion(q.questions.subquestions);
              // let subquestion = q.questions.subquestions;
              let subSubquestion = null;

              if (subquestion === undefined) {
                subquestion = null;
              }

              if (subquestion !== null && subquestion !== undefined) {
                subSubquestion = getSubquestion(getSubquestion(q.questions.subquestions)['subsubquestions']);
                // subSubquestion = q.questions.subquestions['sub-subquestions'];
              }

              if (subSubquestion === undefined) {
                subSubquestion = null;
              }

              return {
                id: q._id,
                question: q.questions,
                subquestion,
                subSubquestion,
                marks: subSubquestion?.marks || subquestion?.marks || q.questions?.marks,
                // markingAttachment: subSubquestion.markingAttachment || subquestion.markingAttachment || q.questions.markingAttachment,
                markingAttachment: q.questions.markingAttachment || subquestion?.markingAttachment || subSubquestion?.markingAttachment
              };
            });
            let index = 0;
            setQuestions(questions);
            setCurrentQuestionIndex(index);
            setCurrentFeedback(props.task.marks[0].feedback);

            let imagesArray = props.task.marks[index].url?.map((imageUrl, i) => ({
              url: imageUrl,
              isActive: i === imageIndex ? true : false
            }));
            setImages(imagesArray);
            setCurrentImage(imagesArray[index]);
            setCurrentQuestion(questions[index]);

            setQuestionEditorState(EditorState.createWithContent(
              ContentState.createFromBlockArray(
                htmlToDraft(questions[index]?.question?.body?questions[index]?.question?.body:"", setImageAttribute)
              )
            ))

            setSubQuestionEditorState(EditorState.createWithContent(
              ContentState.createFromBlockArray(
                htmlToDraft(questions[index]?.subquestion?.body?questions[index]?.subquestion?.body:"", setImageAttribute)
              )
            ))

            setSubSubQuestionEditorState(EditorState.createWithContent(
              ContentState.createFromBlockArray(
                htmlToDraft(questions[index]?.subSubquestion?.body?questions[index]?.subSubquestion?.body:"", setImageAttribute)
              )
            ))

            setFeedbackEditorState(EditorState.createWithContent(
              ContentState.createFromBlockArray(
                htmlToDraft(props?.task?.marks[0]?.feedback?props.task.marks[0].feedback:"", setImageAttribute)
              )
            ))

          })
        )
        .catch((error) => {
          ShowNotification({
            type: 'error',
            message: 'Reload Page: Unable to find Questions for this task'
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function changeQuestion(index) {
    let imagesArray = props.task.marks[index].url?.map((imageUrl, i) => ({
      url: imageUrl,
      isActive: i === imageIndex ? true : false
    }));
    setImages(imagesArray);
    setCurrentImage(imagesArray[0]);
    setImageIndex(0);
    setCurrentQuestion(questions[index]);
  }

  function previousQuestion() {
    if (Number(currentMarksAwarded) >= 0 && Number(currentMarksAwarded) <= Number(currentQuestion.marks)) {
      let prevIndex = currentQuestionIndex - 1;

      if (prevIndex < -1) {
        return;
      }

      updateMarksAndFeedback(currentQuestionIndex, prevIndex).then((updatedTaskMarks) => {
        setCurrentMarksAwarded(updatedTaskMarks[prevIndex].marks || 0);
        setCurrentQuestionIndex(prevIndex);
        let feedback = updatedTaskMarks[prevIndex].feedback;
        changeQuestion(prevIndex);
        setCurrentFeedback(feedback);
        forceUpdate();
      });
      getMarkingScheme();
    } else {
      ShowNotification({
        type: 'error',
        message: 'Invalid marks assigned'
      });
    }
  }

  function nextQuestion() {
    if (Number(currentMarksAwarded) >= 0 && Number(currentMarksAwarded) <= Number(currentQuestion.marks)) {
      let nextIndex = currentQuestionIndex + 1;

      if (nextIndex >= questions.length) {
        return;
      }
      updateMarksAndFeedback(currentQuestionIndex, nextIndex).then((updatedTaskMarks) => {
        setCurrentMarksAwarded(updatedTaskMarks[nextIndex].marks || 0);
        setCurrentQuestionIndex(nextIndex);
        let feedback = updatedTaskMarks[nextIndex].feedback;
        changeQuestion(nextIndex);
        setCurrentFeedback(feedback);
        forceUpdate();
      });
      getMarkingScheme();
    } else {
      ShowNotification({
        type: 'error',
        message: 'Invalid marks assigned'
      });
    }
  }

  // function handleEditorChange(content, editor) {
  //   setCurrentFeedback(content.level.content);
  // }

  function updateMarksAndFeedback(currentIndex, indexToBeUpdated) {
    return new Promise(function (resolve, reject) {
      if (currentMarksAwarded === null || currentMarksAwarded === undefined || currentMarksAwarded === '') {
        alert('Invalid marks awarded');
      }
      let taskMarksCopy = [...taskMarks];
      let updatedObj = {
        ...taskMarksCopy[currentIndex],
        marks: currentMarksAwarded,
        feedback: currentFeedback
      };
      let updatedTaskMarks = taskMarksCopy.map((taskMark, index) =>
        index === currentIndex ? Object.assign({}, updatedObj) : Object.assign({}, taskMark)
      );
      setTaskMarks(updatedTaskMarks);
      resolve(updatedTaskMarks);
    });
  }

  function handleSubmit() {
    updateMarksAndFeedback(currentQuestionIndex).then((updatedTaskMarks) => {
      setLoader(true);
      setShowSubmitConfirmationModal(false);
      let taskData = { status: 'Done', marks: updatedTaskMarks };
      updateTask(props.task._id, taskData)
        .then(() => {
          setLoader(false);
          history.push({
            pathname: '/tasks',
            notification: {
              show: true,
              message: 'Task Successfully Submitted'
            }
          });
        })
        .catch((error) => {
          setLoader(false);
          alert(error);
        });
    });
  }

  async function startMarking() {
    if (user.user.role.toLowerCase() === userRoles.admin.toLowerCase()) {
      setShowOverrideModal(true);
      getMarkingScheme();
    } else if (user.user.role.toLowerCase() === userRoles.assessor.toLowerCase()) {
      setFreshStart(false);
    }
  }

  function getSubquestion(subquestionObject) {
    if (subquestionObject === null || subquestionObject === undefined) {
      return null;
    }

    let [firstSubquestion] = Object.values(subquestionObject);
    return firstSubquestion;
  }

  const getMarkingScheme = () => {
      if (currentQuestion?.markingAttachment || currentQuestion?.subquestion?.markingAttachment){
        const imgUrl = currentQuestion.markingAttachment || currentQuestion.subquestion.markingAttachment;
        // Extract the URL using a regular expression
        const regex = /<img src="([^"]+)"/;
        const match = imgUrl.match(regex);
        const imageUrl = match && match[1];
        setMarkingScheme(imageUrl);
      }
  }


  if (freshStart === true) {
    return (
      <React.Fragment>
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <div className="my-5 d-flex flex-column align-items-center">
                  <div className="w-25">
                    <Button
                      className="btn btn-primary btn-block agora-button border-radius waves-effect waves-light"
                      onClick={() => startMarking()}
                    >
                      Start Marking
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Admin Override Modal */}
        <Modal isOpen={showOverrideModal} toggle={() => setShowOverrideModal(!showOverrideModal)} centered>
          <ModalHeader> Override Task </ModalHeader>
          <ModalBody> Are you sure you want to override this task ? </ModalBody>
          <ModalFooter>
            <div>
              <Button
                onClick={() => {
                  setShowOverrideModal(false);
                }}
                className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setShowOverrideModal(false);
                  setFreshStart(false);
                  props.startMarkingStatus(true)
                }}
                className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
              >
                Yes, Override
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }

  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            <h5 className="mb-4"> Marking Section </h5>
            <Loader loading={currentQuestion === null || loader} />
            {currentQuestion && (
              <Row>
                <Col md="4" className="border border-left-0 pt-3 pb-3">
                  Question Number: {currentQuestionIndex + 1} out of {questions.length}
                  <div className='question task-content-box mb-4 overflow-auto question-height-container'>
                  {/* <Editor
                      value={currentQuestion.question.body}
                      apiKey={process.env.REACT_APP_TINY_RTE_APIKEY}
                      cloudChannel="5-stable"
                      disabled={true}
                      init={{
                        content_style: 'img { resize: both; max-width: 100%; max-height: 90%; }',
                        height: 300,
                        menubar: 'View',
                        plugins: [
                          'autosave advlist autolink lists link image',
                        ],
                        toolbar:'none'
                      }}
                    /> */}
                    <Editor
                        editorState={questionEditorState}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        toolbar={{
                        image: {
                            uploadEnabled: true,
                            alignmentEnabled: true,
                            uploadCallback: preImageUpload,
                            previewImage: true,
                            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                            defaultSize: {
                            height: 'auto',
                            width: 'auto',
                            },
                        },
                        colorPicker: {
                          colors: rteColors
                        },
                        fontFamily: {
                          options: rteFontFamily,
                        },
                        }}
                        toolbarCustomButtons={[<SpecialCharPicker />]}
                    />
                    </div>

                  {currentQuestion.subquestion !== null && currentQuestion.subquestion.body != '' ? (
                    <div>
                      <p>Subquestion: 1</p>
                      <div className='question task-content-box mb-4 overflow-auto question-height-container'>
                  {/* <Editor
                      value={currentQuestion.subquestion.body}
                      apiKey={process.env.REACT_APP_TINY_RTE_APIKEY}
                      cloudChannel="5-stable"
                      disabled={true}
                      init={{
                        content_style: 'img { resize: both; max-width: 100%; max-height: 100%; }',
                        height: 300,
                        menubar: 'View',
                        plugins: [
                          'autosave advlist autolink lists link image',
                        ],
                        toolbar:'none'
                      }}
                    /> */}
                    <Editor
                        editorState={subQuestionEditorState}
                        // onEditorStateChange={(newEditorState)=>{
                        //   let newContent = questions[currentQuestionIndex]?.question.body
                        //   const htmlContent = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
                        //   newContent = htmlContent
                        //   setContentHtml(newContent)
                        //   setQuestionEditorState(newEditorState)
                        //   forceUpdate();
                        // }}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        toolbar={{
                        image: {
                            uploadEnabled: true,
                            alignmentEnabled: true,
                            uploadCallback: preImageUpload,
                            previewImage: true,
                            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                            defaultSize: {
                            height: 'auto',
                            width: 'auto',
                            },
                        },
                        colorPicker: {
                          colors: rteColors
                        },
                        fontFamily: {
                          options: rteFontFamily,
                        },
                        }}
                        toolbarCustomButtons={[<SpecialCharPicker />]}
                    />
                    </div>
                    </div>
                  ) : null}
                  {currentQuestion.subSubquestion !== null && currentQuestion.subSubquestion.body ? (
                    <div>
                      <p>Sub - Subquestion: 1</p>
                      {/* <Editor
                      value={currentQuestion.subSubquestion.body}
                      apiKey={process.env.REACT_APP_TINY_RTE_APIKEY}
                      cloudChannel="5-stable"
                      disabled={true}
                      init={{
                        content_style: 'img { resize: both; max-width: 100%; max-height: 100%; }',
                        height: 300,
                        menubar: 'View',
                        plugins: [
                          'autosave advlist autolink lists link image',
                        ],
                        toolbar:'none'
                      }}
                    /> */}
                    <Editor
                        editorState={subSubQuestionEditorState}
                        // onEditorStateChange={(newEditorState)=>{
                        //   let newContent = questions[currentQuestionIndex]?.question.body
                        //   const htmlContent = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
                        //   newContent = htmlContent
                        //   setContentHtml(newContent)
                        //   setQuestionEditorState(newEditorState)
                        //   forceUpdate();
                        // }}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        toolbar={{
                        image: {
                            uploadEnabled: true,
                            alignmentEnabled: true,
                            uploadCallback: preImageUpload,
                            previewImage: true,
                            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                            defaultSize: {
                            height: 'auto',
                            width: 'auto',
                            },
                        },
                        colorPicker: {
                          colors: rteColors
                        },
                        fontFamily: {
                          options: rteFontFamily,
                        },
                        }}
                        toolbarCustomButtons={[<SpecialCharPicker />]}
                    />
                      {/* <div
                        dangerouslySetInnerHTML={{
                          __html: currentQuestion.subSubquestion.body
                        }}
                        className="question task-content-box mb-4 overflow-auto"
                      ></div> */}
                    </div>
                  ) : null}
                </Col>
                <Col md="4" className="border border-left-0 border-right-0 pt-3 pb-3">
                  Learner Answer
                  <div className="question task-content-box mb-4 overflow-auto question-height-container">
                    {taskMarks[currentQuestionIndex]?.text}
                  </div>
                  <div className="task-images mt-5">
                    <div className="main-image">
                      {currentImage && <img src={currentImage.url} alt="main img" />}
                      <div className="image-arrows text-center">
                        <span
                          onClick={(e) => {
                            changeImage(currentImage.url, -1);
                          }}
                        >
                          <img src={prevIcon} alt="previous-icon" />
                        </span>
                        <span
                          onClick={(e) => {
                            changeImage(currentImage.url, +1);
                          }}
                        >
                          <img src={nextIcon} alt="next-icon" />
                        </span>
                        <span
                          className="float-right"
                          onClick={(e) => {
                            setIsImageViewerOpen(true);
                          }}
                        >
                          <img className="mx-0" src={expandIcon} alt="expand-icon" />
                        </span>
                      </div>
                    </div>
                    <div className="image-thumbnails">
                      {images.map((image, i) => (
                        <Link
                          key={i}
                          onClick={(e) => {
                            selectImage(i);
                          }}
                        >
                          <img
                            className={image.isActive ? 'active-image' : ''}
                            key={'_image_' + i}
                            src={image.url}
                            alt=""
                          />
                        </Link>
                      ))}
                    </div>
                  </div>
                </Col>
                <Col md="4" className="border border-right-0 pt-3 pb-3">
                  Marking Scheme
                  {/*<div*/}
                  {/*  dangerouslySetInnerHTML={{*/}
                  {/*    __html:*/}
                  {/*      currentQuestion.markingAttachment === '' && currentQuestion.subquestion.markingAttachment === ''*/}
                  {/*        ? '<p>This question has NO marking attachment</p>'*/}
                  {/*        : currentQuestion.markingAttachment || currentQuestion.subquestion.markingAttachment*/}
                  {/*  }}*/}
                  {/*  className="question task-content-box mb-4 overflow-auto"*/}
                  {/*></div>*/}
                  <div className="marking-scheme-images">
                    <div className="marking-scheme-image">
                      {<img src={`${markingScheme}`} alt={'main img'} style={{maxWidth: '100%', height: 'auto'}} />}
                    </div>
                    <span
                      className="float-right"
                      onClick={(e) => {
                        setIsMarkingSchemeOpen(true);
                      }}
                    >
                          <img className="mx-0" src={expandIcon} alt="expand-icon" />
                        </span>
                  </div>
                  Assessor Feedback
                  <div className="question border border-light mb-4">
                    {/* <Editor
                      key={currentQuestionIndex}
                      value={currentFeedback}
                      apiKey={process.env.REACT_APP_TINY_RTE_APIKEY}
                      cloudChannel="5-stable"
                      init={{
                        height: 300,
                        menubar: 'View',
                        plugins: [
                          'fullscreen autosave advlist autolink lists link image',
                          'charmap print preview anchor help',
                          'searchreplace visualblocks code',
                          'insertdatetime media table paste wordcount'
                        ],
                        toolbar:
                          'fullscreen | undo redo | formatselect | bold italic | alignleft aligncenter alignright | \bullist numlist outdent indent | link | help'
                      }}
                      // onChange={handleEditorChange}
                      onEditorChange={(newValue, editor) => setCurrentFeedback(newValue)}
                    /> */}
                    <Editor
                        editorState={feedbackEditorState}
                        onEditorStateChange={(newEditorState)=>{
                          let newContent = currentFeedback
                          const htmlContent = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
                          newContent = htmlContent
                          setCurrentFeedback(newContent)
                          setFeedbackEditorState(newEditorState)
                          forceUpdate();
                        }}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        toolbar={{
                        image: {
                            uploadEnabled: true,
                            alignmentEnabled: true,
                            uploadCallback: preImageUpload,
                            previewImage: true,
                            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                            defaultSize: {
                            height: 'auto',
                            width: 'auto',
                            },
                        },
                        colorPicker: {
                          colors: rteColors
                        },
                        fontFamily: {
                          options: rteFontFamily,
                        },
                        }}
                        toolbarCustomButtons={[<SpecialCharPicker />]}
                    />
                  </div>
                  <h5 className="font-size-15 font-weight-bold">Marks Awarded</h5>
                  <input
                    className="obtained-marks"
                    type="number"
                    name="marks"
                    value={currentMarksAwarded}
                    min="0"
                    max={currentQuestion.marks}
                    onChange={({ target }) => setCurrentMarksAwarded(target.value)}
                  />
                  {` / ${currentQuestion.marks}`}
                </Col>
                <Col md="12" className="pt-3 pb-3 text-right">
                  {currentQuestion.id !== questions[0].id && (
                    <Button
                      type="button"
                      onClick={(e) => {
                        previousQuestion();
                      }}
                      className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light"
                    >
                      Previous
                    </Button>
                  )}
                  {currentQuestion.id === questions[questions.length - 1].id ? (
                    <Button
                      onClick={() => {
                        if (
                          Number(currentMarksAwarded) >= 0 &&
                          Number(currentMarksAwarded) <= Number(currentQuestion.marks)
                        ) {
                          setShowSubmitConfirmationModal(true);
                        } else {
                          ShowNotification({
                            type: 'error',
                            message: 'Invalid marks assigned'
                          });
                        }
                      }}
                      type="submit"
                      className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      onClick={(e) => {
                        nextQuestion();
                      }}
                      className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
                    >
                      Next
                    </Button>
                  )}
                </Col>
              </Row>
            )}

            {/* Image Viewer */}
            {isImageViewerOpen && (
              <Lightbox images={images} startIndex={imageIndex} onClose={() => setIsImageViewerOpen(false)} />
            )}
            {/* Marking Scheme Viewer */}
            {isMarkingSchemeOpen && (
              <Lightbox image={markingScheme} title="Marking Scheme" onClose={() => setIsMarkingSchemeOpen(false)} />
            )}
            {/* Save Confirmation Modal */}
            <Modal
              isOpen={showSubmitConfirmationModal}
              toggle={() => setShowSubmitConfirmationModal(!showSubmitConfirmationModal)}
              centered
            >
              <ModalHeader> Submit Marks </ModalHeader>
              <ModalBody> Are you sure you want to submit this task ? </ModalBody>
              <ModalFooter>
                <div>
                  <Button
                    onClick={() => {
                      setShowSubmitConfirmationModal(false);
                    }}
                    className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      handleSubmit();
                    }}
                    className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
                  >
                    Yes
                  </Button>
                </div>
              </ModalFooter>
            </Modal>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default MarkingSection;
