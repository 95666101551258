import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Input, FormGroup, Label, Modal } from 'reactstrap';
import ReactDevicePreview from 'react-device-preview';
// import { Editor } from '@tinymce/tinymce-react';
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import draftToHtml from '../../components/draftjs-to-html/index';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';
import clsx from 'clsx';

import MobileScreen from './MobileScreen';
import Loader from '../../shared/loader/loader';
// import { appConfig } from '../../config/config';
import { getQuestion, saveQuestion } from './Api/questions';
import PopupAddEditQuestions from './PopupAddEditQuestions';
// import { TinyImageUpload } from '../../helpers/general/services/image-service';
import { GetQueryParam, ShowNotification } from '../../helpers/general/generalHelpers';

import { Buffer } from 'buffer';
import SpecialCharPicker from '../../components/SpecialCharPicker';
import {
  convertToHtmlEntity, preImageUpload, rteColors, rteFontFamily, setImageAttribute, uploadAndGetContent, uploadAndGetHTML
} from '../../components/Utils';

const AddEditMcqQuestion = (props) => {

  Buffer.from('anything', 'base64');
  window.Buffer = window.Buffer || require('buffer').Buffer;

  const [question, setQuestion] = useState({
    loaded: false, type: '', subject: {}, chapter: {}, difficulty: '', body: ''
  });
  //const [sampleAnswer, setSampleAnswer] = useState('');
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [markingEditorState, setMarkingEditorState] = useState(() => EditorState.createEmpty());
  const [sampleEditorState, setSampleEditorState] = useState(() => EditorState.createEmpty());
  const [marking, setMarking] = useState('');
  const [options, setOptions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMarkingOpen, setModalMarkingOpen] = useState(false);
  const [questionModalOpen, setQuestionModalOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [answerEditorState, setAnswerEditorState] = useState(() => EditorState.createEmpty());
  const [modalAnswerOpen, setModalAnswerOpen] = useState(false);
  const [, update] = useState(0);
  const forceUpdate = () => {
    update((n) => !n);
  };
  const [ansIndex, setAnsIndex] = useState(0);
  const optionsNames = ['A', 'B', 'C', 'D', 'E'];

  const history = useHistory();

  useEffect(() => {
    if (props?.location?.state) {
      setQuestion({ loaded: true, ...props?.location?.state });
      setLoader(false);
    } else if (props?.location?.search) {
      const questionId = GetQueryParam(props?.location?.search, 'id');
      setLoader(true);
      getQuestion(questionId)
        .then((response) => {
          const question = response.data.question;
          // let mcqoptions = Object.keys(question.mcqOptions).map((k) => {
          //   let temp1 = question.mcqOptions[k].body;
          //   return `${temp1.substr(0, 3)} ${k.toUpperCase()}. ${temp1.substr(3)}`;
          // });
          // console.log(question.questions?.body?.trim());
          let body = question.questions.body;

          // if (body.split(' ').join('').indexOf('A.') === -1) {
          //   mcqoptions.forEach((element) => {
          //     // repeat error here
          //     body += element;
          //   });
          // }
          setQuestion({
            loaded: true,
            ...question,
            type: question.type,
            subject: question.subject,
            chapter: question.chapter,
            // difficulty: { value: question.difficultyLevel, label: question.difficultyLevel },
            difficulty: question.difficultyLevel,
            body: body
          });
          setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(body ? body : '', setImageAttribute))));
          forceUpdate();
          //setMarking(question.questions?.markingAttachment || '');
          setMarkingEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(question.questions?.markingAttachment ? question.questions?.markingAttachment : '', setImageAttribute))));
          let explanation = question.Feedback?.body.split('Explanation: ');
          //setSampleAnswer(explanation ? explanation[1] : 'No explanation available');
          setSampleEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(explanation ? explanation[1] : 'No explanation available', setImageAttribute))));
          let options = question.mcqOptions ? Object.keys(question.mcqOptions).map((k) => {
            return question.mcqOptions[k];
          }) : [];
          setOptions(options);
        })
        .catch((error) => {
          const message = error && error.response && error.response.data ? error.response.data.message : 'There was an error fetching question information, please try again!';
          ShowNotification({ type: 'error', message });
          console.error(error);
          throw error;
        })
        .finally(() => {
          setLoader(false);
        });
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    forceUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  const handleQuestionEditorChange = (content, editor) => {
    setQuestion({ ...question, body: content });
  };

  const addOption = (event) => {
    if (options.length < 5) {
      setOptions([
        ...options,
        {
          body: '',
          marks: 1,
          answer: false
        }
      ]);
    } else {
      ShowNotification({ type: 'warning', message: 'Maximum 5 options are allowed.' });
    }
  };

  const removeOption = (event) => {
    if (options.length > 0) {
      let newOptions = options.slice(0, options.length - 1);
      setOptions(newOptions);
    } else {
      ShowNotification({ type: 'warning', message: 'Add options first.' });
    }
  };

  function toggleModal() {
    setModalOpen(!modalOpen);
  }

  function toggleMarkingModal() {
    setModalMarkingOpen(!modalMarkingOpen);
  }

  function toggleEditQuestionModal() {
    setQuestionModalOpen(!questionModalOpen);
  }
  function toggleAnswerModal() {
    setModalAnswerOpen(!modalAnswerOpen);
  }

  const handleSaveQuestion = async () => {
    setLoader(true);
    const bodyHtml = await uploadAndGetContent(editorState);
    const sampleHtml = await uploadAndGetContent(sampleEditorState);
    const markingHtml = await uploadAndGetContent(markingEditorState);
    let mcqOptionsBackend = {};
    let optionsNamesSmall = optionsNames.map((opt) => opt.toLowerCase());
    options.forEach((opt, idx) => {
      mcqOptionsBackend[optionsNamesSmall[idx]] = opt;
    });
    for (let key in mcqOptionsBackend) {
      if (mcqOptionsBackend.hasOwnProperty(key)) {
        if (mcqOptionsBackend[key].body.includes('<p>')) {
          mcqOptionsBackend[key].body = mcqOptionsBackend[key].body;
        } else {
          mcqOptionsBackend[key].body = `<p>${mcqOptionsBackend[key].body}</p>`;
        }
        //upload blob and return html content
        const htmlContent = await uploadAndGetHTML(mcqOptionsBackend[key].body)
        mcqOptionsBackend[key].body = htmlContent
      }
    }

    let newQuestion = {
      _id: question._id,
      type: question.type,
      studyLevelId: question.subject.studyLevelId || question.studyLevelId,
      subject: question.subject.id || question.subject._id,
      // chapter: question.chapter.id || question.chapter._id,
      subjectName: question.subject.label || question.subject.title,
      //sampleAnswerAvailable: sampleAnswer !== '',
      sampleAnswerAvailable: editorState.getCurrentContent() !== '',
      difficultyLevel: question.difficulty,
      mcqOptions: mcqOptionsBackend,
      paperNumber: question.paperNumber || '',
      session: question.session || '',
      year: question.year,
      versionNumber: question.versionNumber || '',
      questionNumber: question.questionNumber || '',
      questions: {
        body: bodyHtml, //convertToHtmlEntity(draftToHtml(convertToRaw(editorState.getCurrentContent()))),//question.body,
        sampleAnswer: sampleHtml, //convertToHtmlEntity(draftToHtml(convertToRaw(sampleEditorState.getCurrentContent()))), //sampleAnswer
        markingAttachment: markingHtml //convertToHtmlEntity(draftToHtml(convertToRaw(markingEditorState.getCurrentContent()))),//marking
      }
    };

    console.log(newQuestion);

    saveQuestion(newQuestion, props.history, setLoader);
  };

  const SampleAnswerModal = (
    <Modal
      isOpen={modalOpen}
      toggle={() => {
        toggleModal();
      }}
    >
      <Card>
        <CardBody>
          <Loader loading={loader} />
          <Row>
            <Col md="12">
              <h5>Explanation</h5>
            </Col>
            {/* <Col md="12">
                            <h6 className="text-secondary">Fill all information below</h6>
                        </Col> */}
            <Col md="12">
              {/* <div>{sampleAnswer}</div> */}

              {/* <Editor
                                {...appConfig.tinyRTE}
                                // initialValue={sampleAnswer}
                                value={sampleAnswer}
                                init={{
                                    height: 300,
                                    menubar: false,
                                    images_upload_handler: (blobInfo, success, failure) => {
                                        TinyImageUpload(blobInfo, success, failure, setLoader);
                                    }
                                }}
                                onEditorChange={(content, editor) => {
                                    setSampleAnswer(content);
                                }}
                            /> */}
              <Editor
                editorState={sampleEditorState}
                onEditorStateChange={setSampleEditorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                toolbar={{
                  image: {
                    uploadEnabled: true,
                    alignmentEnabled: true,
                    uploadCallback: preImageUpload,
                    previewImage: true,
                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                    defaultSize: {
                      height: 'auto',
                      width: 'auto'
                    }
                  }, colorPicker: {
                    colors: rteColors
                  }, fontFamily: {
                    options: rteFontFamily
                  }
                  //specialCharPicker: {component: SpecialCharPicker}
                }}
                toolbarCustomButtons={[<SpecialCharPicker />]}
              />
            </Col>
            <Col md="12" className="mt-4">
              <Row className="justify-content-center">
                <Button
                  type="button"
                  onClick={() => {
                    // setSampleAnswer('');
                    toggleModal();
                  }}
                  className="agora-button cancel-button border-radius"
                >
                  Close
                </Button>
                {/*<Button*/}
                {/*  type='submit'*/}
                {/*  className='agora-button save-button border-radius'*/}
                {/*  onClick={() => {*/}
                {/*    toggleModal();*/}
                {/*    ShowNotification({ type: 'success', message: 'Sample answer added.' });*/}
                {/*  }}*/}
                {/*>*/}
                {/*  Save*/}
                {/*</Button>*/}
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Modal>
  );

  const MarkingModal = (
    <Modal
      isOpen={modalMarkingOpen}
      toggle={() => {
        toggleMarkingModal();
      }}
    >
      <Card>
        <CardBody>
          <Loader loading={loader} />
          <Row>
            <Col md="12">
              <h5>Add Marking</h5>
            </Col>
            <Col md="12">
              <h6 className="text-secondary">Fill all information below</h6>
            </Col>
            <Col md="12">
              {/* <Editor
                                {...appConfig.tinyRTE}
                                // initialValue={marking}
                                value={marking}
                                init={{
                                    height: 300,
                                    menubar: false,
                                    images_upload_handler: (blobInfo, success, failure) => {
                                        TinyImageUpload(blobInfo, success, failure, setLoader);
                                    }
                                }}
                                onEditorChange={(content, editor) => {
                                    setMarking(content);
                                }}
                            /> */}
              <Editor
                editorState={markingEditorState}
                onEditorStateChange={setMarkingEditorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                toolbar={{
                  image: {
                    uploadEnabled: true,
                    alignmentEnabled: true,
                    uploadCallback: preImageUpload,
                    previewImage: true,
                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                    defaultSize: {
                      height: 'auto',
                      width: 'auto'
                    }
                  }, colorPicker: {
                    colors: rteColors
                  }, fontFamily: {
                    options: rteFontFamily
                  }
                  //specialCharPicker: {component: SpecialCharPicker}
                }}
                toolbarCustomButtons={[<SpecialCharPicker />]}
              />
            </Col>
            <Col md="12" className="mt-4">
              <Row className="justify-content-center">
                <Button
                  type="button"
                  onClick={() => {
                    setMarking('');
                    toggleMarkingModal();
                  }}
                  className="agora-button cancel-button border-radius"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="agora-button save-button border-radius"
                  onClick={() => {
                    toggleMarkingModal();
                    ShowNotification({ type: 'success', message: 'Marking added.' });
                  }}
                >
                  Save
                </Button>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Modal>
  );
  const CorrectAnswerModal = (
    <Modal
      isOpen={modalAnswerOpen}
      toggle={() => {
        toggleAnswerModal();
      }}
    >
      <Card>
        <CardBody>
          <Loader loading={loader} />
          <Row>
            <Col md="12">
              <h5>Update Correct Answer</h5>
            </Col>
            <Col md="12">
              <h6 className="text-secondary">Fill all information below</h6>
            </Col>
            <Col md="12">
              <Editor
                editorState={answerEditorState}
                onEditorStateChange={setAnswerEditorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                toolbar={{
                  image: {
                    uploadEnabled: true,
                    alignmentEnabled: true,
                    uploadCallback: preImageUpload,
                    previewImage: true,
                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                    defaultSize: {
                      height: 'auto',
                      width: 'auto'
                    }
                  }, colorPicker: {
                    colors: rteColors
                  }, fontFamily: {
                    options: rteFontFamily
                  }
                }}
                toolbarCustomButtons={[<SpecialCharPicker />]}
              />
            </Col>
            <Col md="12" className="mt-4">
              <Row className="justify-content-center">
                <Button
                  type="button"
                  onClick={() => {
                    toggleAnswerModal();
                  }}
                  className="agora-button cancel-button border-radius"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="agora-button save-button border-radius"
                  onClick={ () => {
                    const bodyHtml = draftToHtml(convertToRaw(answerEditorState.getCurrentContent()));//await uploadAndGetContent(answerEditorState);

                    let newOptions = options;
                    newOptions[ansIndex].body = `${bodyHtml}`;
                    setOptions(newOptions);
                    forceUpdate();

                    toggleAnswerModal();
                    //ShowNotification({ type: 'success', message: 'Answer.' });
                  }}
                >
                  Save
                </Button>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Modal>
  );
  return (
    <React.Fragment>
      <Loader loading={loader} />

      <div className="page-content">
        <Container fluid className="relative">
          <Row>
            <Col xs="12">
              <div
                onClick={() => {
                  window.history.back();
                }}
                className="page-title-box d-flex align-items-center justify-content-between"
              >
                <span className="mb-0 font-size-14 back-button">
                  <i className="fa fa-arrow-left"></i> Back to Questions
                </span>
              </div>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <Row className="subject-info-section">
                <Col md="3" className="justify-content-center">
                  <h4 className="card-title mb-3">{question.chapter?.label || question.chapter?.title}</h4>
                </Col>
                <Col className="justify-content-left">
                  <span
                    className={
                      'badge badge-pill badge-soft-success font-size-12 px-3 mr-1 ' +
                      (question.type.toLowerCase() === 'mcq' ? 'text-uppercase' : 'text-capitalize')
                    }
                  >
                    {question.type}
                  </span>
                  <span
                    className={clsx({
                      'badge badge-pill font-size-12 text-capitalize px-3': true,
                      'badge-soft-success': question.difficulty === 'Easy',
                      'badge-soft-info': question.difficulty === 'Medium',
                      'badge-soft-warning': question.difficulty === 'Hard'
                    })}
                  >
                    {question.difficulty}
                  </span>
                </Col>
                <Col className="col text-md-right text-sm-right">
                  <i onClick={toggleEditQuestionModal} className="bx bxs-edit-alt font-size-16 btn p-0"></i>
                </Col>
              </Row>
              <Row>
                <Col md="2" className="subject-creator-info-section font-size-13">
                  <div className="subject-creator-info">
                    <p>Study level: {question?.studyLevelId || '-'}</p>
                    <p>Year: {question.year || '-'}</p>
                  </div>
                </Col>
                <Col md="2" className="subject-creator-info-section font-size-13">
                  <div className="subject-creator-info">
                    <p>Subject: {question.subject?.title || '-'}</p>
                    <p>Session: {question.session || '-'}</p>
                  </div>
                </Col>
                <Col md="2" className="subject-creator-info-section font-size-13">
                  <div className="subject-creator-info">
                    <p>Paper Number: {question.paperNumber || '-'}</p>
                    <p>Version Number: {question.versionNumber || '-'}</p>
                  </div>
                </Col>
                <Col className="subject-creator-info-section font-size-13">
                  <div className="subject-creator-info">
                    <p>Marking Scheme ID: {question.markingSchemeId || '-'}</p>
                    <p>Question ID: {question.internalId || '-'}</p>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row>
                <Col md="12" className="justify-content-center">
                  <h4 className="card-title mb-3">Question Detail</h4>
                </Col>
              </Row>
              <Row className="section-top">
                <Col md="6" className="text-center section-right">
                  <ReactDevicePreview device="iphone8" scale="0.7">
                    <div className="device-content">
                      <MobileScreen
                        mcq
                        cardHeaderText={question.subject?.label || question.subject?.title || '-'}
                        cardSubHeaderText={question.chapter?.label || question.chapter?.title || '-'}
                        setOptions={setOptions}
                        options={options}
                        forceUpdate={forceUpdate}
                      >
                        {parse(question.body || '')}
                      </MobileScreen>
                    </div>
                  </ReactDevicePreview>
                </Col>
                <Col md="6">
                  <h5 className="my-2 text-black-50">Question</h5>
                  {question.loaded && (
                    // <Editor
                    //     {...appConfig.tinyRTE}
                    //     // initialValue={question.body}
                    //     value={question.body}
                    //     init={{
                    //         height: 500,
                    //         menubar: false,
                    //         images_upload_handler: (blobInfo, success, failure) => {
                    //             TinyImageUpload(blobInfo, success, failure, setLoader);
                    //         }
                    //     }}
                    //     onEditorChange={handleQuestionEditorChange}
                    // />
                    <Editor
                      editorState={editorState}
                      // onEditorStateChange={setEditorState}
                      onEditorStateChange={(newEditorState) => {
                        if (question.body !== undefined) {
                          question.body = '';
                        }
                        let newQuestion = question;
                        const htmlContent = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
                        newQuestion.body = htmlContent;
                        setQuestion(newQuestion);
                        setEditorState(newEditorState);
                        forceUpdate();
                      }}
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      toolbarClassName="toolbar-class"
                      toolbar={{
                        image: {
                          uploadEnabled: true,
                          alignmentEnabled: true,
                          uploadCallback: preImageUpload,
                          previewImage: true,
                          inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                          defaultSize: {
                            height: 'auto',
                            width: 'auto'
                          }
                        }, colorPicker: {
                          colors: rteColors
                        }, fontFamily: {
                          options: rteFontFamily
                        }
                      }}
                      toolbarCustomButtons={[<SpecialCharPicker />]}
                    />
                  )}
                  {options.map((option, idx) => {
                    const result = option.body;
                    console.log(option)
                    return (
                      <div
                        key={'option_' + idx}
                        style={{ flexDirection: 'row', display: 'flex', marginTop: 30, width: '100%' }}
                      >
                        <h5 className="my-2 text-black-50">Option {optionsNames[idx]}</h5>
                        <span className="custom-control custom-checkbox mt-1">
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                id="checkbox2"
                                checked={option.answer}
                                onChange={() => {
                                  let newOptions = options;
                                  newOptions[idx].answer = !newOptions[idx].answer;
                                  setOptions(newOptions);
                                  forceUpdate();
                                }}
                              />{' '}
                              Correct Answer
                            </Label>
                          </FormGroup>
                        </span>
                        <Button type="button" color="link" className="btn btn-link waves-effect mr-4" onClick={()=>{
                          setAnsIndex(idx);
                          setAnswerEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(result || '', setImageAttribute))));
                          toggleAnswerModal();
                        }}>
                            <i className="bx font-size-20 bx-refresh"></i>
                            <span style={{ verticalAlign: 'top' }} className="font-size-15">
                              {' '}
                              Update{' '}
                            </span>
                          </Button>
                        
                      </div>
                    );
                  })}
                  <br />
                  <Button type="button" color="link" className="btn btn-link waves-effect mr-4" onClick={addOption}>
                    <i className="bx font-size-20 bxs-plus-square"></i>
                    <span style={{ verticalAlign: 'top' }} className="font-size-15">
                      {' '}
                      Add Option{' '}
                    </span>
                  </Button>
                  <Button type="button" color="link" className="btn btn-link waves-effect mr-4" onClick={toggleModal}>
                    <i
                      className={`bx font-size-20 ${
                        editorState.getCurrentContent() ? 'bx-check-circle' : 'bxs-conversation'
                      }`}
                    ></i>
                    <span style={{ verticalAlign: 'top' }} className="font-size-15">
                      {' '}
                      Explanation{' '}
                    </span>
                  </Button>
                  <Button
                    type="button"
                    color="link"
                    className="btn btn-link waves-effect mr-4"
                    onClick={toggleMarkingModal}
                  >
                    <i className={`bx font-size-20 ${marking ? 'bx-badge-check' : 'bxs-badge-check'}`}></i>
                    <span style={{ verticalAlign: 'top' }} className="font-size-15">
                      {' '}
                      Marking{' '}
                    </span>
                  </Button>
                  {options.length > 0 && (
                    <Button
                      type="button"
                      color="link"
                      className="btn btn-link waves-effect mr-4"
                      onClick={removeOption}
                    >
                      <i className="bx font-size-24 bxs-trash text-danger"></i>
                    </Button>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md="12" className="text-right">
                  <Button
                    type="button"
                    onClick={() => {
                      history.goBack();
                    }}
                    className="agora-button cancel-button border-radius"
                  >
                    Cancel
                  </Button>
                  <Button className="agora-button save-button border-radius" onClick={handleSaveQuestion}>
                    Save
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
      {SampleAnswerModal}
      {MarkingModal}
      {CorrectAnswerModal}
      <PopupAddEditQuestions
        edit
        show={questionModalOpen}
        toggleModal={toggleEditQuestionModal}
        question={question}
        setQuestion={setQuestion}
      />
    </React.Fragment>
  );
};

export default AddEditMcqQuestion;
