import React, { useState, useEffect, useRef } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  Row,
  FormGroup,
  Label,
  Col,
  Button
} from 'reactstrap';
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';

import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import Loader from '../../../shared/loader/loader';
import { getListSubjects } from '../../../service/subject';
import { ShowNotification } from '../../../helpers/general/generalHelpers';
import { updateUser, getUser } from '../../../pages/Users/Services/user-service';
import { IsSquareImage, UploadImage } from '../../../helpers/general/services/image-service';

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const profileImage = useRef(null);
  const [loader, setLoader] = useState(false);
  const [file, setFile] = useState(null);
  const [imageError, setImageError] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState();

  const [userImage, setUserImage] = useState({
    name: '',
    url: ''
  });
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    bankName: '',
    accountNumber: '',
    accountHolderName: '',
    profileImage: '',
    email: ''
  });
  const [profileModal, setProfileModal] = useState(false);
  const [bankInfoModal, setBankInfoModal] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = JSON.parse(localStorage.getItem('authUser'));
      setUser(obj.user);
      setUserImage({
        name: obj.user.firstName,
        url: obj.user.profileImage
      });
    } else {
      props.history.push('/logout');
    }
  }, [props.success, props.history]);

  const handleGetSubjets = () => {
    getListSubjects({ pageSize: 100, filters: { active: true}})
      .then((response) => {
        const subjList = response.data.subjects.map((subj) => {
          return { ...subj, id: subj._id, value: subj.title, label: subj.title};
        });
        setSubjects(subjList);
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
  }

  const openProfilePopup = () =>{
    setProfileModal(true);
    handleGetSubjets();
    getUserInformation();
  }

  const toggleProfileModal = () => {
    setProfileModal(!profileModal);
  }

  const openBankInfoPopup = () => {
    setBankInfoModal(true);
    getUserInformation();
  }

  const toggleBankInfoModal = () => {
    setBankInfoModal(!bankInfoModal);
  }

  const getUserInformation = () => {
    setLoader(true);
    getUser(user._id)
      .then((response) => {
        setUser(response.data.user);
        setUserImage({
          name: response.data.user.firstName,
          url: response.data.user.profileImage
        });
        const tempSubject = response.data.user?.subjectSpecialties?.[0] || {};
        setSelectedSubject({
          id: tempSubject._id, value: tempSubject.title, label: tempSubject.title
        })
      })
      .catch((error) => {
        const message =
          error && error.response && error.response.data
            ? error.response.data.message
            : 'There was an error fetching user information, please try again!';
        ShowNotification({ type: 'error', message });
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
  }

  const updateUserInformation = (event, values) =>  {
    setLoader(true);
    if (user.profileImage) {
      values.profileImage = user.profileImage;
    }
    values.subjectSpecialties = [selectedSubject]
    updateUser(user._id, values)
      .then((response) => {
        const updatedUser = response.data.user;
        const userLocalInfo = JSON.parse(localStorage.getItem('authUser'));
        userLocalInfo.user.bankName = updatedUser.bankName;
        userLocalInfo.user.accountNumber = updatedUser.accountNumber;
        userLocalInfo.user.accountHolderName = updatedUser.accountHolderName;
        userLocalInfo.user.profileImage = updatedUser.profileImage;
        localStorage.setItem('authUser', JSON.stringify(userLocalInfo));
        setUser(updatedUser);
        ShowNotification({ type: 'success', message: response.data.message });
      })
      .catch((error) => {
        const message =
          error && error.response && error.response.data
            ? error.response.data.message
            : 'There was an error updating user, please try again!';
        ShowNotification({ type: 'error', message });
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
  }

  const triggerProfilePicUpload = () => {
    profileImage.current.open();
  }

  const handleFileUpload = (files) => {
    if (files.length) {
      files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      const file = files[0];
      setFile(file);
    }
  }

  useEffect(() => {
    if (file) {
      setImageError('');
      IsSquareImage(file).then((isSquare) => {
        if (isSquare) {
          setLoader(true);
          UploadImage(file, 'users')
            .then((result) => {
              setUserImage({
                name: user.firstName,
                url: file.preview
              });
              setUser({
                ...user,
                profileImage: result.location
              });
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              setFile(null);
              setLoader(false);
            });
        } else {
          setImageError('Only square images are allowed!');
        }
      });
    }
  }, [file, user]);

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
          {!userImage.url ? (
            <div className="avatar-xs d-inline-block">
              <span className="avatar-title text-capitalize rounded-circle">{user.firstName.charAt(0)}</span>
            </div>
          ) : (
            <img className="rounded-circle header-profile-user" src={userImage.url} alt={user.firstName} />
          )}
          <span className="d-none d-xl-inline-block ml-2 mr-1 mt-1">{user.firstName}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem
            tag="a"
            onClick={(e) => {
              openProfilePopup();
            }}
          >
            {' '}
            <i className="bx bx-user font-size-16 align-middle mr-1"></i>
            {props.t('Profile')}{' '}
          </DropdownItem>
          <DropdownItem
            tag="a"
            onClick={(e) => {
              openBankInfoPopup();
            }}
          >
            {' '}
            <i className="bx bxs-bank font-size-16 align-middle mr-1"></i>
            {props.t('Bank Info')}{' '}
          </DropdownItem>
          <div className="dropdown-divider"></div>
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
            <span>{props.t('Logout')}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>

      {/* Profile modal */}
      <Modal
        isOpen={profileModal}
        toggle={() => {
          toggleProfileModal();
        }}
      >
        <div className="modal-body overflow-hidden">
          <Loader loading={loader} />
          <button
            type="button"
            onClick={() => {
              setProfileModal(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <AvForm
            className="needs-validation"
            onValidSubmit={(e, v) => {
              updateUserInformation(e, v);
            }}
          >
            <h4 className="card-title mb-3">Profile</h4>
            <Row>
              <Col md="12" className="text-center mb-2">
                {!userImage.url ? (
                  <div className="avatar-xl d-inline-block">
                    <span className="avatar-title text-capitalize rounded-circle">{user.firstName.charAt(0)}</span>
                  </div>
                ) : (
                  <img className="rounded-circle profile-image" src={userImage.url} alt={user.firstName} />
                )}
                <Dropzone
                  ref={profileImage}
                  multiple={false}
                  accept="image/jpeg, image/png, image/svg, .jpeg, .png, .svg"
                  onDrop={(acceptedFiles) => {
                    handleFileUpload(acceptedFiles);
                  }}
                  onDropRejected={(e) => {
                    setImageError('Please select an image file!');
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone d-none subject-image-dropzone">
                      <div className="dz-message needsclick" {...getRootProps()}>
                        <input {...getInputProps()} />
                      </div>
                    </div>
                  )}
                </Dropzone>
                <div className="mt-1">
                  <Button
                    className="change-image-button"
                    onClick={(e) => {
                      triggerProfilePicUpload();
                    }}
                  >
                    <i className="bx bx-reset"></i> {userImage.url ? 'Update' : 'Add'} Profile Picture
                  </Button>
                </div>
                <p className="text-danger mt-1">{imageError}</p>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label htmlFor="firstName">First Name</Label>
                  <AvField
                    name="firstName"
                    type="text"
                    id="firstName"
                    validate={{ required: { value: true } }}
                    placeholder="Enter first name"
                    errorMessage="Please enter first name"
                    value={user.firstName}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label htmlFor="lastName">Last Name</Label>
                  <AvField
                    name="lastName"
                    type="text"
                    id="lastName"
                    validate={{ required: { value: true } }}
                    placeholder="Enter last name"
                    errorMessage="Please enter last name"
                    value={user.lastName}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="email">Email Address</Label>
                  <AvField
                    name="email"
                    type="text"
                    id="email"
                    disabled={true}
                    style={{ backgroundColor: '#eeeeee', cursor: 'not-allowed' }}
                    value={user.email}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="subjectSpeciality">Subject Speciality</Label>
                  <Select
                    value={selectedSubject}
                    isLoading={loader}
                    isSearchable
                    name="subjectSpeciality"
                    placeholder="Enter subject speciality"
                    options={subjects}
                    onChange={(value) => {
                      setSelectedSubject(value)
                    }}
                  />
                </FormGroup>
              </Col>
            </Row><br/> <br/> <br/>
            <Row>
              <Col lg="12" className="mt-1 text-center">
                <Button
                  onClick={() => {
                    toggleProfileModal();
                  }}
                  className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
                >
                  Save
                </Button>
              </Col>
            </Row>
            <br/>
          </AvForm>
        </div>
      </Modal>
      {/* Bank info modal */}
      <Modal
        isOpen={bankInfoModal}
        toggle={() => {
          toggleBankInfoModal();
        }}
      >
        <div className="modal-body overflow-hidden">
          <Loader loading={loader} />
          <button
            type="button"
            onClick={() => {
              setBankInfoModal(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <AvForm
            className="needs-validation"
            onValidSubmit={(e, v) => {
              updateUserInformation(e, v);
            }}
          >
            <h4 className="card-title mb-3">Bank Account Information</h4>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="bankName">Bank Name</Label>
                  <AvField
                    name="bankName"
                    type="text"
                    id="bankName"
                    validate={{ required: { value: true } }}
                    placeholder="Enter bank name"
                    errorMessage="Please enter bank name"
                    value={user.bankName}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="accountNumber">Account Number</Label>
                  <AvField
                    name="accountNumber"
                    type="text"
                    id="accountNumber"
                    validate={{ required: { value: true } }}
                    placeholder="Enter account number"
                    errorMessage="Please enter an account number"
                    value={user.accountNumber}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="accountHolderName">Account Holder Name</Label>
                  <AvField
                    name="accountHolderName"
                    type="text"
                    id="accountHolderName"
                    validate={{ required: { value: true } }}
                    placeholder="Enter account holder name"
                    errorMessage="Please enter account holder name"
                    value={user.accountHolderName}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12" className="text-center mt-1">
                <Button
                  onClick={() => {
                    toggleBankInfoModal();
                  }}
                  className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light"
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </AvForm>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(connect(mapStatetoProps, {})(withNamespaces()(ProfileMenu)));
