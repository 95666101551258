import S3 from 'react-aws-s3';
import { appConfig } from '../../../config/config';

const config = {
  bucketName: appConfig.S3.bucketName,
  region: appConfig.S3.region,
  accessKeyId: appConfig.S3.accessKeyId,
  secretAccessKey: appConfig.S3.secretAccessKey
};

const config_uploadMarking = {
  bucketName: appConfig.S3_markingUpload.bucketName,
  region: appConfig.S3_markingUpload.region,
  accessKeyId: appConfig.S3_markingUpload.accessKeyId,
  secretAccessKey: appConfig.S3_markingUpload.secretAccessKey
};

function IsSquareImage(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      // file is loaded
      const img = new Image();
      img.onload = () => {
        resolve(img.width === img.height);
      };
      img.src = fileReader.result;
    };

    fileReader.readAsDataURL(file);
    console.log(fileReader);
  });
}

function UploadImage(file, folderName) {
  config.dirName = folderName;
  const ReactS3 = new S3(config);
  const fileName = `${folderName}-${Date.now()}`;
  return ReactS3.uploadFile(file, fileName);
}

function UploadMarkingImage(file, folderName) {
  config_uploadMarking.dirName = folderName;
  const ReactS3 = new S3(config_uploadMarking);
  const fileName = `${folderName}-${Date.now()}`;
  return ReactS3.uploadFile(file, fileName);
}

function DeleteImage(fileName, folderName) {
  config.dirName = folderName;
  const ReactS3 = new S3(config);
  return ReactS3.deleteFile(fileName);
}

function GetFileName(url, folderName) {
  const stringParts = url.split(`${folderName}/`);
  return stringParts[stringParts.length - 1];
}

function TinyImageUpload(blobInfo, success, failure, setLoader, folderName='images') {
  setLoader(true);
  let data = new FormData();
  data.append('file', blobInfo.blob(), blobInfo.filename());
  UploadImage(blobInfo.blob(), folderName)
    .then(function (res) {
      success(res.location);
    })
    .catch(function (err) {
      failure('HTTP Error: ' + err.message);
    })
    .finally(() => {
      setLoader(false);
    });
}

export { IsSquareImage, GetFileName, UploadImage, DeleteImage, TinyImageUpload,UploadMarkingImage };
