import axios from "axios";
import authHeader from "../../../helpers/jwt-token-access/auth-token-header";
import {
  ShowNotification,
  GetQueryParam,
  GetFilterString,
} from "../../../helpers/general/generalHelpers";
import { appConfig } from "../../../config/config";



function saveRole(values, history, setLoader) {
  const roleId = GetQueryParam(history.location.search, "id");
  console.log(roleId);
  let type = "post";
  let url = "/role";
  if (roleId) {
    type = "put";
    url = "/role/" + roleId;
  }

  axios[type](url, values, { headers: authHeader() })
    .then((data) => {
      
      
    })
    .catch((error) => {
      console.log(error.response);
      const message =
        error && error.response && error.response.data
          ? error.response.data.message
          : "There was an error adding user, please try again!";
      ShowNotification({ type: "error", message });
      throw error;
    })
    .finally(() => {
      setLoader(false);
    });
}

function postrole(data,history,type){
  ShowNotification({ type: "success", message: data.message });
  const messageType = type === "post" ? "added" : "updated";
  history.push({
    pathname: "/role",
    notification: {
      show: true,
      message: data.message || `Role has been ${messageType} successfully!`,
      title: `Role ${messageType} successfully!`,
    },
  });

}
function updateRole(roleId, roleData) {
  return axios.put(`/role/${roleId}`, roleData, { headers: authHeader() });
}

function getRoles(pageNumber, filters, pageSize = appConfig.RecordsPerPage) {
  let filterString = GetFilterString(filters);
  return axios.get(
    `/role?pageNumber=${pageNumber}&pageSize=${pageSize}${filterString}`,
    { headers: authHeader() }
  );
}

function getAllRoles() {
  return axios.get(`/role`, {
    headers: authHeader(),
  });
}

function getRole(id) {
  return axios.get("/role/" + id, {
    headers: authHeader(),
  });
}

function getRolePrivileges(roleId){
  return axios.get(`/roleroute?type=ui&roleId=${roleId}`, {
    headers: authHeader(),
  });
}
function updateRolePrivileges(roleId, type, roleData) {
  return axios.patch(`/roleroute?id=${roleId}&type=${type}`, roleData, { headers: authHeader() });
}
function getRoleAPIPrivileges(roleId){
  return axios.get(`/roleroute/api/${roleId}`, {
    headers: authHeader(),
  });
}


export {
  saveRole,
  getRoles,
  getRole,
  getAllRoles,
  updateRole,
  getRolePrivileges,
  updateRolePrivileges,
  getRoleAPIPrivileges
};
