import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
  Label,
  Button,
} from "reactstrap";

import PageTitle from "../../components/Common/PageTitle";
import {
  SetStatusStyle,
  ShowNotification,
  ObjectHasValues,
  GetQueryParam,
} from "../../helpers/general/generalHelpers";
import Loader from "../../shared/loader/loader";
import {
  getPaginationInformation,
  initialPaginationObject,
} from "../../helpers/general/pagination";
import { getAllReferralsById } from "../Referrals/Services/users-referrals-service";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvField from "availity-reactstrap-validation/lib/AvField";

const ViewAllReferrals = (props) => {
  const [pagination, setPagination] = useState(initialPaginationObject);
  const [currentPage, setCurrentPage] = useState(1);
  const filterForm = useRef(null);
  const [filters, setFilters] = useState(null);
  const [users, setUsers] = useState({
    users: [],
  });
  const [loader, setLoader] = useState(true);
  const { user } = props.location.state;
  useEffect(() => {
    setLoader(true);
    const userId = GetQueryParam(props.location.search, "id");

    getAllReferralsById(userId, currentPage, filters)
      .then((response) => {
        const users = response.data.users;
        setUsers({ users });
        setPagination(
          getPaginationInformation(response.data.Count, currentPage)
        );
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
  }, [currentPage, filters]);

  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
  }

  function handleFilters(event, values) {
    if (ObjectHasValues(values)) {
      setFilters(values);
    }
  }

  function clearFilters() {
    filterForm.current.reset();
    setFilters(null);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="relative">
          <Loader loading={loader} />

          {/* Render Page title */}
          {/* <PageTitle title="Referrals" /> */}
          <Row>
            <Col xs="12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <div className="subject-icon">
                  {!user?.profileImage || user?.profileImage === "Icon" ? (
                    <div className="avatar-lg">
                      <span className="avatar-title text-capitalize rounded-circle">
                        {user.firstName.charAt(0)}
                      </span>
                    </div>
                  ) : (
                    <div>
                      <img
                        className="img-responsive"
                        src={user.profileImage}
                        alt={user.firstName}
                      />
                    </div>
                  )}
                </div>
                <div className="subject-info">
                  <h4 className="font-size-18 text-capitalize">
                    {user.firstName + " " + user.lastName}
                    <span className="subject-status">
                      <SetStatusStyle status={user.status} />
                    </span>
                  </h4>
                  <h5 className="text-capitalize font-weight-normal font-size-14">
                    {user.role === "contenteditor"
                      ? "Content Editor"
                      : user.role}
                  </h5>
                  <p className="font-size-12 mb-0">{user.email}</p>
                  <p className="font-size-12">{user.mobileNumber}</p>
                  <div className="page-title-right"></div>
                </div>
              </div>
              {/* <div className="clearfix"></div> */}
            </Col>
          </Row>

          <PageTitle
            title="Referrals"
            button
            name="Go Back to Users"
            url="/users"
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table-centered table-nowrap table-hover">
                      <thead className="thead-light font-size-15">
                        <tr>
                          <th
                            scope="col"
                            className="text-center"
                            style={{ width: "70px" }}
                          >
                            #
                          </th>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Referral Code</th>
                          <th scope="col">Status</th>
                          <th scope="col">Last Updated</th>
                          <th scope="col" className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="agora-lato">
                        {users.users.map((user, i) => (
                          <tr key={"_user_" + i}>
                            <td className="text-center">
                              {!user.profileImage ? (
                                <div className="avatar-xs d-inline-block">
                                  <span className="avatar-title rounded-circle">
                                    {user.firstName.charAt(0)}
                                  </span>
                                </div>
                              ) : (
                                <div>
                                  <img
                                    className="rounded-circle avatar-xs"
                                    src={user.profileImage}
                                    alt={user.firstName}
                                  />
                                </div>
                              )}
                            </td>
                            <td>
                              <h5 className="font-size-14 mb-1">
                                <Link
                                  to={{
                                    pathname: "/profile",
                                    search: "?id=" + user._id,
                                  }}
                                  className="text-dark"
                                >
                                  {user.firstName + " " + user.lastName}
                                </Link>
                              </h5>
                              <p className="text-muted mb-0 text-capitalize">
                                {user.role === "contenteditor"
                                  ? "Content Editor"
                                  : user.role}
                              </p>
                            </td>
                            <td>{user.email}</td>
                            <td>{user.referralId?.Code}</td>
                            <td>
                              <div>
                                <SetStatusStyle status={user.status} />
                              </div>
                            </td>
                            <td>
                              {new Intl.DateTimeFormat("en-GB", {
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              }).format(new Date(user.updatedAt))}
                            </td>
                            <td className="text-center">
                              <ul className="list-inline font-size-20 contact-links mb-0">
                                <li className="list-inline-item px-2">
                                  <Link
                                    to={{
                                      pathname: "/profile",
                                      search: "?id=" + user._id,
                                    }}
                                    id={"profile" + user._id}
                                  >
                                    <i className="bx bx-show-alt"></i>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={"profile" + user._id}
                                    >
                                      View
                                    </UncontrolledTooltip>
                                  </Link>
                                </li>
                                <li className="list-inline-item px-2">
                                  <Link
                                    to={{
                                      pathname: "/user",
                                      search: "?id=" + user._id,
                                    }}
                                    id={"edit-user" + user._id}
                                  >
                                    <i className="bx bxs-edit-alt"></i>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={"edit-user" + user._id}
                                    >
                                      Edit
                                    </UncontrolledTooltip>
                                  </Link>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <Row>
                    <Col lg="12">
                      <Pagination className="pagination pagination-rounded justify-content-center mt-4">
                        <PaginationItem disabled={pagination.currentPage === 1}>
                          <PaginationLink
                            first
                            onClick={(e) => {
                              paginate(1);
                            }}
                          />
                        </PaginationItem>
                        <PaginationItem disabled={pagination.currentPage === 1}>
                          <PaginationLink
                            previous
                            onClick={(e) => {
                              paginate(pagination.currentPage - 1);
                            }}
                          />
                        </PaginationItem>
                        {pagination.pages.map((page, index) => (
                          <PaginationItem
                            key={"_page_" + index}
                            active={page === pagination.currentPage}
                          >
                            <PaginationLink
                              onClick={(e) => {
                                paginate(page);
                              }}
                            >
                              {page}
                            </PaginationLink>
                          </PaginationItem>
                        ))}
                        <PaginationItem
                          disabled={
                            pagination.currentPage === pagination.totalPages
                          }
                        >
                          <PaginationLink
                            next
                            onClick={(e) => {
                              paginate(pagination.currentPage + 1);
                            }}
                          />
                        </PaginationItem>
                        <PaginationItem
                          disabled={
                            pagination.currentPage === pagination.totalPages
                          }
                        >
                          <PaginationLink
                            last
                            onClick={(e) => {
                              paginate(pagination.totalPages);
                            }}
                          />
                        </PaginationItem>
                      </Pagination>
                      <div className="records-count text-center">
                        <p>
                          Showing <strong>{pagination.startIndex + 1}</strong>{" "}
                          to <strong>{pagination.endIndex + 1}</strong> of{" "}
                          <strong>{pagination.totalItems}</strong> records
                        </p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewAllReferrals;
