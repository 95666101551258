import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
  Label,
  Button
} from 'reactstrap';

import PageTitle from '../../components/Common/PageTitle';
import { SetStatusStyle, ShowNotification, ObjectHasValues } from '../../helpers/general/generalHelpers';
import Loader from '../../shared/loader/loader';
import { getSubjects } from './Services/subject-service';
import { getPaginationInformation, initialPaginationObject } from '../../helpers/general/pagination';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvField from 'availity-reactstrap-validation/lib/AvField';

const SubjectList = (props) => {
  const [pagination, setPagination] = useState(initialPaginationObject);
  const [currentPage, setCurrentPage] = useState(1);
  const filterForm = useRef(null);
  const [filters, setFilters] = useState(null);
  const [subjects, setSubjects] = useState([]);
  const [loader, setLoader] = useState(true);

  if (props.location.notification && props.location.notification.show) {
    const notification = props.location.notification;
    ShowNotification({
      type: 'success',
      message: notification.message
    });
    props.location.notification = null;
  }

  useEffect(() => {
    setLoader(true);
    getSubjects(currentPage, filters)
      .then((response) => {
        setSubjects(response.data.subjects);
        setPagination(getPaginationInformation(response.data.Count, currentPage));
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
  }, [currentPage, filters]);

  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
  }

  function handleFilters(event, values) {
    if (ObjectHasValues(values)) {
      values.active = values.active === 'active' ? true : values.active === 'inactive' ? false : '';
      setFilters(values);
    } else {
      setFilters(null);
    }
  }

  function clearFilters() {
    filterForm.current.reset();
    setFilters(null);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="relative">
          <Loader loading={loader} />

          {/* Render Page title */}
          <PageTitle
            title="Subjects"
            button
            name="+ Add New Subject"
            url="/subject"
          />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={filterForm}
                    onValidSubmit={(e, v) => {
                      handleFilters(e, v);
                    }}
                  >
                    <Row>
                      <Col className="col-sm-auto">
                        <FormGroup>
                          <Label htmlFor="title">Title</Label>
                          <AvField
                            name="title"
                            placeholder="Type title to search"
                            type="text"
                            className="form-control filter-field"
                            id="title-filter"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-auto">
                        <FormGroup>
                          <Label htmlFor="study-level-filter">Study Level</Label>
                          <AvField
                            type="select"
                            name="studyLevelId"
                            id="study-level-filter"
                            className="form-control filter-field"
                          >
                            <option value="">Select Study Level</option>
                            <option value="O Level">O Level</option>
                            <option value="A Level">A Level</option>
                          </AvField>
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-auto">
                        <FormGroup>
                          <Label htmlFor="status-filter">Status</Label>
                          <AvField type="select" name="active" id="status-filter" className="form-control filter-field">
                            <option value="">Select Study Status</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </AvField>
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-auto agora-filter-buttons">
                        <Button
                          type="submit"
                          className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
                        >
                          Filter
                        </Button>
                        <Button
                          type="button"
                          onClick={clearFilters}
                          className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light ml-2"
                        >
                          Clear
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>

                  <div className="table-responsive">
                    <Table className="table-centered table-nowrap table-hover">
                      <thead className="thead-light font-size-15">
                        <tr>
                          <th scope="col" style={{ width: '70px' }}></th>
                          <th scope="col">Name</th>
                          <th scope="col">Study Level</th>
                          <th scope="col">Subject Code</th>
                          <th scope="col">Status</th>
                          <th scope="col">Last Update</th>
                          <th scope="col" className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="agora-lato">
                        {subjects.map((subject, i) => (
                          <tr key={'_subject_' + i}>
                            <td>
                              {!subject.subjectIcon || subject.subjectIcon === 'Icon' ? (
                                <div className="avatar-xs">
                                  <span className="avatar-title rounded-circle">{subject.title.charAt(0)}</span>
                                </div>
                              ) : (
                                <div>
                                  <img
                                    className="rounded-circle avatar-xs"
                                    src={subject.subjectIcon}
                                    alt={subject.title}
                                  />
                                </div>
                              )}
                            </td>
                            <td>
                              <h5 className="font-size-14 mb-1">
                                <Link
                                  to={{ pathname: '/subject-details', search: '?id=' + subject._id }}
                                  className="text-dark text-capitalize"
                                >
                                  {subject.title}
                                </Link>
                              </h5>
                            </td>
                            <td>{subject.studyLevelId}</td>
                            <td>{subject.subjectCode}</td>
                            <td>
                              <div>
                                <SetStatusStyle status={subject.active ? 'active' : 'inactive'} />
                              </div>
                            </td>
                            <td>
                              {new Intl.DateTimeFormat('en-GB', {
                                year: 'numeric',
                                month: 'short',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit'
                              }).format(new Date(subject.updatedAt))}
                            </td>
                            <td className="text-center">
                              <ul className="list-inline font-size-20 contact-links mb-0">
                                <li className="list-inline-item px-2">
                                  <Link
                                    to={{ pathname: '/subject-details', search: '?id=' + subject._id }}
                                    id={'view-subject' + subject._id}
                                  >
                                    {/* <Link to={{ pathname: "/subject-details", search: '?id='+subject._id, subjectInfo: { Id: subject._id }}} id={"view-subject" + subject._id}> */}
                                    <i className="bx bx-show-alt"></i>
                                    <UncontrolledTooltip placement="top" target={'view-subject' + subject._id}>
                                      View
                                    </UncontrolledTooltip>
                                  </Link>
                                </li>
                                <li className="list-inline-item px-2">
                                  <Link
                                    to={{ pathname: '/subject', search: '?id=' + subject._id }}
                                    id={'edit-subject' + subject._id}
                                  >
                                    <i className="bx bxs-edit-alt"></i>
                                    <UncontrolledTooltip placement="top" target={'edit-subject' + subject._id}>
                                      Edit
                                    </UncontrolledTooltip>
                                  </Link>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <Row>
                    <Col lg="12">
                      <Pagination className="pagination pagination-rounded justify-content-center mt-4">
                        <PaginationItem disabled={pagination.currentPage === 1}>
                          <PaginationLink
                            first
                            onClick={(e) => {
                              paginate(1);
                            }}
                          />
                        </PaginationItem>
                        <PaginationItem disabled={pagination.currentPage === 1}>
                          <PaginationLink
                            previous
                            onClick={(e) => {
                              paginate(pagination.currentPage - 1);
                            }}
                          />
                        </PaginationItem>
                        {pagination.pages.map((page, index) => (
                          <PaginationItem key={'_page_' + index} active={page === pagination.currentPage}>
                            <PaginationLink
                              onClick={(e) => {
                                paginate(page);
                              }}
                            >
                              {page}
                            </PaginationLink>
                          </PaginationItem>
                        ))}
                        <PaginationItem disabled={pagination.currentPage === pagination.totalPages}>
                          <PaginationLink
                            next
                            onClick={(e) => {
                              paginate(pagination.currentPage + 1);
                            }}
                          />
                        </PaginationItem>
                        <PaginationItem disabled={pagination.currentPage === pagination.totalPages}>
                          <PaginationLink
                            last
                            onClick={(e) => {
                              paginate(pagination.totalPages);
                            }}
                          />
                        </PaginationItem>
                      </Pagination>
                      <div className="records-count text-center">
                        <p>
                          Showing <strong>{pagination.startIndex + 1}</strong> to{' '}
                          <strong>{pagination.endIndex + 1}</strong> of <strong>{pagination.totalItems}</strong> records
                        </p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SubjectList;
