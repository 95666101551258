import axios from 'axios';
import authHeader from '../../../helpers/jwt-token-access/auth-token-header';
import { ShowNotification, GetQueryParam, GetFilterString } from '../../../helpers/general/generalHelpers';
import { appConfig } from '../../../config/config';

function saveSubject(values, history, setLoader) {
  const subjectId = GetQueryParam(history.location.search, 'id');
  let type = 'post';
  let url = '/subject';
  if (subjectId) {
    type = 'put';
    url = '/subject/' + subjectId;
  }
  axios[type](url, values, { headers: authHeader() })
    .then((data) => {
      ShowNotification({ type: 'success', message: data.message });
      const messageType = type === 'post' ? 'added' : 'updated';
      history.push({
        pathname: '/subjects',
        notification: {
          show: true,
          message: data.message || `Subject has been ${messageType} successfully!`,
          title: `Subject ${messageType} successfully!`
        }
      });
    })
    .catch((error) => {
      const message =
        error && error.response && error.response.data
          ? error.response.data.message
          : 'There was an error adding subject, please try again!';
      ShowNotification({ type: 'error', message });
      throw error;
    })
    .finally(() => {
      setLoader(false);
    });
}

function getSubject(id) {
  return axios.get('/subject/' + id, { headers: authHeader() });
}

function getSubjects(pageNumber = 1, filters = '', pageSize = appConfig.RecordsPerPage) {
  let filterString = GetFilterString(filters);
  return axios.get(`/subject?pageNumber=${pageNumber}&pageSize=${pageSize}${filterString}`, { headers: authHeader() });
}

export { saveSubject, getSubject, getSubjects };
