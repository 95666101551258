import { EditorState,ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import draftToHtml from '../draftjs-to-html/index';
import htmlToDraft from "html-to-draftjs";
import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import SpecialCharPicker from '../SpecialCharPicker';
import { preImageUpload, rteColors, rteFontFamily, uploadAndGetContent } from '../Utils';

const AgoraEditor = (props) => {
    const {
      content,
      setContent,
      forceUpdate,
      value
    } = props;
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    useEffect(() => {
      setEditorState(value !==undefined? EditorState.createWithContent(
        ContentState.createFromBlockArray(
          htmlToDraft(value)
        )
      ):EditorState.createEmpty())

    },[])

    const handleEditorChange = (newEditorState) => {
      let newQuestion = content;
      const htmlContent = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
      newQuestion = htmlContent
      setContent(newQuestion)
      setEditorState(newEditorState)
      if(forceUpdate !== undefined) forceUpdate()
      };
    return (
        <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        toolbar={{
        image: {
            uploadEnabled: true,
            alignmentEnabled: true,
            uploadCallback: preImageUpload,
            previewImage: true,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            defaultSize: {
            height: 'auto',
            width: 'auto',
            },
        },
        colorPicker: {
          colors: rteColors
        },
        fontFamily: {
          options: rteFontFamily,
        },
        }}
        toolbarCustomButtons={[<SpecialCharPicker />]}
    />
    );
  };

  export default AgoraEditor;