import React, { useState, useEffect } from "react";
import { Row, Col, FormGroup, Label, Button, Modal } from "reactstrap";

import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvField from "availity-reactstrap-validation/lib/AvField";
import { ShowNotification } from "../../helpers/general/generalHelpers";
import Loader from "../../shared/loader/loader";
import { getDiscount, saveReward } from "./Services/users-referrals-service";
import { useHistory } from "react-router-dom";

const PopupAddReward = (props) => {
  const [loader, setLoader] = useState(false);
  const [modalOpen, setModalOpen] = useState(props.show);
  const history = useHistory();
  const [discount, setDiscount] = useState("");

  useEffect(() => {
    setModalOpen(props.show);
    setLoader(true);
    getDiscount()
      .then((response) => {
        setDiscount(response.data.discount);
      })
      .catch((error) => {
        const message =
          error && error.response && error.response.data
            ? error.response.data.message
            : "There was an error fetching user information, please try again!";
        ShowNotification({ type: "error", message });
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
  }, [props.show]);

  function toggleModal() {
    setModalOpen(false);
    props.toggleModal();
  }

  function updateReward(event, values) {
    setLoader(true);
    values.type = "REFERRAL_REWARD";
    values.isDefault = true;
    values.isActive = true;
    saveReward(values, history, setLoader);
    setModalOpen(false);
    props.toggleModal();
  }

  return (
    <Modal
      isOpen={modalOpen}
      toggle={() => {
        toggleModal();
      }}
      centered
    >
      <div className="modal-body overflow-hidden">
        <AvForm
          className="needs-validation"
          onValidSubmit={(e, v) => {
            updateReward(e, v);
          }}
        >
          <h4 className="card-title">Set Referral Reward</h4>
          <p className="card-title-desc">Set the discount below</p>
          <Row>
            <FormGroup className="col-md-12">
              <Col className="mb-2">
                <Label htmlFor="discount">Discount %</Label>
                <AvField
                  name="discount"
                  placeholder="Enter Discount"
                  type="numeric"
                  errorMessage="Please enter valid discount"
                  className="form-control"
                  id="discount"
                  max={100}
                  value={discount}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please provide a discount.",
                    },
                    pattern: {
                      value: /^\d+(\.\d{1,3})?$/,
                      errorMessage:
                        "Please provide a valid positive number with up to 3 decimal places.",
                    },
                    min: {
                      value: 0,
                      errorMessage: "Please provide a valid positive number.",
                    },
                  }}
                />
              </Col>
            </FormGroup>
          </Row>

          <Row>
            <Col lg="12" className="agora-form-buttons text-center pt-4">
              <Button
                onClick={() => {
                  toggleModal();
                }}
                className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
              >
                Save
              </Button>
            </Col>
          </Row>
        </AvForm>
      </div>
    </Modal>
  );
};

export default PopupAddReward;
