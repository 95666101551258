import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledTooltip,
} from "reactstrap";

import Loader from "../../shared/loader/loader";
import {
  ShowNotification,
  SetStatusStyle,
  GetQueryParam,
} from "../../helpers/general/generalHelpers";
import BackButton from "../../components/Common/BackButton";
import { getUser } from "./Services/user-service";
import { Link } from "react-router-dom";

const ViewUser = (props) => {
  const [loader, setLoader] = useState(false);
  // const activities = [
  //   {
  //     name: 'Created New User',
  //     description: "Here is the link to the user's profile",
  //     link: 'https://agora-8f1df.web.app/users',
  //     date: new Date()
  //   },
  //   {
  //     name: 'Another Task',
  //     description: 'Here is the link to the task',
  //     link: 'https://agora-8f1df.web.app/subjects',
  //     date: new Date()
  //   },
  //   {
  //     name: 'Created New Chapter',
  //     description: 'Here is the link to the chapter',
  //     link: 'https://agora-8f1df.web.app/chapters',
  //     date: new Date()
  //   }
  // ];
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    description: "",
    status: "",
    role: "",
    email: "",
    mobileNumber: "",
    createdBy: "",
    createdAt: null,
    updatedAt: null,
    points: 0,
  });

  useEffect(() => {
    if (props.location.search) {
      setLoader(true);
      const userId = GetQueryParam(props.location.search, "id");
      if (userId) {
        getUser(userId)
          .then((response) => {
            const user = response.data.user;
            console.log(user);
            setUser(user);
          })
          .catch((error) => {
            const message =
              error && error.response && error.response.data
                ? error.response.data.message
                : "There was an error fetching user information, please try again!";
            ShowNotification({ type: "error", message });
            throw error;
          })
          .finally(() => {
            setLoader(false);
          });
      }
    }
  }, [props.location.search]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="relative">
          <Loader loading={loader} />
          {/* Render Page title */}
          <BackButton text="Back to users" url="/users" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="12 subject-info-section">
                      <div className="subject-icon">
                        {!user.profileImage || user.profileImage === "Icon" ? (
                          <div className="avatar-lg">
                            <span className="avatar-title text-capitalize rounded-circle">
                              {user.firstName.charAt(0)}
                            </span>
                          </div>
                        ) : (
                          <div>
                            <img
                              className="img-responsive"
                              src={user.profileImage}
                              alt={user.firstName}
                            />
                          </div>
                        )}
                      </div>
                      <div className="subject-info">
                        <h4 className="font-size-18 text-capitalize">
                          {user.firstName + " " + user.lastName}
                          <span className="subject-status">
                            <SetStatusStyle status={user.status} />
                          </span>
                          <Link
                            className="float-right text-secondary"
                            to={{
                              pathname: "/user",
                              search: "?id=" + user._id,
                            }}
                            id={"edit-user" + user._id}
                          >
                            <i className="bx bxs-edit-alt"></i>
                            <UncontrolledTooltip
                              placement="top"
                              target={"edit-user" + user._id}
                            >
                              Edit
                            </UncontrolledTooltip>
                          </Link>
                        </h4>
                        <h5 className="text-capitalize font-weight-normal font-size-14">
                          {user.role === "contenteditor"
                            ? "Content Editor"
                            : user.role}
                        </h5>
                        <p className="font-size-12 mb-0">{user.email}</p>
                        <p className="font-size-12">{user.mobileNumber}</p>
                      </div>
                      <div className="clearfix"></div>
                    </Col>
                    <Col md="12 subject-creator-info-section font-size-13">
                      <div
                        className="referral-info"
                        style={{ lineHeight: "0.5" }}
                      >
                        <p>Points : {user.points}</p>
                        <p>
                          Referral Code :{" "}
                          {user.referralId?.Code
                            ? user.referralId.Code
                            : "No Referral Code"}
                        </p>
                        <p>
                          Referrer :{" "}
                          {user.referrer
                            ? `${user.referrer} ( ${user.referredBy} )`
                            : "No Referrer"}
                        </p>
                      </div>
                      <div className="subject-creator-info">
                        <p>
                          Created at:{" "}
                          {new Intl.DateTimeFormat("en-GB", {
                            year: "numeric",
                            month: "short",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          }).format(new Date(user.createdAt))}
                        </p>
                        <p>
                          Last updated at:{" "}
                          {new Intl.DateTimeFormat("en-GB", {
                            year: "numeric",
                            month: "short",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          }).format(new Date(user.updatedAt))}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="12">
                      <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h4 className="mb-0 font-size-18">Activities</h4>
                        <div className="page-title-right">
                          <Button onClick={(e) => {editSyllabus(null)}} className="btn btn-primary btn-block agora-button border-radius waves-effect waves-light">+ Add New Syllabus</Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <Table className="table-centered table-nowrap table-hover">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Activities</th>
                          <th scope="col" style={{ width: '180px' }}>
                            Date & Time
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {activities.map((activity, i) => (
                          <tr key={'_activity_' + i}>
                            <td>
                              <h5 className="font-size-14">{activity.name}</h5>
                              <h5 className="font-size-12 mb-1">
                                {activity.description}:{' '}
                                <Link className="agora-link-color" to={activity.link}>
                                  {activity.link}
                                </Link>
                              </h5>
                            </td>
                            <td>
                              {new Intl.DateTimeFormat('en-GB', {
                                year: 'numeric',
                                month: 'short',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit'
                              }).format(new Date(activity.date))}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <Row>
                    <Col lg="12">
                      <Pagination className="pagination pagination-rounded justify-content-center mt-4">
                        <PaginationItem disabled>
                          <PaginationLink first />
                        </PaginationItem>
                        <PaginationItem disabled>
                          <PaginationLink previous />
                        </PaginationItem>
                        <PaginationItem active>
                          <PaginationLink>1</PaginationLink>
                        </PaginationItem>
                        <PaginationItem disabled>
                          <PaginationLink next />
                        </PaginationItem>
                        <PaginationItem disabled>
                          <PaginationLink last />
                        </PaginationItem>
                      </Pagination>
                      <div className="records-count text-center">
                        <p>
                          Showing <strong>1</strong> to <strong>3</strong> of <strong>3</strong> records
                        </p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewUser;
