import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Label,
  Button,
  FormGroup,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink
} from 'reactstrap';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvField from 'availity-reactstrap-validation/lib/AvField';

import PageTitle from '../../components/Common/PageTitle';
import { SetStatusStyle, ShowNotification } from '../../helpers/general/generalHelpers';
import Loader from '../../shared/loader/loader';
import { getListAssessments } from '../../service/assessment';
import { getListSubjects } from '../../service/subject';
import PopupAddStudyGuide from './PopupAddAssessment';
import { getPaginationInformation, initialPaginationObject } from '../../helpers/general/pagination';

const AssessmentList = (props) => {
  const [pagination, setPagination] = useState(initialPaginationObject);
  const filterForm = useRef(null);
  const [filters, setFilters] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [assessments, setAssessments] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [subjects, setSubjects] = useState([]);

  if (props.location.notification && props.location.notification.show) {
    const notification = props.location.notification;
    ShowNotification({
      type: 'success',
      message: notification.message
    });
    props.location.notification = null;
  }

  const handleGetSubjects = () => {
    getListSubjects({ pageSize: 100 })
      .then((response) => {
        const subjList = response.data.subjects.map((subj) => {
          return { ...subj, id: subj._id, value: subj.title, label: subj.title };
        });
        setSubjects(subjList);
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleGetListAssessments = () => {
    setLoader(true);
    getListAssessments({ pageNumber: currentPage, filters })
      .then((response) => {
        setAssessments(response.data.assessments);
        setPagination(getPaginationInformation(response.data.Count, currentPage));
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const clearFilters = () => {
    filterForm.current.reset();
    setFilters(undefined);
  };

  const handleFilters = (event, values) => {
    setFilters(values);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const toggleModal = () => {
    setIsPopupOpen(!isPopupOpen);
    removeBodyCss();
  };

  const removeBodyCss = () => {
    document.body.classList.add('no_padding');
  };

  useEffect(() => {
    handleGetSubjects();
  }, []);

  useEffect(() => {
    handleGetListAssessments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, filters]);

  return (
    <React.Fragment>
      <div className="page-content assessment">
        <Container fluid className="relative">
          <Loader loading={loader} />

          {/* Render Page title */}
          <PageTitle title="Assessments">
            <div className="page-title-right">
              <Button
                onClick={(e) => {
                  setIsPopupOpen(true);
                }}
                className="btn btn-primary btn-block agora-button border-radius waves-effect waves-light"
              >
                + Add New Assessment
              </Button>
            </div>
          </PageTitle>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={filterForm}
                    onValidSubmit={(e, v) => {
                      handleFilters(e, v);
                    }}
                  >
                    <Row>
                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label>Title :</Label>
                          <AvField name="title" placeholder="Search" className="form-control filter-field" />
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label>Subject :</Label>
                          <AvField type="select" name="subject" className="form-control filter-field">
                            <option value="">Please select the subject</option>
                            {subjects.map((subj, idx) => {
                              return (
                                <option key={idx} value={subj._id}>
                                  {subj.title + ' - ' + subj.studyLevelId}
                                </option>
                              );
                            })}
                          </AvField>
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label>Type :</Label>
                          <AvField type="select" name="type" className="form-control filter-field">
                            <option value="">Please select the type</option>
                            <option value="Structured">Structured</option>
                            <option value="MCQ">MCQ</option>
                          </AvField>
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label>Level :</Label>
                          <AvField type="select" name="level" className="form-control filter-field">
                            <option value="">Please select the level</option>
                            <option value="Subject">Subject</option>
                            <option value="Chapter">Chapter</option>
                            <option value="Study Guide">Study Guide</option>
                          </AvField>
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label>Status :</Label>
                          <AvField type="select" name="active" className="form-control filter-field">
                            <option value="">Please select the status</option>
                            <option key={1} value={true}>
                              {' '}
                              Active
                            </option>
                            <option key={2} value={false}>
                              {' '}
                              Inactive
                            </option>
                          </AvField>
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-12 col-md-auto mb-4">
                        <Button
                          type="submit"
                          className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
                        >
                          Filter
                        </Button>
                        <Button
                          type="button"
                          onClick={clearFilters}
                          className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light ml-2"
                        >
                          Clear
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>

                  <div className="table-responsive">
                    <Table className="table-centered table-nowrap table-hover">
                      <thead className="thead-light font-size-15">
                        <tr>
                          <th scope="col">Title</th>
                          <th scope="col">Total Questions</th>
                          <th scope="col">Subjects</th>
                          <th scope="col">Level</th>
                          <th scope="col">Type</th>
                          <th scope="col">Status</th>
                          <th scope="col" className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="agora-lato">
                        {assessments.map((item, i) => (
                          <tr key={'item' + item._id}>
                            <td>
                              <span>
                                <Link
                                  style={{ color: '#495057' }}
                                  to={{ pathname: '/assessment', search: '?id=' + item._id }}
                                  id={'view-assessment' + item._id}
                                >
                                  {item.title}
                                  <UncontrolledTooltip placement="top" target={'view-assessment' + item._id}>
                                    View
                                  </UncontrolledTooltip>
                                </Link>
                              </span>
                            </td>
                            <td>{item.questionCount}</td>
                            <td>
                              {item.subject?.title} - {item?.studyLevelId}
                            </td>
                            <td>{item.level}</td>
                            <td>{item.type}</td>
                            <td>
                              <div>
                                <SetStatusStyle status={item.active ? 'active' : 'inactive'} />
                              </div>
                            </td>
                            <td className="text-center">
                              <ul className="list-inline font-size-20 contact-links mb-0">
                                <li className="list-inline-item px-2">
                                  <Link
                                    to={{ pathname: '/assessment', search: '?id=' + item._id }}
                                    id={'view-assessment' + item._id}
                                  >
                                    <i className="bx bx-show-alt"></i>
                                    <UncontrolledTooltip placement="top" target={'view-assessment' + item._id}>
                                      View
                                    </UncontrolledTooltip>
                                  </Link>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <Row>
                    <Col lg="12">
                      <Pagination className="pagination pagination-rounded justify-content-center mt-4">
                        <PaginationItem disabled={pagination.currentPage === 1}>
                          <PaginationLink
                            first
                            onClick={(e) => {
                              paginate(1);
                            }}
                          />
                        </PaginationItem>
                        <PaginationItem disabled={pagination.currentPage === 1}>
                          <PaginationLink
                            previous
                            onClick={(e) => {
                              paginate(pagination.currentPage - 1);
                            }}
                          />
                        </PaginationItem>
                        {pagination.pages.map((page, index) => (
                          <PaginationItem key={'_page_' + index} active={page === pagination.currentPage}>
                            <PaginationLink
                              onClick={(e) => {
                                paginate(page);
                              }}
                            >
                              {page}
                            </PaginationLink>
                          </PaginationItem>
                        ))}
                        <PaginationItem disabled={pagination.currentPage === pagination.totalPages}>
                          <PaginationLink
                            next
                            onClick={(e) => {
                              paginate(pagination.currentPage + 1);
                            }}
                          />
                        </PaginationItem>
                        <PaginationItem disabled={pagination.currentPage === pagination.totalPages}>
                          <PaginationLink
                            last
                            onClick={(e) => {
                              paginate(pagination.totalPages);
                            }}
                          />
                        </PaginationItem>
                      </Pagination>
                      <div className="records-count text-center">
                        <p>
                          Showing <strong>{pagination.startIndex + 1}</strong> to{' '}
                          <strong>{pagination.endIndex + 1}</strong> of <strong>{pagination.totalItems}</strong> records
                        </p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <PopupAddStudyGuide show={isPopupOpen} toggleModal={toggleModal} />
    </React.Fragment>
  );
};

export default AssessmentList;
