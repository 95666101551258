import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Row, Col, Card, CardBody, Button, Modal } from 'reactstrap';

import Loader from '../../shared/loader/loader';
import { appConfig } from '../../config/config';
import { TinyImageUpload } from '../../helpers/general/services/image-service';

const PopupMarking = (props) => {
  const { modalOpen, toggleModal, loader, setLoader, markingModalGetSet, handleSaveQuestion,marking } = props;
  return (
    <Modal
      isOpen={modalOpen}
      toggle={() => {
        toggleModal();
      }}
    >
      <Card>
        <CardBody>
          <Loader loading={loader} />
          <Row>
            {/* <Col md="12">
              <h5>Add Marking</h5>
            </Col> */}
            {/* <Col md="12">
              <h6 className="text-secondary">Fill all information below</h6>
            </Col> */}
            <Col md="12">
              <img src={marking} className='col-12' />
              {/* <Editor
                {...appConfig.tinyRTE}
                // initialValue={markingModalGetSet.get()}
                value={markingModalGetSet.get()}
                init={{
                  height: 300,
                  menubar: false,
                  images_upload_handler: (blobInfo, success, failure) => {
                    TinyImageUpload(blobInfo, success, failure, setLoader);
                  }
                }}
                onEditorChange={(content, editor) => {
                  markingModalGetSet.set(content);
                }}
              /> */}
            </Col>
            <Col md="12" className="mt-4">
              <Row className="justify-content-center">
                <Button
                  type="button"
                  onClick={() => {
                    toggleModal();
                  }}
                  className="agora-button cancel-button border-radius"
                >
                  Close
                </Button>
                {/* <Button
                  type="submit"
                  className="agora-button save-button border-radius"
                  onClick={() => {
                    toggleModal();
                    handleSaveQuestion();
                  }}
                >
                  Save
                </Button> */}
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Modal>
  )
};

export default PopupMarking;
