import React, { useState } from 'react';
//import { Editor } from '@tinymce/tinymce-react';
import { Row, Col, Card, CardBody, Button, Modal } from 'reactstrap';
import AgoraEditor from '../../components/Common/AgoraEditor';
import { useEffect } from 'react';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import Loader from '../../shared/loader/loader';
// import { appConfig } from '../../config/config';
// import { TinyImageUpload } from '../../helpers/general/services/image-service';

const PopupSampleAnswer = (props) => {
  const { modalOpen, toggleModal, loader, setLoader, handleSaveQuestion, setSampleAnswer,sampleAnswer } = props;
  const [editorState, setEditorState] =  useState(() => EditorState.createEmpty());
  useEffect(()=>{
    setEditorState(sampleAnswer? EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(sampleAnswer)
      )
    ):EditorState.createEmpty())
  },[])
  return (
    <Modal
      isOpen={modalOpen}
      toggle={() => {
        toggleModal();
      }}
    >
      <Card>
        <CardBody>
          <Loader loading={loader} />
          <Row>
            <Col md="12">
              <h5>Explanation</h5>
            </Col>
            <Col md="12">
              <h6 className="text-secondary">Fill all information below</h6>
            </Col>
            <Col md="12">
              {/* {console.log(`sampleAnswer`, sampleAnswer)} */}
              {/* <Editor
                {...appConfig.tinyRTE}
                // initialValue={sampleAnswerModalGetSet.get()}
                // value={sampleAnswerModalGetSet.get()}
                value={sampleAnswer}
                init={{
                  height: 300,
                  menubar: false,
                  images_upload_handler: (blobInfo, success, failure) => {
                    TinyImageUpload(blobInfo, success, failure, setLoader);
                  }
                }}
                onEditorChange={(content, editor) => {
                  setSampleAnswer(content);
                }}
              /> */}
              <AgoraEditor content={sampleAnswer} setContent={setSampleAnswer}  value={sampleAnswer}  />
            </Col>
            <Col md="12" className="mt-4">
              <Row className="justify-content-center">
                <Button
                  type="button"
                  onClick={() => {
                    toggleModal();
                  }}
                  className="agora-button cancel-button border-radius"
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  className="agora-button save-button border-radius"
                  onClick={() => {
                    toggleModal();
                    // handleSaveQuestion();
                  }}
                >
                  Save
                </Button>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Modal>
  )
};

export default PopupSampleAnswer;
