import axios from "axios";
import authHeader from "../../../helpers/jwt-token-access/auth-token-header";
import {
  ShowNotification,
  GetQueryParam,
  GetFilterString,
} from "../../../helpers/general/generalHelpers";
import { appConfig } from "../../../config/config";
import { generateReferralCode } from "../../Users/Services/user-service";

function getUsersReferrals(
  pageNumber,
  filters,
  pageSize = appConfig.RecordsPerPage
) {
  let filterString = GetFilterString(filters);
  return axios.get(
    `/referralCoupon?pageNumber=${pageNumber}&pageSize=${pageSize}${filterString}`,
    { headers: authHeader() }
  );
}

function getAllReferralsById(
  userId,
  pageNumber,
  filters,
  pageSize = appConfig.RecordsPerPage
) {
  let filterString = GetFilterString(filters);
  return axios.get(
    `/referralCoupon/refferals/${userId}?pageNumber=${pageNumber}&pageSize=${pageSize}${filterString}`,
    { headers: authHeader() }
  );
}

function getDiscount() {
  return axios.get("/referralCoupon/", {
    headers: authHeader(),
  });
}

function saveReward(values, history, setLoader) {
  generateReferralCode(values)
    .then((response) => {
      history.push({
        notification: {
          show: true,
          message: response.data.message,
          title: `Reward added successfully!`,
        },
      });
    })
    .catch((error) => {
      console.log(error.response);
      const message =
        error && error.response && error.response.data
          ? error.response.data.message
          : "There was an error adding referralReward, please try again!";
      ShowNotification({ type: "error", message });
      throw error;
    })
    .finally(() => {
      setLoader(false);
    });
}

export { getAllReferralsById, getDiscount, saveReward };
