import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from './actionTypes';
import { apiError } from './actions';

//Include Both Helper File with needed methods
import { postJwtLogin } from '../../../helpers/agorabackend_helper';

function* loginUser({ payload: { user, history, setLoader } }) {
  setLoader(true);
  try {
    const response = yield call(postJwtLogin, '/auth/login', { email: user.email, password: user.password });
    setLoader(false);
    if (response) {
      localStorage.setItem('authUser', JSON.stringify(response));
      history.push('/users');
    }
  } catch (error) {
    setLoader(false);
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem('authUser');
    history.push('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
  yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default authSaga;
