import axios from "axios";
import authHeader from "../../../helpers/jwt-token-access/auth-token-header";
import {
  ShowNotification,
  GetQueryParam,
  GetFilterString,
} from "../../../helpers/general/generalHelpers";
import { appConfig } from "../../../config/config";

function saveCoupon(values, history, setLoader) {
  const couponId = GetQueryParam(history.location.search, "id");
  let type = "post";
  let url = "/referralCoupon";
  if (couponId) {
    type = "put";
    url = "/referralCoupon/" + couponId;
  }

  axios[type](url, values, { headers: authHeader() })
    .then((data) => {
      ShowNotification({ type: "success", message: data.message });
      const messageType = type === "post" ? "added" : "updated";
      history.push({
        pathname: "/coupons",
        notification: {
          show: true,
          message:
            data.message || `Coupon has been ${messageType} successfully!`,
          title: `Coupon ${messageType} successfully!`,
        },
      });
    })
    .catch((error) => {
      console.log(error.response);
      const message =
        error && error.response && error.response.data
          ? error.response.data.message
          : "There was an error adding user, please try again!";
      ShowNotification({ type: "error", message });
      throw error;
    })
    .finally(() => {
      setLoader(false);
    });
}

function deleteCoupon(couponId, Data) {
  return axios.put(`/referralCoupon/delete/${couponId}`, Data, {
    headers: authHeader(),
  });
}

function getCoupons(pageNumber, filters, pageSize = appConfig.RecordsPerPage) {
  let filterString = GetFilterString(filters);
  return axios.get(
    `/referralCoupon/coupons/admin?pageNumber=${pageNumber}&pageSize=${pageSize}${filterString}`,
    { headers: authHeader() }
  );
}

function getCoupon(id) {
  return axios.get("/referralCoupon/" + id, {
    headers: authHeader(),
  });
}

export { saveCoupon, getCoupons, getCoupon, deleteCoupon };
