import React from 'react';
import { Redirect } from 'react-router-dom';

// Profile
// import UserProfile from '../pages/Authentication/user-profile';

// Authentication related pages
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';
import ForgetPwd from '../pages/Authentication/ForgetPassword';

//  // Inner Authentication
import Login1 from '../pages/AuthenticationInner/Login';
import Register1 from '../pages/AuthenticationInner/Register';
import ForgetPwd1 from '../pages/AuthenticationInner/ForgetPassword';
import LockScreen from '../pages/AuthenticationInner/auth-lock-screen';

//Pages
import PagesMaintenance from '../pages/Utility/pages-maintenance';
import PagesComingsoon from '../pages/Utility/pages-comingsoon';
import Pages404 from '../pages/Utility/pages-404';
import Pages500 from '../pages/Utility/pages-500';

//Users
import UsersList from '../pages/Users/users-list';
import AddEditUser from '../pages/Users/add-edit-user';
import ViewUser from '../pages/Users/view-user';
import ViewAllReferrals from '../pages/Users/view-all-referrals';

//Roles
import RoleList from '../pages/Roles/role-list';
import AddEditRole from '../pages/Roles/add-edit-role';
import RolePrivileges from '../pages/Roles/role-privileges';
import RoleAPIPrivileges from '../pages/Roles/role-api-privileges';
// subjects
import SubjectList from '../pages/Subjects/subject-list';
import AddEditSubject from '../pages/Subjects/add-edit-subject';
import ViewSubject from '../pages/Subjects/view-subject';

// chapter
import ChapterList from '../pages/Chapters/chapter-list';
import AddEditChapter from '../pages/Chapters/add-edit-chapter';
import ViewChapter from '../pages/Chapters/view-chapter';

// Study Guides
import StudyGuideList from '../pages/StudyGuides/StudyGuide';
import EditStudyGuide from '../pages/StudyGuides/EditStudyGuide';

// Assessment
import AssessmentList from '../pages/Assessment/Assessment';
import EditAssessment from '../pages/Assessment/EditAssessment';

// Tasks
import TasksList from '../pages/Tasks/tasks-list';
import Task from '../pages/Tasks/task';

// Questions
import Questions from '../pages/Questions/Questions';
import QuestionTagging from '../pages/QuestionTagging/QuestionTagging';
import AddEditMcqQuestion from '../pages/Questions/AddEditMcqQuestion';
import AddEditStructuredQuestion from '../pages/Questions/AddEditStructuredQuestion';

//Coupons
import AddEditCoupon from '../pages/Coupons/add-edit-coupon';
import CouponsList from '../pages/Coupons/coupons-list';

const navRoutes = [
  {
    name: "questions",
    routeType: "module",
    path: "",
    description: "Qustions Module",
    children: [
      {
        name: "questions",
        routeType: "child",
        path: "/questions",
        component: Questions,
        description: "Questions",
      },
      {
        name: "mcq-question",
        routeType: "child",
        path: "/mcq-question",
        component: AddEditMcqQuestion,
        description: "MCQ Questions",
      },
      {
        name: "structured-question",
        routeType: "child",
        path: "/structured-question",
        component: AddEditStructuredQuestion,
        description: "Structured Question",
      }
    ]
  },
  {
    name: "users",
    routeType: "module",
    path: "",
    description: "Users Module",
    children: [
      {
        name: "users",
        routeType: "child",
        path: "/users",
        component: UsersList,
        description: "Get Users",
      },
      {
        name: "user",
        routeType: "child",
        component: AddEditUser,
        path: "/user",
        description: "Get User",
      },
      {
        name: "profile",
        routeType: "child",
        path: "/profile",
        component: ViewUser,
        description: "User Profile",
      },
      {
        name: "viewAllReferrals",
        routeType: "child",
        path: "/viewAllReferrals",
        component: ViewAllReferrals,
        description: "View All Referrals",
      },
    ]
  },
  {
    name: "coupons",
    routeType: "module",
    path: "",
    description: "Coupons Module",
    children: [
      {
        name: "coupon",
        routeType: "child",
        path: "/coupon",
        component: AddEditCoupon,
        description: "Get Coupon",
      },
      {
        name: "coupons",
        routeType: "child",
        path: "/coupons",
        component: CouponsList,
        description: "Get Coupons",
      },
    ]
  },
  {
    name: "subjects",
    routeType: "module",
    path: "",
    description: "Subject Module",
    children: [
      {
        name: "subjects",
        routeType: "child",
        path: "/subjects",
        component: SubjectList,
        description: "Get Subjects",
      },
      {
        name: "subject",
        routeType: "child",
        path: "/subject",
        component: AddEditSubject,
        description: "Get Subject",
      },
      {
        name: "subject-details",
        routeType: "child",
        path: "/subject-details",
        component: ViewSubject,
        description: "Get subject details",
      },
    ]
  },
  {
    name: "chapters",
    routeType: "module",
    path: "",
    description: "Chapters Module",
    children: [
      {
        name: "chapters",
        routeType: "child",
        path: "/chapters",
        component: ChapterList,
        description: "Get chapters",
      },
      {
        name: "chapter",
        routeType: "child",
        path: "/chapter",
        component: AddEditChapter,
        description: "Get chapter",
      },
      {
        name: "chapter-details",
        routeType: "child",
        path: "/chapter-details",
        component: ViewChapter,
        description: "Get chapter details",
      },
    ]
  },
  {
    name: "studyguides",
    routeType: "module",
    path: "",
    description: "Study Guid Module",
    children: [
      {
        name: "studyguides",
        routeType: "child",
        path: "/studyguides",
        component: StudyGuideList,
        description: "Get study guides",
      },
      {
        name: "studyguide",
        routeType: "child",
        path: "/studyguide",
        component: EditStudyGuide,
        description: "Get studyguide",
      },
    ]
  },
  {
    name: "assessments",
    routeType: "module",
    path: "",
    description: "Assessment Module",
    children: [
      {
        name: "assessments",
        routeType: "child",
        path: "/assessments",
        component: AssessmentList,
        description: "Get assessments",
      },
      {
        name: "assessment",
        routeType: "child",
        path: "/assessment",
        component: EditAssessment,
        description: "Get assessment",
      },
    ]
  },
  {
    name: "tasks",
    routeType: "module",
    path: "",
    description: "Task Module",
    children: [
      {
        name: "tasks",
        routeType: "child",
        path: "/tasks",
        component: TasksList,
        description: "Get tasks",
      },
      {
        name: "task",
        routeType: "child",
        path: "/task",
        component: Task ,
        description: "Get task",
      },
    ]
  },
  {
    name: "question-tagging",
    routeType: "module",
    path: "",
    description: "Question Tagging Module",
    children: [
      {
        name: "question-tagging",
        routeType: "child",
        path: "/question-tagging",
        component: QuestionTagging ,
        description: "Question Tagging",
      },
    ]
  },
  {
    name: "user-role",
    routeType: "module",
    path: "",
    description: "User Role Module",
    children: [
      {
        name: "roles",
        routeType: "child",
        path: "/roles",
        component: RoleList,
        description: "Get Roles",
      },
      {
        name: "role",
        routeType: "child",
        component: AddEditRole,
        path: "/role",
        description: "Get Role",
      },
      {
        name: "privileges",
        routeType: "child",
        component: RolePrivileges,
        path: "/role-privileges",
        description: "Get Role Privileges",
      },
      {
        name: "api-privileges",
        routeType: "child",
        component: RoleAPIPrivileges,
        path: "/role-api-privileges",
        description: "Get Role Privileges",
      },
    ]
  },
]

const userRoutes = [];

navRoutes.forEach((module) => {
  module.children.forEach((child) => {
    const route = {
      name: child.name,
      path: child.path,
      component: child.component,

    };

    userRoutes.push(route);
  });
});
let route = { name: '', path: '/', exact: true, component: () => <Redirect to="/users" /> }
userRoutes.push(route);


const allRoutes = navRoutes.map((module) => {
  const updatedModule = { ...module };
  updatedModule.children = module.children.map((child) => {
    const { component, ...childWithoutComponent } = child;
    return childWithoutComponent;
  });
  return updatedModule;
});

/*const userRoutes = [
  // Questions
  { path: '/questions', component: Questions },
  { path: '/mcq-question', component: AddEditMcqQuestion },
  { path: '/structured-question', component: AddEditStructuredQuestion },

  // Users
  { path: '/users', component: UsersList },
  { path: '/user', component: AddEditUser },
  { path: '/profile', component: ViewUser },
  { path: '/viewAllReferrals', component: ViewAllReferrals },

  //Coupons
  { path: '/coupon', component: AddEditCoupon },
  { path: '/coupons', component: CouponsList },

  // Subjects
  { path: '/subjects', component: SubjectList },
  { path: '/subject', component: AddEditSubject },
  { path: '/subject-details', component: ViewSubject },

  // Chapters
  { path: '/chapters', component: ChapterList },
  { path: '/chapter', component: AddEditChapter },
  { path: '/chapter-details', component: ViewChapter },

  // Study Guides
  { path: '/studyguides', component: StudyGuideList },
  { path: '/studyguide', component: EditStudyGuide },

  // Assessment
  { path: '/assessments', component: AssessmentList },
  { path: '/assessment', component: EditAssessment },

  // Tasks
  { path: '/tasks', component: TasksList },
  { path: '/task', component: Task },

  // Question Tagging
  { path: '/question-tagging', component: QuestionTagging },

  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to="/users" /> }
];*/

const authRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgetPwd },
  { path: '/register', component: Register },

  { path: '/pages-maintenance', component: PagesMaintenance },
  { path: '/pages-comingsoon', component: PagesComingsoon },
  { path: '/pages-404', component: Pages404 },
  { path: '/pages-500', component: Pages500 },

  // Authentication Inner
  { path: '/pages-login', component: Login1 },
  { path: '/pages-register', component: Register1 },
  { path: '/pages-forget-pwd', component: ForgetPwd1 },
  { path: '/auth-lock-screen', component: LockScreen }
];

export { userRoutes, authRoutes, allRoutes };
