import { requestWithToken } from './index';
import { appConfig } from '../config/config';
import { GetFilterString } from '../helpers/general/generalHelpers';

export const getListAssessments = ({ pageNumber=1, filters, pageSize = appConfig.RecordsPerPage }) => {
	let filterString = GetFilterString(filters);
  	return requestWithToken({
        url: `assessment?pageNumber=${pageNumber}&pageSize=${pageSize}${filterString}`,
        method: 'GET'
    })
}

export const postAddAssessment = ({ body }) => {
	return requestWithToken({
		url: 'assessment',
		method: 'POST',
		data: body
	})
}

export const getDetailAssessment = ({ id }) => {
  return requestWithToken({
		url: `assessment/${id}`,
		method: 'GET'
	})
}

export const putUpdateAssessment = ({ id, body }) => {
	return requestWithToken({
		url: `assessment/${id}`,
		method: 'PUT',
		data: body
	})
}


export const deleteAssessment = ({ id }) => {
	return requestWithToken({
		url: `assessment/${id}`,
		method: 'DELETE',
	})
}
