import axios from 'axios';
import authHeader from '../../../helpers/jwt-token-access/auth-token-header';
import { ShowNotification, GetFilterString } from '../../../helpers/general/generalHelpers';
import { appConfig } from '../../../config/config';

function saveQuestion(values, history, setLoader) {
  const questionId = values._id ? values._id : values.id ? values.id : '';
  let type = questionId ? 'put' : 'post';
  let url = `/question${questionId ? '/' + questionId : ''}`;
  axios[type](url, values, { headers: authHeader() })
    .then((data) => {
      const messageType = type === 'post' ? 'added' : 'updated';
      ShowNotification({
          type: 'success',
          message: data.message || `Question has been ${messageType} successfully!`
      });
      history.goBack()
    })
    .catch((error) => {
      console.error(error.response);
      const message =
        error && error.response && error.response.data
          ? error.response.data.message
          : 'There was an error adding question, please try again!';
      ShowNotification({ type: 'error', message });
      throw error;
    })
    .finally(() => {
      setLoader(false);
    });
}

function getQuestions(pageNumber, filters, pageSize = appConfig.RecordsPerPage) {
  let filterString = GetFilterString(filters);
  return axios.get(`/question?pageNumber=${pageNumber}&pageSize=${pageSize}${filterString}`, {
    headers: authHeader()
  });
}

function getQuestion(id) {
  return axios.get('/question/' + id, { headers: authHeader() });
}

export { getQuestions, getQuestion, saveQuestion };
