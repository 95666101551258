import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { CSVDownload } from 'react-csv';

import { ShowNotification } from '../../helpers/general/generalHelpers';
import { user, userRoles } from '../../helpers/user/userInfo';
import { getTasks } from '../../pages/Tasks/Services/task-service';
import exportIcon from '../../assets/images/exportIcon.svg';

export default function ExportTasksCSV(props) {
  const [exportData, setExportData] = useState(null);
  const [clicked, setClicked] = useState(false);

  function transformTasksDataForExport(tasks) {
    let transformedData = [];
    tasks.forEach((task) => {
      let transformedTask = {
        'Assessment Title': task.assessment.title,
        Subject: task.subject.title,
        Learner: `${task.learner.firstName} ${task.learner.lastName}`,
        'Last Updated': task.updatedAt,
        Status: task.status
      };

      if (user.user.role === userRoles.admin) {
        transformedTask['Assignee'] = `${task.assignee.firstName} ${task.assignee.lastName}`;
      }

      transformedData.push(transformedTask);
    });
    return transformedData;
  }

  return (
    <>
      <Button
        className="btn btn-primary agora-button border-radius waves-effect waves-light"
        onClick={(event) => {
          getTasks(null, props.filters, null)
            .then((response) => {
              setExportData(response.data.task);
              setClicked(true);
            })
            .catch((error) => {
              ShowNotification({
                type: 'error',
                message: 'Something went wrong'
              });
            })
            .finally(() => setTimeout(setClicked(false), 2000));
        }}
      >
        <img src={exportIcon} alt="export icon" /> Export CSV
      </Button>
      {clicked === true && exportData ? <CSVDownload data={transformTasksDataForExport(exportData)} /> : null}
    </>
  );
}
