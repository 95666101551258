import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
  Label,
  Button
} from 'reactstrap';

import PageTitle from '../../components/Common/PageTitle';
import { SetStatusStyle, ShowNotification, ObjectHasValues } from '../../helpers/general/generalHelpers';
import Loader from '../../shared/loader/loader';
import { getChapters } from './Services/chapter-service';
import { getPaginationInformation, initialPaginationObject } from '../../helpers/general/pagination';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import { getSubjects } from '../Subjects/Services/subject-service';

const SubjectList = (props) => {
  const [pagination, setPagination] = useState(initialPaginationObject);
  const [currentPage, setCurrentPage] = useState(1);
  const [chapters, setChapters] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const filterForm = useRef(null);
  const [filters, setFilters] = useState(null);
  const [loader, setLoader] = useState(true);

  if (props.location.notification && props.location.notification.show) {
    const notification = props.location.notification;
    ShowNotification({
      type: 'success',
      message: notification.message
    });
  }

  useEffect(() => {
    getSubjects()
      .then((response) => {
        setSubjects(response.data.subjects);
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  useEffect(() => {
    setLoader(true);
    getChapters(currentPage, filters)
      .then((response) => {
        setChapters(response.data.chapters);
        setPagination(getPaginationInformation(response.data.Count, currentPage));
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
  }, [currentPage, filters]);

  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
  }

  function handleFilters(event, values) {
    if (ObjectHasValues(values)) {
      values.active = values.active === 'active' ? true : values.active === 'inactive' ? false : '';
      setFilters(values);
    } else {
      setFilters(null);
    }
  }

  function clearFilters() {
    filterForm.current.reset();
    setFilters(null);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="relative">
          <Loader loading={loader} />

          {/* Render Page title */}
          <PageTitle
            title="Chapters"
            button
            name="+ Add New Chapter"
            url="/chapter"
          />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={filterForm}
                    onValidSubmit={(e, v) => {
                      handleFilters(e, v);
                    }}
                  >
                    <Row>
                      <Col className="col-sm-auto">
                        <FormGroup>
                          <Label htmlFor="title">Name</Label>
                          <AvField
                            name="title"
                            placeholder="Type name to search"
                            type="text"
                            className="form-control filter-field"
                            id="title-filter"
                            autoComplete="off"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-auto">
                        <FormGroup>
                          <Label htmlFor="subject-filter">Subject</Label>
                          <AvField
                            type="select"
                            name="subjectId"
                            id="subject-filter"
                            className="form-control filter-field"
                          >
                            <option value="">Select Subject</option>
                            {subjects.map((subject, index) => (
                              <option key={'_subject_' + index} value={subject._id}>
                                {subject.title} - {subject.studyLevelId}
                              </option>
                            ))}
                          </AvField>
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-auto">
                        <FormGroup>
                          <Label htmlFor="status-filter">Status</Label>
                          <AvField type="select" name="active" id="status-filter" className="form-control filter-field">
                            <option value="">Select Status</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </AvField>
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-auto agora-filter-buttons">
                        <Button
                          type="submit"
                          className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
                        >
                          Filter
                        </Button>
                        <Button
                          type="button"
                          onClick={clearFilters}
                          className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light ml-2"
                        >
                          Clear
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>

                  <div className="table-responsive">
                    <Table className="table-centered table-nowrap table-hover">
                      <thead className="thead-light font-size-15">
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Subject</th>
                          <th scope="col">Status</th>
                          {/* <th scope="col">Contributors</th> */}
                          <th className="text-center" scope="col" style={{ width: '150px' }}>
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="agora-lato">
                        {chapters.map((chapter, i) => (
                          <tr key={'_subject_' + i}>
                            <td>
                              <h5 className="font-size-14 mb-1">
                                <Link
                                  to={{ pathname: '/chapter-details', search: '?id=' + chapter._id }}
                                  className="text-dark"
                                >
                                  {chapter.title}
                                </Link>
                              </h5>
                            </td>
                            <td>
                              {chapter.subjectId && (
                                <Link
                                  to={{ pathname: '/subject-details', search: '?id=' + chapter.subjectId._id }}
                                  className="text-dark"
                                >
                                  {chapter.subjectId?.title} - {chapter.subjectId?.studyLevelId}
                                </Link>
                              )}
                            </td>
                            <td>
                              <div>
                                <SetStatusStyle status={chapter.active ? 'active' : 'inactive'} />
                              </div>
                            </td>
                            {/* <td>
                              {
                                  <div className="avatar-xs chapter-icon">
                                      <span className="avatar-title rounded-circle">
                                          {chapter.title.charAt(0)}
                                      </span>
                                  </div>
                              }
                              {
                                  <div className="avatar-xs chapter-icon">
                                      <span className="avatar-title rounded-circle">
                                          {chapter.title.charAt(1)}
                                      </span>
                                  </div>
                              }
                              {
                                  <div className="avatar-xs chapter-icon">
                                      <span className="avatar-title rounded-circle">
                                          {chapter.title.charAt(2)}
                                      </span>
                                  </div>
                              }
                              {
                                  <div className="avatar-xs chapter-icon">
                                      <span className="avatar-title rounded-circle">
                                          {chapter.title.charAt(3)}
                                      </span>
                                  </div>
                              }
                              <div className="clearfix"></div>
                          </td> */}
                            <td className="text-center">
                              <ul className="list-inline font-size-20 contact-links mb-0">
                                <li className="list-inline-item px-2">
                                  <Link
                                    to={{ pathname: '/chapter-details', search: '?id=' + chapter._id }}
                                    id={'message' + chapter._id}
                                  >
                                    <i className="bx bx-show-alt"></i>
                                    <UncontrolledTooltip placement="top" target={'message' + chapter._id}>
                                      View
                                    </UncontrolledTooltip>
                                  </Link>
                                </li>
                                <li className="list-inline-item px-2">
                                  <Link
                                    to={{ pathname: '/chapter', search: '?id=' + chapter._id }}
                                    id={'edit-chapter' + chapter._id}
                                  >
                                    <i className="bx bxs-edit-alt"></i>
                                    <UncontrolledTooltip placement="top" target={'edit-chapter' + chapter._id}>
                                      Edit
                                    </UncontrolledTooltip>
                                  </Link>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <Row>
                    <Col lg="12">
                      <Pagination className="pagination pagination-rounded justify-content-center mt-4">
                        <PaginationItem disabled={pagination.currentPage === 1}>
                          <PaginationLink
                            first
                            onClick={(e) => {
                              paginate(1);
                            }}
                          />
                        </PaginationItem>
                        <PaginationItem disabled={pagination.currentPage === 1}>
                          <PaginationLink
                            previous
                            onClick={(e) => {
                              paginate(pagination.currentPage - 1);
                            }}
                          />
                        </PaginationItem>
                        {pagination.pages.map((page, index) => (
                          <PaginationItem key={'_page_' + index} active={page === pagination.currentPage}>
                            <PaginationLink
                              onClick={(e) => {
                                paginate(page);
                              }}
                            >
                              {page}
                            </PaginationLink>
                          </PaginationItem>
                        ))}
                        <PaginationItem disabled={pagination.currentPage === pagination.totalPages}>
                          <PaginationLink
                            next
                            onClick={(e) => {
                              paginate(pagination.currentPage + 1);
                            }}
                          />
                        </PaginationItem>
                        <PaginationItem disabled={pagination.currentPage === pagination.totalPages}>
                          <PaginationLink
                            last
                            onClick={(e) => {
                              paginate(pagination.totalPages);
                            }}
                          />
                        </PaginationItem>
                      </Pagination>
                      <div className="records-count text-center">
                        <p>
                          Showing <strong>{pagination.startIndex + 1}</strong> to{' '}
                          <strong>{pagination.endIndex + 1}</strong> of <strong>{pagination.totalItems}</strong> records
                        </p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SubjectList;
