import React from 'react';
import { Spinner } from 'reactstrap';

// Import scss
import './loader.scss';

const Loader = (props) => {
  let loaderElement = '';
  if (props.loading) {
    loaderElement = (
      <div id="agora-spinner-container">
        <Spinner className="agora-spinner" color="info" />
      </div>
    );
  }
  return loaderElement;
};

export default Loader;
