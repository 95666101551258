import axios from 'axios';
import { appConfig } from '../config/config';
import authHeader, {getToken} from '../helpers/jwt-token-access/auth-token-header';

export const requestWithToken = (props) => {
    return axios.request({
        url: `${appConfig.BASE_URL}/${props.url}`,
        method: props.method,
        data: props.data,
        headers: authHeader(),
    });
}

export const request = (props) => {
    return axios.request({
        url: `${appConfig.BASE_URL}/${props.url}`,
        method: props.method,
        data: props.data,
    });
}

export const fetchRequestWithToken = (props) => {
    let token = getToken()
    // console.log("Token", token)
    // let url = `${appConfig.BASE_URL}/${props.url}`
    // console.log(url)
    return fetch(`${appConfig.BASE_URL}/${props.url}`, {
        method: props.method,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
}