import React, { useState } from 'react';
import { Row, Col, Button, FormGroup, Label } from 'reactstrap';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvField from 'availity-reactstrap-validation/lib/AvField';
//import { Editor } from '@tinymce/tinymce-react';
import { appConfig } from '../../config/config';
// import { v4 as uuid } from 'uuid';
import { ShowNotification } from '../../helpers/general/generalHelpers';
import { TinyImageUpload } from '../../helpers/general/services/image-service';
import Select from 'react-select';
import { CompositeDecorator, ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from '../../components/draftjs-to-html/index';
import {  preImageUpload, rteColors, rteFontFamily, setImageAttribute } from '../../components/Utils';
import SpecialCharPicker from '../../components/SpecialCharPicker';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from "html-to-draftjs";
import { useEffect } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';


const SubQuestion = (props) => {
    const {
        idx,
        forceUpdate,
        subQuestion,
        subQuestions,
        setSubQuestions,
        inputSampleAnswer,
        inputMarking,
        keyid,
        subSubQuestionBlock,
        question,
        subsubquestions
    } = props;
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [subEditorState, setSubEditorState] = useState([]);
    let subsubObj =subQuestions
    let key  =  Object.keys(subQuestions) || ''
    let subSubKey = subsubObj && subsubObj?.[key?.[0]] && subsubObj?.[key?.[0]]?.subsubQuestions &&  Object.keys(subsubObj?.[key?.[0]]?.subsubQuestions) || ''

    const subquestionNames = ['a', 'b', 'c', 'd', 'e'];
    let subSubQuestion = false;
    if (subQuestion['subsubquestions']) {
        subSubQuestion = subQuestion['subsubquestions'];
    }
    const addSubSubQuestion = (idx) => {
        let newSubQuestions = subQuestions;
        let temp ={
            1: {
                // id: uuid(),
                body: '',
                marks: 0,
                sampleAnswer: '',
                markingAttachment: '',
                answer: false,
            }
        }

        // Adding the newSubObject to the subsubQuestions key
        newSubQuestions[key[0]].subsubQuestions = temp;
        setSubQuestions(newSubQuestions)
        forceUpdate();
    };

    const removeSubQuestion = (idx) => {
        if (subQuestions.length > 0) {
            let newSubQuestions = subQuestions;
            newSubQuestions.splice(idx, 1);
            setSubQuestions(newSubQuestions);
            forceUpdate();
        } else {
            ShowNotification({ type: 'warning', message: 'Add Subquestions first.' });
        }
    };
    const removeSubSubQuestion = (idx, subIdx) => {
        if (subQuestions.length > 0) {
            if (subQuestions[idx]) {
                if (subQuestions[idx].subQuestions[subIdx]) {
                    let newSubQuestions = subQuestions;
                    newSubQuestions[idx].subQuestions.splice(subIdx, 1);
                    setSubQuestions(newSubQuestions);
                    forceUpdate();
                }
            }
        } else {
            ShowNotification({ type: 'warning', message: 'Add Sub-Subquestions first.' });
        }
    };




    const [selectedOption, setSelectedOption] = useState();
    const [SubQuestionOption, setSubQuestionOption] = useState([
        { value: 'a', label: 'a' },
        { value: 'b', label: 'b' },
        { value: 'c', label: 'c' },
        { value: 'd', label: 'd' },
        { value: 'e', label: 'e' },
        { value: 'f', label: 'f' },
        { value: 'g', label: 'g' },
        { value: 'h', label: 'h' },
        { value: 'i', label: 'i' },
        { value: 'j', label: 'j' },
    ]);

    const [selectedSubSubOption, setSelectedSubSubOption] = useState({label: subSubKey?.[0] || '', value: subSubKey?.[0] || ''});
    const [SubSubQuestionOption, setSubSubQuestionOption] = useState([
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
    ]);
    useEffect(()=>{
        setEditorState(subQuestion.body? EditorState.createWithContent(
            ContentState.createFromBlockArray(
               // htmlToDraft(moveImgToFigure(subQuestion.body))
               htmlToDraft((subQuestion.body), setImageAttribute)
            )
            //decorator
          ):EditorState.createEmpty())
          
        if(subSubQuestion !== undefined ){//&& subSubQuestion.length>0
            let subState = []
            Object.keys(subSubQuestion).map((key, idx)=>{
                let newData = subSubQuestion[key].body?
                EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  //convertFromHTML(moveImgToFigure(subSubQuestion[key].body))
                  htmlToDraft((subSubQuestion[key].body), setImageAttribute)
                )
              ):EditorState.createEmpty()
              subState[key]=newData
            })
            setSubEditorState(subState)
        }
          
    },[])
    return (
        <div style={{ backgroundColor: '#F5F8FC', padding: '5px' }} className="section-bottom">
            <Row className="mx-1">
                <Col md="4" className="align-self-center">
                    <span className="my-2 text-info">{!subSubQuestionBlock ? `Subquestion ${selectedOption?.value || 'a'}` : `SubSubquestion ${selectedSubSubOption?.value || '1'}`}</span>
                </Col>

                <Col md="4" style={{zIndex:1000}}>
                <FormGroup>
                    {/* {console.log(subQuestions[Object.keys(subQuestions)[0]].subsubQuestions[Object.keys(subQuestions[Object.keys(subQuestions)[0]].subsubQuestions)] , 'subbbbkey_______________')} */}
                  <Select
                    value={!subSubQuestionBlock ? selectedOption : selectedSubSubOption}
                    // isLoading={loader}
                    isSearchable
                    name="subjectSpeciality"
                    placeholder="Enter key"
                    options={!subSubQuestionBlock ? SubQuestionOption : SubSubQuestionOption }
                    className='mt-1'
                    onChange={(value) => {
                        if(!subSubQuestionBlock){
                            setSelectedOption(value)
                             const newObj = {
                               [value.label]: subQuestions[key[0]]
                             };
                             setSubQuestions(newObj)
                             forceUpdate();

                        }else{
                            setSelectedSubSubOption(value)
                            const newSubsubQuestions = {
                                [value.label]: {
                                    // id: uuid(),
                                    body: subsubObj[key[0]].subsubQuestions[subSubKey?.[0]]?.body || '',
                                    marks: subsubObj[key[0]].subsubQuestions[subSubKey?.[0]]?.marks || '',
                                    sampleAnswer: '',
                                    markingAttachment: '',
                                    answer: true,
                                }
                            };
                            subsubObj[key[0]].subsubQuestions = newSubsubQuestions;
                             setSubQuestions(subsubObj)
                             forceUpdate();
                    }
                    forceUpdate();
                    }}
                  />
                </FormGroup>
                </Col>


                <Col md="4">
                    <AvForm className="my-2 text-info text-right">
                        <AvField
                            required
                            name={'sub-question-marks_' + idx}
                            value={!subSubQuestionBlock ? subQuestion?.marks : subQuestions[Object.keys(subQuestions)[0]].subsubQuestions[Object.keys(subQuestions[Object.keys(subQuestions)[0]].subsubQuestions)]?.marks}
                            placeholder="Add Mark(s)"
                            type="number"
                            className="form-control filter-field"
                            onChange={(ev) => {



                                if(!subSubQuestionBlock){
                                    let newSubQuestions = subQuestions;
                                newSubQuestions[key[0]].marks = +ev.target.value;
                                setSubQuestions(newSubQuestions);
                                     forceUpdate();

                                }else{
                                    subsubObj[key[0]].subsubQuestions[subSubKey?.[0]].marks = +ev.target.value;
                                    setSubQuestions(subsubObj);
                                    forceUpdate();
                            }
                            }}
                            id={'sub-question-marks_' + idx}
                        />
                    </AvForm>
                </Col>
            </Row>
            <span className="custom-control custom-checkbox mt-1">
                {/* <Editor
                    {...appConfig.tinyRTE}
                    // initialValue={subQuestion.body}
                    value={subQuestion.body}
                    init={{
                        height: 200,
                        menubar: false,
                        images_upload_handler: (blobInfo, success, failure) => {
                            TinyImageUpload(blobInfo, success, failure, props.setLoader);
                        }
                    }}
                    onEditorChange={(content, editor) => {
                        if(!subSubQuestionBlock){
                            let newSubQuestions = subQuestions;
                            newSubQuestions[keyid].body = content;
                            setSubQuestions(newSubQuestions);
                            forceUpdate();
                        }else{
                            // let subsubObj =subQuestions
                            let key =  Object.keys(subQuestions)
                            // let subSubKey = Object.keys(subsubObj[key[0]].subsubQuestions)
                            subsubObj[key[0]].subsubQuestions[subSubKey[0]].body = content;
                            setSubQuestions(subsubObj);
                            forceUpdate();
                        }
                    }}
                /> */}
<Editor
                        editorState={editorState}
                        onEditorStateChange={(newEditorState)=>{
                            if(!subSubQuestionBlock){
                                let newSubQuestions = subQuestions;
                                const htmlContent = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
                                newSubQuestions[keyid].body = htmlContent;
                                setSubQuestions(newSubQuestions);
                                setEditorState(newEditorState)
                                forceUpdate();
                            }else{
                                // let subsubObj =subQuestions
                                let key =  Object.keys(subQuestions)
                                const htmlContent = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
                                subsubObj[key[0]].subsubQuestions[subSubKey[0]].body = htmlContent;
                                setSubQuestions(subsubObj);
                                setEditorState(newEditorState)
                                forceUpdate();
                            }

                        }}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        toolbar={{
                        image: {
                            uploadEnabled: true,
                            alignmentEnabled: true,
                            uploadCallback: preImageUpload,
                            previewImage: true,
                            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                            defaultSize: {
                            height: 'auto',
                            width: 'auto',
                            },
                        },
                        colorPicker: { 
                            colors: rteColors
                          },
                          fontFamily: {
                            options: rteFontFamily,
                          },
                        }}
                        toolbarCustomButtons={[<SpecialCharPicker />]}
                    />

            </span>
            {!subSubQuestionBlock && (
            <Button
                type="button"
                color="link"
                className="btn btn-link waves-effect ml-4"
                onClick={() => {
                    // if (subQuestion.subQuestions?.length < 5) {
                        addSubSubQuestion(idx);
                    // } else {
                        // ShowNotification({ type: 'warning', message: 'Maximum 5 Sub-Subquestion are allowed.' });
                    // }
                }}
            >
                <i className="bx font-size-20 bxs-plus-square"></i>
                <span style={{ verticalAlign: 'top' }} className="font-size-15">
                    {' '}
                    Add Sub-Subquestion{' '}
                </span>
            </Button>
            )}

            {subQuestion?.subQuestions?.length < 1 && (
                <React.Fragment>
                    <Button
                        type="button"
                        color="link"
                        className="btn btn-link waves-effect"
                        onClick={() => {
                            inputSampleAnswer(idx);
                        }}
                    >
                        <i className={`bx font-size-20 ${subQuestion.sampleAnswer ? 'bx-check-circle' : 'bxs-conversation'}`}></i>
                        <span style={{ verticalAlign: 'top' }} className="font-size-15">
                            {' '}
                            Sample Answer{' '}
                        </span>
                    </Button>
                    <Button
                        type="button"
                        color="link"
                        className="btn btn-link waves-effect"
                        onClick={() => {
                            inputMarking(idx);
                        }}
                    >
                        <i
                            className={`bx font-size-20 ${subQuestion?.markingAttachment ? 'bx-badge-check' : 'bxs-badge-check'}`}
                        ></i>
                        <span style={{ verticalAlign: 'top' }} className="font-size-15">
                            {' '}
                            Marking{' '}
                        </span>
                    </Button>
                </React.Fragment>
            )}

            <Button type="button" color="link" className="btn btn-link waves-effect" onClick={() => removeSubQuestion(idx)}>
                <i className="bx font-size-24 bxs-trash text-danger"></i>
            </Button>
            {subSubQuestion &&
                Object.keys(subSubQuestion)?.map((key, subIdx) => {
                    // debugger; key={subQuestions[key].id}
                    return (
                        <div key={subSubQuestion[key].id} style={{ backgroundColor: '#FEF6FA' }} className="section-bottom">
                            <Row className="mx-1">
                                <Col md="6" className="align-self-center">
                                    <span className="my-2 text-pink">{`Sub-Subquestion (${subquestionNames[idx]})(${subIdx + 1})`}</span>
                                </Col>
                                <Col md="6">
                                    <AvForm className="my-2 text-info text-right">
                                        <AvField
                                            required
                                            name={'sub-question-marks_' + subIdx}
                                            value={subSubQuestion.marks}
                                            placeholder="Add Mark(s)"
                                            type="number"
                                            className="form-control filter-field"
                                            onChange={(ev) => {
                                                let newSubQuestions = subQuestions;
                                                newSubQuestions[idx].subQuestions[subIdx].marks = +ev.target.value;
                                                setSubQuestions(newSubQuestions);
                                                forceUpdate();
                                            }}
                                            id={'sub-question-marks_' + subIdx}
                                        />
                                    </AvForm>
                                </Col>
                            </Row>
                            <span className="custom-control custom-checkbox mt-1">
                                {/* <Editor
                                    {...appConfig.tinyRTE}
                                    // initialValue={subSubQuestion[key].body}
                                    value={subSubQuestion[key].body}
                                    init={{
                                        height: 200,
                                        menubar: false,
                                        images_upload_handler: (blobInfo, success, failure) => {
                                            TinyImageUpload(blobInfo, success, failure, props.setLoader);
                                        }
                                    }}


                                    onEditorChange={(content, editor) => {
                                        // let newSubQuestions = subQuestions;
                                        // newSubQuestions[idx].subQuestions[subIdx].body = content;
                                        // setSubQuestions(newSubQuestions);
                                        // forceUpdate();
                                        let newSubQuestions = subQuestions;
                                        newSubQuestions[keyid]['subsubquestions'][key].body = content;
                                        setSubQuestions(newSubQuestions);
                                        forceUpdate()
                                    }}
                                /> */}
                                <Editor
                                    editorState={subEditorState[key]}
                                    onEditorStateChange={(newSubEditorState)=>{
                                        let newSubQuestions = subQuestions;
                                        console.log(newSubEditorState)
                                        const htmlContent =draftToHtml(convertToRaw(newSubEditorState.getCurrentContent()));
                                        newSubQuestions[keyid]['subsubquestions'][key].body = htmlContent;
                                        setSubQuestions(newSubQuestions);
                                        let sEState = subEditorState;
                                        sEState[key] = newSubEditorState;
                                        setSubEditorState(sEState)
                                        forceUpdate()
                                    }}
                                    wrapperClassName="wrapper-class"
                                    editorClassName="editor-class"
                                    toolbarClassName="toolbar-class"
                                    toolbar={{
                                    image: {
                                        uploadEnabled: true,
                                        alignmentEnabled: true,
                                        uploadCallback: preImageUpload,
                                        previewImage: true,
                                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                        defaultSize: {
                                        height: 'auto',
                                        width: 'auto',
                                        },
                                    },
                                    colorPicker: { 
                                        colors: rteColors
                                      },
                                      fontFamily: {
                                        options: rteFontFamily,
                                      },
                                    }}
                                    toolbarCustomButtons={[<SpecialCharPicker />]}
                                />
                            </span>
                            <Button
                                type="button"
                                color="link"
                                className="btn btn-link waves-effect m3-5"
                                onClick={() => {
                                    inputSampleAnswer(idx, subIdx);
                                }}
                            >
                                <i
                                    className={`bx font-size-20 ${subSubQuestion.sampleAnswer ? 'bx-check-circle' : 'bxs-conversation'}`}
                                ></i>
                                <span style={{ verticalAlign: 'top' }} className="font-size-15">
                                    {' '}
                                    Sample Answer{' '}
                                </span>
                            </Button>
                            <Button
                                type="button"
                                color="link"
                                className="btn btn-link waves-effect"
                                onClick={() => {
                                    inputMarking(idx, subIdx);
                                }}
                            >
                                <i
                                    className={`bx font-size-20 ${subSubQuestion?.markingAttachment ? 'bx-badge-check' : 'bxs-badge-check'
                                        }`}
                                ></i>
                                <span style={{ verticalAlign: 'top' }} className="font-size-15">
                                    {' '}
                                    Marking{' '}
                                </span>
                            </Button>
                            <Button
                                type="button"
                                color="link"
                                className="btn btn-link waves-effect"
                                onClick={() => removeSubSubQuestion(idx, subIdx)}
                            >
                                <i className="bx font-size-24 bxs-trash text-danger"></i>
                            </Button>
                        </div>
                    );
                })}
        </div>
    );
};
export default SubQuestion;
