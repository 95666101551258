import React from 'react';

// Toasters for showing notifications
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

toastr.options = {
  positionClass: 'toast-top-right',
  timeOut: 3000,
  closeButton: true,
  progressBar: false,
  preventDuplicates: true,
  newestOnTop: true,
  showEasing: 'linear',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut'
  // showDuration : showDuration,
  // hideDuration : hideDuration
};

function SetStatusStyle(props) {
  if (props.status) {
    const status = props.status.toLowerCase();
    if (status === 'active' || status === 'done') {
      return <span className="badge badge-pill badge-soft-success font-size-12 text-capitalize">{props.status}</span>;
    } else if (status === 'inactive' || status === 'declined') {
      return <span className="badge badge-pill badge-soft-danger font-size-12 text-capitalize">{props.status}</span>;
    } else if (status === 'pending') {
      return <span className="badge badge-pill badge-soft-warning font-size-12 text-capitalize">{props.status}</span>;
    } else if (status === 'accepted') {
      return <span className="badge badge-pill badge-soft-info font-size-12 text-capitalize">{props.status}</span>;
    }
  } else {
    return '';
  }
}

function SetSyllabusType(props) {
  if (props.type) {
    const type = props.type.toLowerCase();
    if (type === 'assessment') {
      return <span className="badge badge-pill badge-soft-info font-size-12 text-capitalize">{props.type}</span>;
    } else if (type === 'chapter') {
      return <span className="badge badge-pill badge-soft-primary font-size-12 text-capitalize">{props.type}</span>;
    }
  } else {
    return '';
  }
}

function ShowNotification(options) {
  if (options.type === 'success') {
    toastr.success(options.message, options.title);
  } else if (options.type === 'error') {
    toastr.error(options.message, options.title);
  } else if (options.type === 'info') {
    toastr.info(options.message, options.title);
  } else if (options.type === 'warning') {
    toastr.warning(options.message, options.title);
  }
}

function GetQueryParam(queryString, paramName) {
  let params = new URLSearchParams(queryString);
  return params.get(paramName);
}

function GetRandomNumber() {
  return Math.random() * 100000000000000000;
}

function ObjectHasValues(obj) {
  for (let key in obj) {
    if (obj[key]) {
      return true;
    }
  }
  return false;
}

function GetFilterString(filters) {
  let filterString = '';
  if (filters) {
    for (let filterType in filters) {
      if (filters[filterType] !== '') {
        filterString += `&${filterType}=${filters[filterType]}`;
      }
    }
  }
  return filterString;
}

export {
  SetStatusStyle,
  SetSyllabusType,
  ShowNotification,
  GetQueryParam,
  GetRandomNumber,
  ObjectHasValues,
  GetFilterString
};
