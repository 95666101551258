import axios from 'axios';
import authHeader from '../../../helpers/jwt-token-access/auth-token-header';
import {
  GetFilterString
} from '../../../helpers/general/generalHelpers';
import {
  appConfig
} from '../../../config/config';
import {
  user,
  userRoles
} from '../../../helpers/user/userInfo';

function getTasks(pageNumber, filters, pageSize = appConfig.RecordsPerPage) {
  let filterOptions = filters;

  if (user.user.role === userRoles.assessor) {
    filterOptions = {
      ...filters,
      assignee: user.user._id
    };
  }

  let filterString = GetFilterString(filterOptions);
  let url = `/task?pageNumber=${pageNumber}&pageSize=${pageSize}${filterString}`;

  if (pageNumber === null) {
    url = `/task?${filterString.slice(1, filterString.length)}`;
  }

  return axios.get(url, {
    headers: authHeader()
  });
}

function getTask(id) {
  return axios.get('/task/' + id, {
    headers: authHeader()
  });
}

function updateTask(id, taskData) {
  return axios.put('/task/' + id, taskData, {
    headers: authHeader()
  });
}

// Need to relocate this later
function getQuestionsByIds(questionIds) {
  let requests = questionIds.map((id) =>
    axios.get('/question/' + id, {
      headers: authHeader()
    })
  );
  return axios.all(requests);
}

function deleteTask(id) {
  return axios.delete('/task/' + id, { headers: authHeader() });
}

export {
  getTask,
  getTasks,
  updateTask,
  getQuestionsByIds,
  deleteTask
};