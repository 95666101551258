import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
  Label,
  Button
} from 'reactstrap';

import PageTitle from '../../components/Common/PageTitle';
import { SetStatusStyle, ShowNotification, ObjectHasValues, GetQueryParam } from '../../helpers/general/generalHelpers';
import Loader from '../../shared/loader/loader';
import { getPaginationInformation, initialPaginationObject } from '../../helpers/general/pagination';
import { getRoles, getRolePrivileges, updateRolePrivileges } from './Services/role-service';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import PopupAddReward from '../Referrals/addReward';
import {allRoutes} from "../../routes/allRoutes";

const RolePrivileges = (props) => {

  const [roles, setRoles] = useState([]);
  const [loader, setLoader] = useState(true);

  if (props.location.notification && props.location.notification.show) {
    const notification = props.location.notification;
    ShowNotification({
      type: 'success',
      message: notification.message
    });
    props.location.notification = null;
  }

  const handleCheckboxChange = (moduleIndex, childIndex, isChecked) => {
    const newNavRoutes = [...roles];
    newNavRoutes[moduleIndex].children[childIndex].isAllowed = isChecked;
    setRoles(newNavRoutes);

    console.log(roles)
  };

  const updatePrivileges = ()=>{
    setLoader(true);
    const roleId = GetQueryParam(props.location.search, 'id');
    
    const filteredRoles = roles.flatMap((module) => 
      module.children.map((child) => ({
        parent: module.name,
        name: child.name,
        isAllowed: child.isAllowed,
        roleId: roleId,
        type: "ui"
      }))
    );
    
    updateRolePrivileges(roleId, "ui", filteredRoles).then((response)=>{

    }).catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
  }

  useEffect(() => {
    setLoader(true);
    
    const roleId = GetQueryParam(props.location.search, 'id');
    getRolePrivileges(roleId)
      .then((response) => {
        
        const remoteNavs = response.data.rolePermission;//.data.roles;
        const navRoutes = allRoutes;

        const updatedNavRoutes = navRoutes.map((module) => {
          const updatedModule = { ...module };
          updatedModule.children = module.children.map((child) => {
            const updatedChild = { ...child };
            const matchingRemoteNav = remoteNavs.find(
              (remoteNav) => remoteNav.parent === module.name && remoteNav.routePath === child.name
            );
        
            updatedChild.isAllowed = !!matchingRemoteNav;
        
            return updatedChild;
          });
        
          return updatedModule;
        });
        
        setRoles( updatedNavRoutes );
        
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="relative">
          <Loader loading={loader} />
          <Row>
            <Col xs="12">
              <div className="d-flex mb-2 align-items-center justify-content-between">
                <h4 className="mb-0 p-1 mr-auto font-size-20 font-weight-bold">Role Privileges</h4>
                <div className="">
                  <Button
                  type="button"
                  className="m-1 font-size-12 px-4 btn btn-primary agora-button border-radius waves-effect waves-light"
                  onClick={(e)=>updatePrivileges()}
                >
                  Update
                </Button>
                </div>
              </div>
            </Col>
          </Row>

          

          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="p-10">
                 
                  <div className="table-responsive mt-2">
                    <Table className="table-centered table-nowrap table-hover text-center">
                      <thead className="thead-light font-size-15">
                        <tr>
                          <th scope="col" className="text-left">
                            Name
                          </th>
                          <th scope="col">Allow</th>
                        </tr>
                      </thead>
                      <tbody className="agora-lato">
                        {
                           roles.map((role, moduleIndex) => (
                            <React.Fragment key={'_role_privilege_' + moduleIndex}>
                              <tr>
                                <th className="text-left">{role.description}</th>
                                <th></th>
                              </tr>
                              {role.children.map((srole, childIndex) => (
                                <tr key={'_role_privilege_' + moduleIndex + '_' + childIndex}>
                                  <td className="text-left">{srole.description}</td>
                                  <td>
                                    <div className="custom-control status-switch custom-switch mb-2" dir="ltr">
                                      <input type="checkbox" className="custom-control-input" id={"roleStatus"+moduleIndex+'_'+childIndex} 
                                      checked={srole.isAllowed}
                                      onChange={(e) =>
                                        handleCheckboxChange(moduleIndex, childIndex, e.target.checked)
                                        
                                      } />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={"roleStatus"+moduleIndex+'_'+childIndex}
                                        
                                      >
                                        
                                      </label>
                                    </div>

                                  </td>
                                </tr>
                              ))}
                            </React.Fragment>
                          ))
                        }
                        
                      </tbody>
                    </Table>
                  </div>
                  
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      
    </React.Fragment>
  );
};

export default RolePrivileges;
