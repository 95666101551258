import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import { Row, Col, FormGroup, Label, Button, Modal } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import { ShowNotification } from '../../helpers/general/generalHelpers';
import { postAddStudyGuide, putUpdateStudyGuide } from '../../service/studyGuide';
import { getListSubjects } from '../../service/subject';
import { getListChapter } from '../../service/chapter';

const tempStatus = [
  {
    id: true,
    value: true,
    label: 'Active'
  },
  {
    id: false,
    value: false,
    label: 'Inactive'
  }
];

const PopupAddStudyGuide = (props) => {
  const history = useHistory();

  const [loader, setLoader] = useState(true);
  const [chapterloader, setChapterLoader] = useState(false);

  const [modalOpen, setModalOpen] = useState(props.show);

  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState();

  const [chapters, setChapters] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState();

  const [selectedStatus, setSelectedStatus] = useState(true);

  const [defaultValue, setDefaultValue] = useState({ title: '' });

  const handleGetSubjets = () => {
    getListSubjects({ pageSize: 100 })
      .then((response) => {
        const subjList = response.data.subjects.map((subj) => {
          return { ...subj, id: subj._id, value: subj.title, label: subj.title + ' - ' + subj.studyLevelId };
        });
        setSubjects(subjList);
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const toggleModal = () => {
    props.toggleModal();
  };

  const reuqestAddStudyGuide = (value) => {
    const requestBody = {
      order: value.order,
      title: value.title,
      studyLevelId: selectedSubject.studyLevelId,
      subject: selectedSubject.id,
      chapter: selectedChapter.id,
      content: ['Page 1'],
      questions: [],
      active: true
    };

    postAddStudyGuide({ body: requestBody })
      .then((response) => {
        const studyGuideId = response.data.studyGuide._id;
        history.push(`/studyguide?id=${studyGuideId}`);
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const reuqestUpdateStudyGuide = (value) => {
    const requestBody = {
      order: value.order,
      title: value.title,
      studyLevelId: selectedSubject.studyLevelId,
      subject: selectedSubject.id,
      chapter: selectedChapter.id,
      active: selectedStatus.value
    };

    putUpdateStudyGuide({ id: props.edit?._id, body: requestBody })
      .then((response) => {
        props.UpdateContent();
        toggleModal();
      })
      .catch((error) => {
        ShowNotification({
          type: 'error',
          message: error.message
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleSubmitFrom = (e, v) => {
    if (selectedChapter && selectedSubject && v.title !== '') {
      if (props.edit) {
        reuqestUpdateStudyGuide(v);
      } else {
        reuqestAddStudyGuide(v);
      }
    } else {
      ShowNotification({
        type: 'error',
        message: 'Please fill all fields.'
      });
    }
  };

  const handleGetChapters = (value) => {
    setChapters([]);
    getListChapter({ filters: { subjectId: value.id }, pageSize: 99999 })
      .then((response) => {
        const chapList = response.data.chapters.map((chap) => {
          return { id: chap._id, value: chap.title, label: chap.title };
        });
        setChapters(chapList);
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setChapterLoader(false);
      });
  };

  const onChangeSubject = (value) => {
    setSelectedSubject(value);
    setChapterLoader(true);
    handleGetChapters(value);
  };

  const handleAutoFillForm = () => {
    const chap = props.edit?.chapter || {};
    const subj = props.edit?.subject || {};
    const stat = props.edit.active;
    setDefaultValue({ title: props.edit.title });
    setSelectedStatus(stat ? tempStatus[0] : tempStatus[1]);
    setSelectedSubject({ id: subj._id, value: subj.title, label: `${subj.title} - ${props.edit.studyLevelId}` });
    setSelectedChapter({ id: chap._id, value: chap.title, label: chap.title });
    handleGetChapters({ id: subj._id });
  };

  useEffect(() => {
    setModalOpen(props.show);
    if (props.show && subjects.length === 0) {
      handleGetSubjets();
    }

    if (!props.edit) {
      setSelectedChapter(null);
      setSelectedSubject(null);
    } else {
      handleAutoFillForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  return (
    <Modal
      centered
      isOpen={modalOpen}
      toggle={() => {
        toggleModal();
      }}
    >
      <div className="modal-body overflow-hidden">
        <AvForm
          model={defaultValue}
          className="needs-validation"
          onValidSubmit={(e, v) => {
            handleSubmitFrom(e, v);
          }}
        >
          <h4 className="card-title">{props.edit ? 'Edit Study Guide' : 'Add New Study Guide'}</h4>
          <p className="card-title-desc">Fill all information below</p>
          <Row>
            <FormGroup className="col-md-12">
              <Col>
                <Label>Order</Label>
                <AvField name="order" placeholder="Enter Order" className="form-control filter-field" />
              </Col>
              <Col>
                <Label>Title</Label>
                <AvField name="title" placeholder="Enter Title" className="form-control filter-field" />
              </Col>
              <Col className="mb-2">
                <Label>Subject Name</Label>
                <Select
                  value={selectedSubject}
                  isLoading={loader}
                  isSearchable
                  name="subject"
                  placeholder="Enter Subject Name"
                  options={subjects}
                  onChange={(value) => {
                    if (value?.id !== selectedSubject?.id) {
                      setSelectedChapter(null);
                      onChangeSubject(value);
                    }
                  }}
                />
              </Col>
              <Col className="mb-2">
                <Label>Chapter Name</Label>
                <Select
                  value={selectedChapter}
                  isLoading={chapterloader}
                  isSearchable
                  name="chapter"
                  placeholder="Enter Chapter Name"
                  options={chapters}
                  onChange={(value) => {
                    setSelectedChapter(value);
                  }}
                />
              </Col>
              {props.edit && (
                <Col className="mb-2">
                  <Label>Status</Label>
                  <Select
                    value={selectedStatus}
                    name="status"
                    placeholder="Enter Status"
                    options={tempStatus}
                    onChange={(value) => {
                      setSelectedStatus(value);
                    }}
                  />
                </Col>
              )}
            </FormGroup>
          </Row>

          <Row>
            <Col lg="12" className="agora-form-buttons text-center pt-4">
              <Button
                onClick={() => {
                  toggleModal();
                }}
                className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
              >
                {props.edit ? 'Save' : 'Next'}
              </Button>
            </Col>
          </Row>
        </AvForm>
      </div>
    </Modal>
  );
};

export default PopupAddStudyGuide;
