import React, { useState, useEffect } from 'react';
import { Row, Col, FormGroup, Label, Button, Modal } from 'reactstrap';

import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvRadio from 'availity-reactstrap-validation/lib/AvRadio';
import AvRadioGroup from 'availity-reactstrap-validation/lib/AvRadioGroup';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import { ShowNotification } from '../../helpers/general/generalHelpers';
import { useHistory } from 'react-router-dom';
import { getSubjects } from '../Subjects/Services/subject-service';
import Select from 'react-select';
import { getChapters } from '../Chapters/Services/chapter-service';
import moment from 'moment';

const PopupAddEditQuestions = (props) => {
  const [loader, setLoader] = useState(true);
  const [chapterloader, setChapterLoader] = useState(false);

  const [modalOpen, setModalOpen] = useState(props.show);

  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState();

  const [chapters, setChapters] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState();

  const [selectedDifficulty, setSelectedDifficulty] = useState('Easy');
  const [selectedType, setSelectedType] = useState('MCQ');

  const [paperNumber, setPaperNumber] = useState('1');
  // const [selectedPaperNumber, setSelectedPaperNumber] = useState(); //number

  const [selectedYear, setSelectedYear] = useState(`${new Date().getFullYear()}`); //number
  const [selectedQuestionNumber, setSelectedQuestionNumber] = useState(`Q${new Date().getTime().toString()}`); //String
  const [selectedVersionNumber, setSelectedVersionNumber] = useState('V1'); //String
  // const [selectedSession, setSelectedSession] = useState(`S${moment(new Date()).format('YY')}`); //String
  const [selectedSession, setSelectedSession] = useState('May/June'); //String
  const [selectedMarkingScheme, setSelectedMarkingScheme] = useState(''); //String
  const [internalId, setInternalId] = useState('');

  const [, update] = useState(0);
  const { question, setQuestion } = props;
  const difficulties = [
    { value: 'Easy', label: 'Easy' },
    { value: 'Medium', label: 'Medium' },
    { value: 'Hard', label: 'Hard' }
  ];
  const paperNumbersOptions = [
    {
      'id': '1',
      'value': '1',
      'label': '1'
    },
    {
      'id': '2',
      'value': '2',
      'label': '2'
    },
    {
      'id': '3',
      'value': '3',
      'label': '3'
    },
    {
      'id': '4',
      'value': '4',
      'label': '4'
    },
    {
      'id': '5',
      'value': '5',
      'label': '5'
    },
    {
      'id': '6',
      'value': '6',
      'label': '6'
    }
  ];
  const forceUpdate = () => {
    update((n) => !n);
  };

  const history = useHistory();

  const getHandleChapters = (data) => {
    getChapters(1, { subjectId: data ? data.id : selectedSubject.id })
      .then((response) => {
        const chapList = response.data.chapters.map((chap) => {
          return { id: chap._id, value: chap.title, label: chap.title };
        });
        setChapters(chapList);
        if (props.edit) {
          const tempChapList = chapList.find((item) => item.id === question.chapter._id);
          setSelectedChapter(tempChapList);
        }
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setChapterLoader(false);
      });
  };

  useEffect(() => {
    setModalOpen(props.show);
    if (props.show && subjects.length === 0) {
      getSubjects(undefined, undefined, 100)
        .then((response) => {
          const subjList = response.data.subjects.map((subj) => {
            return { ...subj, id: subj._id, value: subj.title, label: subj.title + ' - ' + subj.studyLevelId };
          });
          setSubjects(subjList);
          if (props.edit) {
            // setSelectedDifficulty(question.difficulty);
            const tempSelectSubject = subjList.find((item) => item.id === question.subject._id);
            setSelectedSubject(tempSelectSubject);
            getHandleChapters(tempSelectSubject);
          }
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => {
          setLoader(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  useEffect(() => {
    if (selectedSubject) {
      setChapterLoader(true);
      setChapters(undefined);
      setSelectedChapter(undefined);
      forceUpdate();
      getHandleChapters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubject]);

  function toggleModal() {
    props.toggleModal();
  }

  function formValid() {
    if (props.edit) {
      return selectedSubject && selectedChapter;
    } else {
      return selectedType && selectedSubject && selectedChapter;
    }

    // if (props.edit) {
    //   return selectedSubject && selectedChapter && selectedDifficulty;
    // } else {
    //   return selectedType && selectedSubject && selectedChapter && selectedDifficulty;
    // }
  }

  return (
    <Modal
      isOpen={modalOpen}
      toggle={() => {
        toggleModal();
      }}
      centered
    >
      <div className='modal-body'>
        <AvForm
          className='needs-validation'
          onValidSubmit={(e, v) => {
            if (formValid()) {
              if (props.edit) {
                setQuestion({
                  ...question,
                  subject: selectedSubject,
                  chapter: selectedChapter,
                  difficulty: selectedDifficulty
                });
                // debugger;
                props.toggleModal();
              } else {
                history.push(selectedType.toLowerCase() === 'mcq' ? '/mcq-question' : '/structured-question', {
                  type: selectedType,
                  subject: selectedSubject,
                  chapter: selectedChapter,
                  difficulty: selectedDifficulty,
                  year: selectedYear,
                  paperNumber: paperNumber,
                  questionNumber: selectedQuestionNumber,
                  versionNumber: selectedVersionNumber,
                  session: selectedSession,
                  markingSchemeId: selectedMarkingScheme,
                  internalId: internalId
                });
                // debugger;
              }
            } else {
              ShowNotification({
                type: 'error',
                message: 'Please fill all fields.'
              });
              // debugger;
            }
          }}
        >
          <h4 className='card-title'>{props.edit ? 'Edit Question' : 'Add New Question'}</h4>
          <p className='card-title-desc'>Fill all information below</p>
          <Row>
            <FormGroup className='col-md-12'>
              {!props.edit && (
                <Col>
                  <Label>Type</Label>
                  <AvRadioGroup
                    inline
                    name='type'
                    required
                    value={selectedType}
                    onChange={(e, value) => {
                      if (value) {
                        setSelectedType(value);
                      }
                    }}
                  >
                    <AvRadio label='MCQ' value='MCQ' />
                    <AvRadio style={{ marginLeft: '35px' }} label='Structured' value='Structured' />
                  </AvRadioGroup>
                </Col>
              )}
              <Col className='mb-2'>
                <Label>Subject Name</Label>
                <Select
                  value={selectedSubject}
                  isLoading={loader}
                  isSearchable
                  // isMulti
                  name='subject'
                  options={subjects}
                  onChange={(value) => {
                    if (value) {
                      setSelectedSubject(value);
                    }
                  }}
                />
              </Col>
              <Col className='mb-2'>
                <Label>Chapter Name</Label>
                <Select
                  isLoading={chapterloader}
                  value={selectedChapter}
                  isSearchable
                  name='chapter'
                  options={chapters}
                  onChange={(value) => {
                    setSelectedChapter(value);
                  }}
                />
              </Col>
              {/* <Col className="mb-2">
                <Label>Difficulty Level</Label>
                <Select
                  name="difficulty"
                  options={difficulties}
                  value={selectedDifficulty}
                  onChange={(value) => {
                    setSelectedDifficulty(value);
                  }}
                />
              </Col> */}
              {/* <Col className="mb-2">
                <Label>Year</Label>
                <AvField
                  value={selectedYear}
                  isLoading={loader}
                  name="year"
                  placeholder="Year"
                  disabled
                  onChange={(event) => setSelectedYear(event.target.value)}
                />
              </Col> */}
              {selectedType !== 'MCQ' ?
              <Col className='mb-2'>
                <Label>Paper Number</Label>
                  {/*<AvField*/}
                  {/*  value={paperNumber}*/}
                  {/*  isLoading={loader}*/}
                  {/*  name='papernumber'*/}
                  {/*  placeholder='papernumber'*/}
                  {/*  disabled*/}
                  {/*/>*/}
                  {/*:*/}
                  <Select
                    value={paperNumber}
                    isLoading={loader}
                    isSearchable
                    name='subject'
                    options={paperNumbersOptions}
                    onChange={(value) => {
                      if (value) {
                        setPaperNumber(value);
                      }
                    }}
                  />

              </Col> : <></> }
              {/* <Col className="mb-2">
                <Label>Question Number</Label>
                <AvField
                  value={selectedQuestionNumber}
                  isLoading={loader}
                  name="question number"
                  placeholder="Question Number"
                  onChange={(event) => setSelectedQuestionNumber(event.target.value)}
                />
              </Col> */}
              {/*<Col className='mb-2'>*/}
              {/*  <Label>Version Number</Label>*/}
              {/*  <AvField*/}
              {/*    value={selectedVersionNumber}*/}
              {/*    isLoading={loader}*/}
              {/*    name='version number'*/}
              {/*    placeholder='Version Number'*/}
              {/*    disabled*/}
              {/*    // onChange={(event) => setSelectedVersionNumber(event.target.value)}*/}
              {/*  />*/}
              {/*</Col>*/}
              {/*<Col className='mb-2'>*/}
              {/*  <Label>Session</Label>*/}
              {/*  <AvField*/}
              {/*    value={selectedSession}*/}
              {/*    isLoading={loader}*/}
              {/*    name='session'*/}
              {/*    placeholder='Session'*/}
              {/*    disabled*/}
              {/*    // onChange={(event) => setSelectedSession(event.target.value)}*/}
              {/*  />*/}
              {/*</Col>*/}
              {/* <Col className="mb-2">
                <Label>Marking Scheme</Label>
                <AvField
                  value={selectedMarkingScheme}
                  isLoading={loader}
                  name="Marking Scheme"
                  placeholder="Marking Scheme"
                  onChange={(event) => setSelectedMarkingScheme(event.target.value)}
                />
              </Col> */}
              {/* <Col className="mb-2">
                <Label>Internal Id</Label>
                <AvField
                  value={internalId}
                  isLoading={loader}
                  name="internal id"
                  placeholder="Internal id"
                  onChange={(event) => setInternalId(event.target.value)}
                />
              </Col> */}
            </FormGroup>
          </Row>

          <Row>
            <Col lg='12' className='agora-form-buttons text-center pt-4'>
              <Button
                onClick={() => {
                  toggleModal();
                }}
                className='btn btn-primary agora-button cancel-button border-radius waves-effect waves-light'
              >
                Cancel
              </Button>
              <Button
                type='submit'
                className='btn btn-primary agora-button save-button border-radius waves-effect waves-light'
              >
                {props.edit ? 'Save' : 'Next'}
              </Button>
            </Col>
          </Row>
        </AvForm>
      </div>
    </Modal>
  );
};

export default PopupAddEditQuestions;
